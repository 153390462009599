import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./postPage.scss";
import { useCallback } from "react";

interface postPageProps {
  post: React.ReactNode;
  onBack: () => void;
}

export function PostPage(props: postPageProps) {
  const closePost = useCallback(() => {
    props.onBack();
  }, [props]);

  return (
    <div className="local-post-page">
      <ArrowBackIcon
        onClick={closePost}
        sx={{ fontSize: 35, cursor: "pointer" }}
        className="back-btn"
      />
      <div className="post-text-area">{props.post}</div>
      <img src="posts/end.png" className="end-marker" alt="end marker" />
      <span className="footer-text">Marylia Gutierrez</span>
    </div>
  );
}
