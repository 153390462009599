import { getSiteLanguage } from "../../utils";

export function getContentEmAndDev(
  key: ContentPortugueseKeys | ContentEnglishKeys
): string {
  if (getSiteLanguage() === "PT") {
    return contentPortuguese[key];
  }
  return contentEnglish[key];
}

type ContentPortugueseKeys = keyof typeof contentPortuguese;
type ContentEnglishKeys = keyof typeof contentEnglish;

const contentEnglish = {
  CHANGE_TRACK: `Change of Track`,
  RESPONSIBILITIES: `Management Responsibilities`,
  ORGANIZATION: `Organization`,
  BEST: `What's best for you`,
  RECS: `Recommendations`,
  SEC_1_PT_1: `At some point in your career, you may wonder whether you want to follow the technical track 
    or switch to the management track. Many people think that becoming a manager is a requirement to continue 
    evolving in your career, which is not true. These are two parallel paths that can independently result 
    in success.`,
  SEC_1_PT_2: `It is common for people who do not have the profile to be managers (or don't even want to be 
    one) end up in this position, which is not beneficial for anyone involved. When becoming a manager, there 
    is also the fear that you will completely distance yourself from the technical side, which happens in 
    most cases.`,
  SEC_1_PT_3: `2021 was the moment the question arose in my career: is it time for me to become a manager? I 
    was worried if I would do well, whether I would have to stop programming completely (something I love), 
    whether my team would receive the support they needed to grow, and whether the results in general would 
    be good for the company.`,
  SEC_1_PT_4: `I made the switch to management, and ended up very happy with my choice. I became a good 
    manager for both my team and the company and I also continued to write a lot of code (being one of the 
    top contributors to the repository). I decided to write this post to share how I organized myself and 
    made this possible.`,
  SEC_1_PT_5: `It's worth remembering that this is my experience, which worked well for my profile, my work 
    and my team. There are many different experiences out there and my tips may not work for you (or maybe 
    they just show that you want to stay away from management).`,
  SEC_2_PT_1: `In 2021 I joined Cockroach Labs to join a team that was being created. I joined as a developer, 
    and soon I started not only writing code, but also helping with planning and organizing the team. When I 
    was completing 2 months, my manager asked me if I was interested in becoming a manager, as I had the 
    profile and was already performing some of the expected responsibilities of a manager. My first reaction 
    was to want to run for the hills! Imagine stopping programming? Never!`,
  SEC_2_PT_2: `I then spent the following month talking to several other managers at the company, to ask their 
    opinion, understand their experience, etc. I learned about a manager who had recently left the company and 
    who was very good at splitting his time between management and development. I contacted him and scheduled 
    a chat, which also helped me a lot.`,
  SEC_2_PT_3: `This is my `,
  SEC_2_PT_4: `first tip: `,
  SEC_2_PT_5: `don't be afraid to ask for help and ask questions. I was entering a world where I had no experience 
    and needed to learn, and there were many people with a lot more experience who could help me. I sent messages 
    to several people, always remembering to be respectful, and value their time, completely understanding if 
    someone doesn't want to or can't help at that moment. With these conversations I understood more about my new 
    role and became very interested in the opportunity.`,
  SEC_2_PT_6: `One advantage in my case is that this was a new team being created, so there wouldn't be some 
    of the problems that can happen during a management change in an existing team, such as the team not being 
    receptive to the change or the previous manager leaving chaos and you having to spend a lot of time just 
    trying to put things in order.`,
  SEC_2_PT_7: `I decided to become a manager. As I had only been at the company for a short time, and my interview 
    process was not for management, I had to go through another round of interviews, and about a month later I 
    officially became the manager for my team, which at that time had already grown to 4 full-time developers 
    (besides me) and an intern.`,
  SEC_2_PT_8: `The company offered me access to training and a management mentoring program, both of which were 
    very useful. Other than that, I started reading books and blogs on the subject (listed in the references below). 
    Studying prepares you for many situations, but there will always be unexpected scenarios that you will have 
    to resolve.`,
  SEC_3_PT_1: `When you become a manager, your priority is: being a manager! Sometimes you want to put everything 
    aside so you can do other things, like solving a bug, responding to a ticket, working on a feature, etc. But 
    all these other things can be done by other people, whereas your responsibilities as a manager can only be 
    done by you!`,
  SEC_3_PT_2: `Only when your responsibilities as a manager are up-to-date can you split your time with other 
    things.`,
  SEC_3_PT_3: `Here I'm talking about the case where you want to do these other tasks, as is my case. It's 
    perfectly normal and common to instead focus exclusively on management responsibilities. Often, depending on 
    the size of your team and the area you are responsible for, you wouldn't even have time for anything else. 
    I chose to manage a smaller team (reaching 6 full-time people + 1 intern), so that I could have time to work 
    in other areas that I am interested in (writing code and blog posts, presenting at conferences, helping with 
    sales meetings with clients , being the leader of one of the DevRel teams, etc.).`,
  SEC_3_PT_4: `But what are your responsibilities as a manager? Let's discuss some of them:`,
  SEC_3_PT_5: `Define team priorities. `,
  SEC_3_PT_6: `When you become a manager, you have a higher-level view of what the team will do. I think it's 
    amazing to have the power to define what's important for the team, and how these decisions influence the 
    product. This item is done in collaboration with your product manager.`,
  SEC_3_PT_7: `Alignment with other teams. `,
  SEC_3_PT_8: `Depending on the project, other teams may be involved, as you have dependencies from each other. 
    It is important that each team knows what they need to do, delivery date, etc. Any changes must be communicated 
    to the other team so that adjustments can be made if necessary.`,
  SEC_3_PT_9: `Technical and career development of your team members. `,
  SEC_3_PT_10: `You will guide the people you manage. On a daily basis, analyzing how they are growing technically, 
    the quality of their deliveries, how they communicate within and outside the team, whether they are working in 
    interesting areas, etc. You will also think about more general aspects of their career, whether they are evolving, 
    improving the quality of their deliveries, being able to work on more complex projects, etc. Give people credit 
    for the work they've done, provide feedback (positive and negative), and promote when they deserve it (check out 
    an article I wrote in Portuguese on this topic `,
  SEC_3_PT_11: `here`,
  SEC_3_PT_12: `). Along with defining priorities, this is the area that has given me the most gratification. Being 
    able to see people grow gives me great pride. This topic is quite complex, so I won't go into details in this 
    post, but I would love to know if you're interested in learning more about it so I can write in more detail.`,
  SEC_3_PT_13: `Within these and other responsibilities, not everything will be easy and you will need to make 
    difficult decisions. For example, when different customers ask for different features and you don't have enough 
    people to work on them all, you will have to decide which one to prioritize and deal with the customer who will 
    have to wait the longest, and provide some alternative in the meantime. Similarly, you will also have to say “no” 
    internally to other people and teams.`,
  SEC_3_PT_14: `Another difficult part is dealing with people on your team who are not performing well, or who have 
    conflicts with someone else, etc. These are often difficult conversations and you will have to learn to be 
    comfortable in these situations.`,
  SEC_4_PT_1: `Part of planning your project will be determining the tasks to be done and their due dates. If you 
    are up-to-date with your responsibilities as a manager, and have the time and interest in working on technical 
    tasks, be sure to set aside some interesting ones for yourself. If you are only going to have a short time to 
    write code, that time has to be rewarding and help you in your own development. When choosing your task, avoid 
    putting yourself on the critical path of a feature, as you may not have the time to complete them. Your 
    responsibilities as a manager are your number one priority.`,
  SEC_4_PT_2: `Below I detail how I organize my week to complete my tasks.`,
  SEC_4_PT_3: `I use Chrome tab groups to create the following groups:`,
  SEC_4_PT_4: `Follow: `,
  SEC_4_PT_5: `PRs (Pull Requests) or documents that need my review. I start and end my day in this group. Whenever 
    I receive a notification of a new PR, I add it to this group. Only when the PR is merged or the document is 
    finalized that I close the tab.`,
  SEC_4_PT_6: `Dashboards: `,
  SEC_4_PT_7: `dashboards with SLO/SLA, errors found by users, and support escalations for my team. I go over these 
    tabs daily if I'm the `,
  SEC_4_PT_8: `person on-call`,
  SEC_4_PT_9: ` that week, or at least once when I'm not.`,
  SEC_4_PT_10: `Planning: `,
  SEC_4_PT_11: `tabs with planning (documents, sprints, etc.), project repository (that I access weekly to triage 
    new issues) and the issues that other teams have asked me to prioritize (a new request or bug) that I leave open 
    so I can add them to an upcoming sprint.`,
  SEC_4_PT_12: `Current: `,
  SEC_4_PT_13: `everything related to the tasks I'm currently working on, such as PRs, tabs with research material 
    on the subject, documents, etc.`,
  SEC_4_PT_14: `Friday Reads: `,
  SEC_4_PT_15: `everything that I find interesting during the week, but not necessarily related to something I'm 
    working on, I add to this new tab and on Friday (usually the day I have the most free time without meetings), 
    I dedicate part of my day to reading them.`,
  SEC_4_PT_16: `Specific projects: `,
  SEC_4_PT_17: `sometimes I'm working on a specific project, so I create a new group to organize its tabs in this area.`,
  SEC_4_PT_18: `Before I break down my week, there's one thing you need to know about me: I'm really good at switching 
    contexts quickly. This "superpower" is one of the reasons I always liked being a full stack developer. I switch 
    between back and front end tasks when I'm bored with one of them. Being able to quickly switch contexts has helped 
    me a lot to be a manager and continue programming. When a meeting ends early, and I have 5 minutes until the next one, 
    I take the opportunity to continue a task I'm programming, or write a document, review some PR and so on. 
    Unfortunately I don't have any tips for developing this skill, because I've always been like this, so maybe practice 
    can help you here.`,
  SEC_4_PT_19: `In addition to dealing with the tabs in the "Follow" group, I start and end my days by reading and 
    responding to messages and emails.`,
  SEC_4_PT_20: `I start the week organizing everything I need to do, and sometimes I create meetings with some team 
    members if we're starting a new project. Monday is the day I make sure my team and I have everything lined up to 
    be able to complete what was determined for the week. I also triage issues from the previous week, which is done 
    with the project manager and my team's tech lead.`,
  SEC_4_PT_21: `Tuesday is the day for 1:1 meetings with each member of my team, the product manager, designer, people 
    I'm mentoring, etc. These meetings are usually scheduled one after the other, but if I know that I will have a more 
    difficult conversation with someone that day, I try to leave the previous time slot to prepare or the following one 
    to be able to recompose myself. If the meeting is heavier, I leave it until the end of the day, so I can stop working 
    straight away and decompress.`,
  SEC_4_PT_22: `Wednesday is divided into two parts: half for meeting with other people, which could be with all the other 
    managers (alignment meeting) or with other teams that I am working with. I leave some time reserved for planning and 
    organizing the requests that come during those meetings.The other half is where I work on tasks as if I were a developer, 
    that is, programming, writing documents, etc.`,
  SEC_4_PT_23: `Thursday is similar to Wednesday, with some company-wide meetings (including Engineering All-Hands), and 
    some meetings that couldn't happen on Tuesday or Wednesday. The rest of the day is dedicated to my current technical 
    task.`,
  SEC_4_PT_24: `Friday can vary a lot. There are rarely meetings, so I can focus on the "Friday Reads" group, finish a 
    task, test the product to try to find bugs or feature ideas, among other things.`,
  SEC_4_PT_25: `I kept making adjustments to this "schedule" and this was the final version I found that made me very 
    productive and happy. I really like being able to switch between software development, planning, guiding people and 
    helping with their growth, among other things.`,
  SEC_5_PT_1: `As I mentioned, this is my experience. Don't move to management if you don't like it. Not only will you 
    be unhappy, but it will also be a disservice to your team who needs someone interested in helping and guiding them.`,
  SEC_5_PT_2: `If programming is an important area for you, but you also want to be a manager, I hope this post has 
    helped you with ways to organize and prioritize all your responsibilities.`,
  SEC_5_PT_3: `If you can't switch contexts so easily, make adjustments to this schedule in ways that make sense to you 
    and that won't overwhelm you. It is very important to create a balance that will make you happy professionally, 
    without harming your mental health.`,
  SEC_5_PT_4: `It's also important to remember that moving into management is not a one-way path. When deciding to 
    make this change, talk to your manager to find out if it would be possible to return to the technical track if 
    you don't adapt to the management role. There's no shame in changing your plan and you'll thank yourself for it 
    in the future.`,
  SEC_5_PT_5: `Nowadays I can say that I made the right choice and that I loved working as a manager while still being 
    able to write code!`,
  SEC_6_PT_1: `The Manager's Path: A Guide for Tech Leaders Navigating Growth and Change`,
  SEC_6_PT_2: `Engineering Management for the Rest of Us`,
  CALENDAR_1: `Review Pull Requests and documents, replying to messages and emails`,
  CALENDAR_2: `Planning`,
  CALENDAR_3: `1:1s`,
  CALENDAR_4: `Technical tasks: writing code or documents`,
  CALENDAR_5: `Meeting with people outside the team`,
  CALENDAR_6: `Open space that will be filled according to the day's demand`,
};

const contentPortuguese = {
  CHANGE_TRACK: `Mudança de Trilha`,
  RESPONSIBILITIES: `Responsabilidades da Gerência`,
  ORGANIZATION: `Organização`,
  BEST: `O melhor para você`,
  RECS: `Recomendações`,
  SEC_1_PT_1: `Em algum momento da sua carreira pode surgir a dúvida entre seguir a trilha técnica ou 
    mudar para a trilha de gerência. Muitas pessoas pensam que virar gerente é um caminho obrigatório 
    para continuar evoluindo, o que não é verdade. Estes são dois caminhos paralelos que podem resultar 
    em sucesso de forma independente.`,
  SEC_1_PT_2: `É comum pessoas que não tem o perfil para ser gerentes (ou mesmo que nem queriam ser), 
    acabam nesta posição, o que não é benéfico para nenhuma das pessoas envolvidas. Ao se tornar gerente, 
    também existe o receio de que você irá se distanciar totalmente da parte técnica, o que acontece em 
    grande parte dos casos.`,
  SEC_1_PT_3: `Em 2021 foi o momento em que a dúvida surgiu na minha carreira: chegou a hora de eu virar 
    gerente? Eu tinha receio se eu me daria bem, se eu teria que parar totalmente de programar (algo que eu 
    adoro), se minha equipe estaria recebendo o suporte necessário para seu crescimento e se os resultados 
    em geral seriam bons para a empresa.`,
  SEC_1_PT_4: `Eu fiz a mudança para esta trilha, e acabei muito feliz com minha escolha. Consegui me tornar 
    uma boa gerente tanto para minha equipe quanto para a empresa e também continuei desenvolvendo bastante 
    (sendo uma das maiores contribuidoras do repositório). Resolvi escrever este post para compartilhar 
    como eu me organizei e tornei isto possível.`,
  SEC_1_PT_5: `Vale lembrar que isso é a minha experiência, que deu certo com o meu perfil, meu trabalho e 
    minha equipe. Existem muitas experiências diferentes por aí e minhas dicas podem não funcionar para você 
    (ou quem sabe apenas sirvam para mostrar que você quer passar longe da gerência).`,
  SEC_2_PT_1: `Em 2021 eu entrei na Cockroach Labs para uma equipe que estava sendo criada. Eu entrei como 
    desenvolvedora, e logo comecei não só a desenvolver, mas também a ajudar no planejamento e organização 
    da equipe. Quando eu estava completando 2 meses, meu gerente me perguntou se eu tinha interesse em virar 
    gerente, pois eu tinha o perfil e já estava exercendo algumas das funções esperadas. Minha primeira 
    reação foi querer correr para as colinas! Imagine deixar de programar? Jamais!`,
  SEC_2_PT_2: `Passei então o mês seguinte conversando com vários outros gerentes da empresa, para pedir 
    opinião, entender experiência, etc. Fiquei sabendo de um gerente que havia saído da empresa recentemente 
    e que era muito bom em dividir gerência e desenvolvimento. Entrei em contato com ele e pedi para conversar, 
    o que também me ajudou bastante.`,
  SEC_2_PT_3: `Essa então é minha `,
  SEC_2_PT_4: `primeira dica: `,
  SEC_2_PT_5: `não tenha medo de pedir ajuda e fazer perguntas. Eu estava entrando em um mundo em que eu 
    não possuía experiência e precisava aprender, e haviam muitas pessoas muito mais experientes que poderiam 
    me ajudar. Eu mandei mensagem para várias, lembrando sempre de ser respeitosa, e valorizar o tempo delas, 
    entendendo completamente se alguém não quiser ou não puder ajudar naquele momento. Com essas conversas eu 
    entendi mais sobre o meu novo papel e fiquei bem interessada.`,
  SEC_2_PT_6: `Uma vantagem no meu caso é que esta era uma nova equipe sendo criada, então não existiriam 
    alguns dos problemas que podem acontecer durante a mudança de gerência em uma equipe já existente, como a 
    equipe não ser receptiva à mudança ou o gerente anterior ter deixado o caos e você ter que passar um bom 
    tempo só tentando colocar as coisas em ordem.`,
  SEC_2_PT_7: `Eu então decidi virar gerente. Como eu estava na empresa a pouco tempo, e meu processo de entrevista 
    não foi para gerência, eu tive que passar por mais uma rodada de entrevistas, e cerca de um mês depois eu 
    virei oficialmente a gerente da minha equipe, que nesta época já tinha crescido para 4 pessoas desenvolvedoras 
    full-time (além de mim) e um estagiário.`,
  SEC_2_PT_8: `Pela própria empresa, eu tive acesso a treinamentos e a um programa de mentoria sobre gerência, 
    ambos bem úteis. Fora isso, comecei a ler livros e blogs no assunto (listados nas referências abaixo). Estudar 
    te prepara para muitas situações, mas é claro que sempre vão surgir cenários inesperados que você vai ter 
    que resolver.`,
  SEC_3_PT_1: `Quando você se torna gerente, a sua prioridade é: ser gerente! Às vezes você quer deixar tudo 
    de lado para poder fazer outras coisas, como resolver um bug, responder a um ticket, trabalhar em uma feature 
    etc. Mas todas essas outras coisas podem ser feitas por outras pessoas, enquanto que as suas responsabilidades 
    como gerente só podem ser feitas por você!`,
  SEC_3_PT_2: `Apenas quando as suas responsabilidades como gerente estão em dia que você pode dividir seu tempo 
    com outras coisas.`,
  SEC_3_PT_3: `Aqui eu estou falando no caso em que você queira fazer essas outras tarefas, como é o meu caso. É 
    claro que é perfeitamente normal e comum focar exclusivamente nas responsabilidades de gerência. Muitas vezes, 
    dependendo do tamanho da sua equipe e da área que você é responsável, você nem teria tempo para outra coisa. 
    Eu escolhi gerenciar uma equipe menor (chegando a 6 pessoas full-time + 1 pessoa estagiária), para que eu pudesse 
    trabalhar em outras áreas que eu tenho interesse (desenvolver, escrever blog posts, apresentar em conferência, 
    ajudar em reuniões de vendas com clientes, ser líder da uma das equipes de DevRel, etc).`,
  SEC_3_PT_4: `Mas quais são as suas responsabilidades como gerente? Vamos ver algumas delas:`,
  SEC_3_PT_5: `Definir as prioridades da equipe. `,
  SEC_3_PT_6: `Ao se tornar gerente, você tem uma visão mais alto nível do que a equipe irá fazer. Eu acho incrível 
    ter o poder de definir o que é importante para a equipe, e como suas decisões influenciam o produto. Este item 
    é feito em colaboração com seu gerente de produto.`,
  SEC_3_PT_7: `Alinhamento com outras equipes. `,
  SEC_3_PT_8: `Dependendo do projeto, outras equipes estarão envolvidas, pois existe dependência entre si. 
    É importante que cada equipe envolvida saiba o que precisa fazer, data de entregas, etc. Qualquer mudança deverá 
    ser informada a outra equipe para que ajustes sejam feitos se necessário.`,
  SEC_3_PT_9: `Desenvolvimento técnico e da carreira dos membros da sua equipe. `,
  SEC_3_PT_10: `Você irá guiar as pessoas que você gerencia. No dia a dia, analisando como estão se desenvolvendo 
    tecnicamente, qualidade das entregas, como se comunicam dentro e fora da equipe, se estão trabalhando em área 
    interessantes, etc. Você também pensará em aspectos mais gerais da carreira, ou seja, se estão evoluindo, melhorando 
    qualidade das entregas, conseguindo trabalhar em projetos mais complexos, etc. Dê crédito às pessoas pelo trabalho 
    feito, forneça feedback (positivo e negativo) e promova quando merecerem (dê uma olhada em um artigo sobre esse 
        assunto que escrevi para o Programaria `,
  SEC_3_PT_11: `aqui`,
  SEC_3_PT_12: `). Juntamente com escolher prioridades, esta é a área que me deu maior gratificação. Poder ver 
    pessoas evoluindo me dá um orgulho muito grande. Este tópico é bastante complexo, então eu não vou me aprofundar 
    neste post, mas se vocês tiverem interesse eu adoraria saber para que eu possa escrever com mais detalhes.`,
  SEC_3_PT_13: `Dentro destas e outras responsabilidades, nem tudo serão rosas e você precisará tomar decisões difíceis. 
    Por exemplo, quando clientes diferentes pedem muitas features e você não tem gente suficiente para trabalhar em 
    todas, você terá que decidir qual priorizar e lidar com o cliente que terá uma espera maior, e fornecer alguma 
    alternativa no meio-tempo. De forma parecida, internamente você também terá que dizer "não" para outras pessoas e 
    equipes.`,
  SEC_3_PT_14: `Outra parte difícil é lidar com as pessoas da sua equipe que não estão tendo boa performance, que 
    possuem conflito com outra pessoa, etc. Estas costumam ser conversas difíceis e você terá que aprender a se sentir 
    confortável nessas situações.`,
  SEC_4_PT_1: `Parte do planejamento do seu projeto será determinar as tarefas que serão feitas e as datas de entrega. 
    Se você está em dia com as suas responsabilidades como gerente, e possui tempo e interesse em trabalhar em tarefas 
    técnicas, certifique-se de separar algumas tarefas interessantes para você, pois se você vai programar por pouco tempo, 
    esse tempo tem que ser gratificante e te ajudar no seu próprio desenvolvimento. Ao escolher suas tarefas, evite se 
    colocar no caminho crítico de uma feature, pois você pode não ter tempo de terminá-las. Suas responsabilidades como 
    gerente são sua prioridade número um.`,
  SEC_4_PT_2: `A seguir vou detalhar como eu organizo minha semana para cumprir as tarefas definidas.`,
  SEC_4_PT_3: `Eu uso Grupo de Abas do Chrome, e nele eu crio os seguintes grupos:`,
  SEC_4_PT_4: `Seguir: `,
  SEC_4_PT_5: `PRs (Pull Requests) ou documentos que precisam da minha revisão. Eu começo e termino meu dia olhando 
    este grupo. Sempre que recebo uma notificação de um novo PR, eu adiciono neste grupo. Quando o PR é merged ou o 
    documento é finalizado, só então eu fecho a aba.`,
  SEC_4_PT_6: `Dashboards: `,
  SEC_4_PT_7: `dashboards com SLO/SLA, erros encontrados por usuários, chamados de suporte para minha equipe, etc. Estas 
    abas eu olho diariamente se eu sou a `,
  SEC_4_PT_8: `pessoa de plantão`,
  SEC_4_PT_9: ` na semana, ou uma vez nas demais semanas.`,
  SEC_4_PT_10: `Planejamento: `,
  SEC_4_PT_11: `abas com planejamento (documentos, sprints, etc), repositório do projeto que olho semanalmente para 
    triagem de novas issues e as issues que outras equipes me pediram para dar prioridade (seja um pedido novo ou um bug) 
    que deixo abertas para poder adicionar em um próximo sprint.`,
  SEC_4_PT_12: `Atual: `,
  SEC_4_PT_13: `tudo relacionado às tarefas em que eu estou atualmente trabalhando, como PRs, abas com pesquisas sobre o 
    assunto, documentos, etc.`,
  SEC_4_PT_14: `Leituras de Sexta: `,
  SEC_4_PT_15: `tudo que vejo durante a semana e acho interessante, mas que não seja necessariamente relacionado a algo 
    que eu esteja trabalhando, eu vou adicionando neste grupo e na sexta (geralmente o dia que tenho mais tempo livre sem 
    reuniões), dedico parte do meu dia pra ler.`,
  SEC_4_PT_16: `Projetos específicos: `,
  SEC_4_PT_17: `às vezes estou trabalhando em algum projeto específico, então eu crio um grupo novo para organizar abas 
    desta área.`,
  SEC_4_PT_18: `Antes de detalhar minha semana, existe uma coisa que você precisa saber sobre mim: eu sou muito boa em 
    trocar de contexto rapidamente. Este "superpoder" é um dos motivos que eu sempre gostei de ser full stack, porque fico 
    alternando entre tarefas de back e front end quando uma delas me deixa entediada. Isso me ajudou muito a ser gerente e 
    continuar programando, porque quando uma reunião acaba mais cedo e eu tenho 5 minutos até a próxima, eu aproveito para 
    continuar a tarefa que estou programando, ou escrever algum documento, revisar algum PR e por aí vai. Infelizmente eu 
    não sei como dar dicas para desenvolver esta habilidade, pois eu sempre fui assim, então talvez a prática possa te ajudar 
    aqui.`,
  SEC_4_PT_19: `Além de lidar com as abas do grupo "Seguir", eu começo e termino meus dias lendo e respondendo mensagens 
    e emails.`,
  SEC_4_PT_20: `Eu começo a semana organizando tudo que devo fazer e às vezes crio reuniões com algumas pessoas da minha 
    equipe para iniciar um projeto novo. Segunda é o dia que vejo se eu e minha equipe temos tudo alinhado para conseguir 
    completar durante a semana o que havia sido determinado. Neste dia também faço triagem de issues que apareceram na semana 
    anterior. Essa triagem é feita junto com o gerente de projeto e tech lead da equipe.`,
  SEC_4_PT_21: `Terça é o dia de reuniões do tipo 1:1 com cada integrante da minha equipe, gerente de produto, designer, 
    pessoas que eu esteja mentorando, etc. Geralmente as reuniões ocorrem uma atrás da outra, mas se eu sei que aquele dia eu 
    terei uma conversa mais difícil com alguém, eu procuro deixar o horário anterior para me preparar ou o horário a seguir 
    para conseguir me recompor para a próxima reunião. Se a reunião for mais pesada, deixo para o fim do dia, assim posso 
    parar de trabalhar logo em seguida e me recuperar.`,
  SEC_4_PT_22: `Quarta é dividida em duas partes: metade de reuniões com outras pessoas, podendo ser com todos os outros 
    gerentes (reunião de alinhamento) ou com equipes que eu esteja trabalhando. Eu deixo um tempo reservado para planejamento 
    e organização de pedidos que tenham vindo durante estas reuniões. A outra metade é onde eu trabalho nas minhas 
    tarefas como se eu fosse desenvolvedora, ou seja, programando, escrevendo documentos, etc.`,
  SEC_4_PT_23: `Quinta é parecido com quarta, tendo algumas reuniões ainda (incluindo All-Hands de Engenharia), e 
    alguma reunião que não pôde acontecer na terça ou quarta. O resto do dia é dedicado para a minha tarefa técnica atual.`,
  SEC_4_PT_24: `Sexta pode variar bastante. Raramente possui reuniões, então posso focar na aba de "Leituras de Sexta", 
    terminar alguma tarefa, testar o produto para tentar encontrar bugs ou ideias de features, entre outras coisas.`,
  SEC_4_PT_25: `Eu fui fazendo ajustes neste "cronograma" e esta foi a organização final que encontrei que me deixou bem 
    produtiva e feliz. Eu gosto muito de poder variar entre desenvolvimento, planejamento, guiar pessoas e ajudá-las no seu 
    crescimento, entre outras coisas.`,
  SEC_5_PT_1: `Como eu disse, esta é a minha experiência. Não mude para gerência se você não gosta, pois você não somente 
    ficará infeliz, mas também estará fazendo um desserviço para a sua equipe que precisa de alguém interessado em ajudá-los 
    e guiá-los.`,
  SEC_5_PT_2: `Se programar é uma área importante para você, mas você também quer ser gerente, espero que este post tenha 
    ajudado com formas de organizar e priorizar todas as suas responsabilidades.`,
  SEC_5_PT_3: `Caso você não consiga trocar de contexto tão facilmente, faça ajustes neste cronograma de forma que faça 
    sentido para você e que não te sobrecarregue. É muito importante criar um balanço que vai te deixar feliz profissionalmente, 
    sem prejudicar a sua saúde mental.`,
  SEC_5_PT_4: `Também é importante lembrar que mudar para a gerência não é um caminho sem volta. Ao decidir fazer essa 
    mudança, converse com seu gerente para saber se seria possível voltar para a trilha técnica caso você não se adapte ao 
    cargo de gerência. Não há vergonha nenhuma em mudar seu plano e você vai se agradecer por isso no futuro.`,
  SEC_5_PT_5: `Hoje em dia posso dizer que fiz a escolha certa e que adorei trabalhar como gerente de uma forma que ainda 
    conseguisse ser desenvolvedora!`,
  SEC_6_PT_1: `A Arte da Gestão: um Guia Prático Para Integrar Liderança e Recursos Humanos no Setor de Tecnologia`,
  SEC_6_PT_2: `Engineering Management for the Rest of Us`,
  CALENDAR_1: `Revisão de Pull Requests e documentos, responder mensagens e emails`,
  CALENDAR_2: `Planejamento`,
  CALENDAR_3: `1:1s`,
  CALENDAR_4: `Tarefas técnicas: escrever código ou documentos`,
  CALENDAR_5: `Reunião com pessoas fora da equipe`,
  CALENDAR_6: `Espaço em aberto que será preenchido de acordo com a demanda do dia`,
};
