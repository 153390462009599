import Link from "@mui/material/Link";
interface textLabelDescriptionProps {
    label: string;
    description: string;
    link?: string;
  }

export function TextLabelDescription(props: textLabelDescriptionProps) {
    const label = props.link? 
      (<Link
          href={props.link}
          underline="always"
          target="_blank"
      >
        {props.label}
      </Link>) : (<u>{props.label}</u>);
    return (
        <p className="text-area">
          • {label}{props.description}
        </p>
    );
  }
  