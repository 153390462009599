export const LanguageOptions = ["PT", "EN", "ALL"];
export type LanguageKeys = (typeof LanguageOptions)[number];

export function isSmallScreen(): boolean {
  return window.outerWidth <= 950;
}

interface searchParam {
  key: string;
  value: string;
}

export function updateSearchParams(params: searchParam[]) {
  const searchParams = new URLSearchParams(window.location.search);
  params.forEach(function (p: searchParam) {
    if (p.value !== "" && p.value !== "ALL") {
      searchParams.set(p.key, p.value);
    } else {
      searchParams.delete(p.key);
    }
  });

  window.location.search = searchParams.toString();
}

export function getSiteLanguage(): LanguageKeys {
  const language = window.localStorage.getItem("language");
  if (language) {
    return language;
  }
  if (navigator.language.startsWith("pt")) {
    return "PT";
  }
  return "EN";
}

function isProduction(): boolean {
  return window.location.hostname.includes("marylia.dev");
}

export function trackPostSelected(postID: string) {
  if (isProduction()) {
    gtag("event", "post_selected", {
      post_id: postID,
    });
  }
}

export function trackTabSelected(tab: string) {
  if (isProduction()) {
    gtag("event", "tab_selected", {
      tab: tab,
    });
  }
}

export function trackFilterLanguage(language: string) {
  if (isProduction()) {
    gtag("event", "filter_language", {
      selected_language: language,
    });
  }
}

export function trackFilterType(type: string) {
  if (isProduction()) {
    gtag("event", "filter_type", {
      selected_type: type,
    });
  }
}

export function trackWebsiteLanguage(language: string) {
  if (isProduction()) {
    gtag("event", "website_language", {
      selected_language: language,
    });
  }
}

export function trackContactSelected(contact: string) {
  if (isProduction()) {
    gtag("event", "contact_selected", {
      contact: contact,
    });
  }
}

export function trackConferenceSelected(conference: string) {
  if (isProduction()) {
    gtag("event", "conference_selected", {
      conference: conference,
    })
  }
}

export function trackConfetti(postID: string) {
  if (isProduction()) {
    gtag("event", "confetti", {
      postId: postID,
    });
  }
}
