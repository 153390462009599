import { Header } from "../../components/header";
import Link from "@mui/material/Link";
import { getText } from "../../texts/texts";
import { getContentOtelSdkApi } from "./contentOtelSdkApi";
import "./otelSdkApi.scss";
import { TableOfContents, headerItem } from "../../components/tableOfContents";

export function OtelSdkApi() {
    const headers: headerItem[] = [
        {label: getContentOtelSdkApi("SEC_2_TITLE"), id: 'data-flow'},
        {label: getContentOtelSdkApi("SEC_3_TITLE"), id: 'api'},
        {label: getContentOtelSdkApi("SEC_4_TITLE"), id: 'sdk'},
        {label: getContentOtelSdkApi("SEC_5_TITLE"), id: 'setup', 
            subItems: [
                {label: getContentOtelSdkApi("SEC_6_TITLE"), id: 'application'}, 
                {label: getContentOtelSdkApi("SEC_7_TITLE"), id: 'visualization'}]},
        {label: getContentOtelSdkApi("SEC_8_TITLE"), id: 'instrumentation', 
            subItems: [
                {label: getContentOtelSdkApi("SEC_9_TITLE"), id: 'auto-instrumentation'},
                {label: getContentOtelSdkApi("SEC_10_TITLE"), id: 'sdk-instrumentation'},
                {label: getContentOtelSdkApi("SEC_11_TITLE"), id: 'manual-instrumentation'}]},
        {label: getContentOtelSdkApi("SEC_12_TITLE"), id: 'summary'},
        {label: getContentOtelSdkApi("SEC_13_TITLE"), id: 'references'},
    ];
    return (
        <>
          <Header text={getText("LOCAL_5_TITLE")} bck_img="title_bkg_03.png" />
          <section className="content-area">
            <TableOfContents headers={headers} postID="L-otel-sdk-api"/>

            <p className="text-area">
                {getContentOtelSdkApi("SEC_1_PT_1")}{" "}
                <Link
                    href="?postID=L-otel-1"
                    underline="always"
                    target="_blank"
                >
                    {getContentOtelSdkApi("SEC_1_PT_2")}
                </Link>
                {getContentOtelSdkApi("SEC_1_PT_3")}
            </p>
            <br />
            <p className="text-area">{getContentOtelSdkApi("SEC_1_PT_4")}</p>

            <h2 id="data-flow" className="sub-title">{getContentOtelSdkApi("SEC_2_TITLE")}</h2>
            <p className="text-area">{getContentOtelSdkApi("SEC_2_PT_1")}</p>
            <img
                src="posts/otelSdk/flow1.png"
                className="image-big"
                alt="data flow simple"
            />
            <br />
            <p className="text-area">{getContentOtelSdkApi("SEC_2_PT_2")}</p>
            <br />
            <p className="text-area">{getContentOtelSdkApi("SEC_2_PT_3")}</p>
            <img
                src="posts/otelSdk/flow2.png"
                className="image-big"
                alt="data flow simple"
            />
            <br />
            <p className="text-area">{getContentOtelSdkApi("SEC_2_PT_4")}</p>
            <br />
            <p className="text-area italic">{getContentOtelSdkApi("SEC_2_PT_5")}</p>
            <br />
            <p className="text-area">{getContentOtelSdkApi("SEC_2_PT_6")}</p>


            <h2 id="api" className="sub-title">{getContentOtelSdkApi("SEC_3_TITLE")}</h2>
            <p className="text-area">{getContentOtelSdkApi("SEC_3_PT_1")}</p>
            <br />
            <p className="text-area">
                {getContentOtelSdkApi("SEC_3_PT_2")}
                <span className="bold">{getContentOtelSdkApi("SEC_3_PT_3")}</span>
                {getContentOtelSdkApi("SEC_3_PT_4")}
            </p>
            <br />
            <p className="text-area">{getContentOtelSdkApi("SEC_3_PT_5")}</p>
            <br />
            <p className="text-area">{getContentOtelSdkApi("SEC_3_PT_6")}</p>
            <br />
            <p className="text-area">{getContentOtelSdkApi("SEC_3_PT_7")}</p>
            <br />
            <p className="text-area">
                {getContentOtelSdkApi("SEC_3_PT_8")}
                <Link
                    href="https://open-telemetry.github.io/opentelemetry-js/modules/_opentelemetry_api.html"
                    underline="always"
                    target="_blank"
                >
                    {`link`}
                </Link>
                {`):`}</p>
            <br />
            <p className="text-area">
                • <Link
                    href="https://open-telemetry.github.io/opentelemetry-js/classes/_opentelemetry_api.MetricsAPI.html"
                    underline="always"
                    target="_blank"
                >
                    {getContentOtelSdkApi("SEC_3_PT_9")}
                </Link>
                {getContentOtelSdkApi("SEC_3_PT_10")}
            </p>
            <br />
            <p className="text-area">
                • <Link
                    href="https://open-telemetry.github.io/opentelemetry-js/classes/_opentelemetry_api.TraceAPI.html"
                    underline="always"
                    target="_blank"
                >
                    {getContentOtelSdkApi("SEC_3_PT_11")}
                </Link>
                {getContentOtelSdkApi("SEC_3_PT_12")}
            </p>
            <br />
            <p className="text-area">
                • <Link
                    href="https://open-telemetry.github.io/opentelemetry-js/interfaces/_opentelemetry_api.Histogram.html"
                    underline="always"
                    target="_blank"
                >
                    {getContentOtelSdkApi("SEC_3_PT_13")}
                </Link>
                {getContentOtelSdkApi("SEC_3_PT_14")}
            </p>
            <br />
            <p className="text-area">{getContentOtelSdkApi("SEC_3_PT_15")}</p>
            <br />
            <p className="text-area">{getContentOtelSdkApi("SEC_3_PT_16")}</p>


            <h2 id="sdk" className="sub-title">{getContentOtelSdkApi("SEC_4_TITLE")}</h2>
            <p className="text-area">{getContentOtelSdkApi("SEC_4_PT_1")}</p>
            <br />
            <p className="text-area">{getContentOtelSdkApi("SEC_4_PT_2")}</p>
            <br />
            <p className="text-area">{getContentOtelSdkApi("SEC_4_PT_3")}</p>
            <br />
            <p className="text-area">{getContentOtelSdkApi("SEC_4_PT_4")}</p>


            <h2 id="setup" className="sub-title">{getContentOtelSdkApi("SEC_5_TITLE")}</h2>
            <p className="text-area">{getContentOtelSdkApi("SEC_5_PT_1")}</p>
            <br />
            <p className="text-area italic">
                {getContentOtelSdkApi("SEC_5_PT_2")}
                <Link
                    href="https://github.com/maryliag/blog-sdk"
                    target="_blank"
                >
                    {getContentOtelSdkApi("SEC_5_PT_3")}
                </Link>
            </p>

            <h2 id="application" className="sub-title-small">{getContentOtelSdkApi("SEC_6_TITLE")}</h2>
            <p className="text-area">{getContentOtelSdkApi("SEC_6_PT_1")}</p>
            <br />
            <p className="text-area">
                {getContentOtelSdkApi("SEC_6_PT_2")}
                <Link
                    href="https://expressjs.com/"
                    underline="always"
                    target="_blank"
                >
                    {`Express`}
                </Link>
                {getContentOtelSdkApi("SEC_6_PT_3")}
            </p>
            <br />
            <ol className="text-area">
                <li>{getContentOtelSdkApi("SEC_6_PT_4")}<span className="code">{`package.json`}</span></li>
                <p className="code-area">{`npm init -y`}</p>
                <li>{getContentOtelSdkApi("SEC_6_PT_5")}</li>
                <p className="code-area">
                    {`npm install typescript \\`}<br/>
                    {`ts-node \\`}<br/>
                    {`express \\`}<br/>
                    {`@types/express`}<br/><br/>
                    {`# ${getContentOtelSdkApi("SEC_6_PT_6")}`}<br/>
                    {`npx tsc --init`}
                </p>
                <li>
                    {getContentOtelSdkApi("SEC_6_PT_7")}
                    <span className="code">{`app.ts`}</span>
                    {getContentOtelSdkApi("SEC_6_PT_8")}
                </li>
                <p className="code-area">
                    {`import express, { Express } from 'express';`}<br/><br/>
                    {`const PORT: number = parseInt(process.env.PORT || '8080');`}<br/>
                    {`const app: Express = express();`}<br/><br/>
                    {`function getRandomNumber(min: number, max: number) {`}<br/>
                    &nbsp;&nbsp;{`return Math.floor(Math.random() * (max - min) + min);`}<br/>
                    {`}`}<br/><br/>
                    {`app.get('/rolldice', (req, res) => {`}<br/>
                    &nbsp;&nbsp;{`res.send(getRandomNumber(1, 6).toString());`}<br/>
                    {`});`}<br/><br/>
                    {`app.listen(PORT, () => {`}<br/>
                    &nbsp;&nbsp;{`console.log(\`Listening for requests on http://localhost:\${PORT}\`);`}<br/>
                    {`});`}
                </p>
                <li>
                    {getContentOtelSdkApi("SEC_6_PT_9")}
                    <Link
                        href="http://localhost:8080/rolldice"
                        underline="always"
                        target="_blank"
                    >
                        {`http://localhost:8080/rolldice`}
                    </Link>
                    {getContentOtelSdkApi("SEC_6_PT_10")}
                </li>
                <p className="code-area">
                    {`$ npx ts-node app.ts`}<br/>
                    {`Listening for requests on http://localhost:8080`}
                </p>
            </ol>


            <h2 id="visualization" className="sub-title-small">{getContentOtelSdkApi("SEC_7_TITLE")}</h2>
            <p className="text-area">{getContentOtelSdkApi("SEC_7_PT_1")}</p>
            <br />
            <ol className="text-area">
                <li>
                    {getContentOtelSdkApi("SEC_7_PT_2")}
                    <Link
                        href="https://grafana.com/"
                        underline="always"
                        target="_blank"
                    >
                        {`Grafana Cloud`}
                    </Link>
                    {getContentOtelSdkApi("SEC_7_PT_3")}
                    <Link
                        href="https://grafana.com/auth/sign-up/create-user/?pg=blog&plcmt=body-txt"
                        underline="always"
                        target="_blank"
                    >
                        {getContentOtelSdkApi("SEC_7_PT_4")}
                    </Link>
                    {getContentOtelSdkApi("SEC_7_PT_5")}
                </li>
                <li>
                    {getContentOtelSdkApi("SEC_7_PT_6")}
                    <Link
                        href="https://grafana.com/profile/org"
                        underline="always"
                        target="_blank"
                    >
                        {`portal`}
                    </Link>
                    {getContentOtelSdkApi("SEC_7_PT_7")}
                </li>
                <li>{getContentOtelSdkApi("SEC_7_PT_8")}</li>
                <li>{getContentOtelSdkApi("SEC_7_PT_9")}</li>
                <img
                    src="posts/otelSdk/configure.png"
                    className="image-big width-half"
                    alt="configure otel grafana"
                /><br/><br/>
                <li>
                    {getContentOtelSdkApi("SEC_7_PT_10")}
                    <ul>
                        <li>
                            {getContentOtelSdkApi("SEC_7_PT_11")}
                            <span className="code">{`test-service`}</span>
                        </li>
                        <li>{getContentOtelSdkApi("SEC_7_PT_12")}</li>
                        <li>{getContentOtelSdkApi("SEC_7_PT_13")}</li>
                    </ul>
                </li>
                <img
                    src="posts/otelSdk/token.png"
                    className="image-big width-full"
                    alt="token grafana"
                /><br/>
                <li>
                    {getContentOtelSdkApi("SEC_7_PT_14")}
                    <span className="code">{`test`}</span>
                </li>
            </ol>
            <br />
            <p className="text-area">
                {getContentOtelSdkApi("SEC_7_PT_15")}
                <p className="code-area">
                    {`export OTEL_EXPORTER_OTLP_PROTOCOL="http/protobuf"`}<br/>
                    {`export OTEL_EXPORTER_OTLP_ENDPOINT="https://..."`}<br/>
                    {`export OTEL_EXPORTER_OTLP_HEADERS="Authorization=Basic ..."`}<br/>
                    {`export OTEL_SERVICE_NAME=<Service Name>`}<br/>
                </p>
            </p>
            

            <h2 id="instrumentation" className="sub-title">{getContentOtelSdkApi("SEC_8_TITLE")}</h2>
            <p className="text-area">{getContentOtelSdkApi("SEC_8_PT_1")}</p>

            <h2 id="auto-instrumentation" className="sub-title-small">{getContentOtelSdkApi("SEC_9_TITLE")}</h2>
            <p className="text-area">{getContentOtelSdkApi("SEC_9_PT_1")}</p>
            <br />
            <p className="text-area">{getContentOtelSdkApi("SEC_9_PT_2")}</p>
            <br />
            <p className="text-area">
                {getContentOtelSdkApi("SEC_9_PT_3")}
                <p className="code-area">
                    {`npm install --save @opentelemetry/api`}<br/>
                    {`npm install --save @opentelemetry/auto-instrumentations-node`}<br/>
                </p>
            </p>
            <br />
            <p className="text-area">
                {getContentOtelSdkApi("SEC_9_PT_4")}
                <p className="code-area">
                    {`# ${getContentOtelSdkApi("SEC_9_PT_5")}`}<br/>
                    {`export OTEL_TRACES_EXPORTER="otlp"`}<br/>
                    {`export OTEL_METRICS_EXPORTER="otlp"`}<br/><br/>
                    {`# ${getContentOtelSdkApi("SEC_9_PT_6")}`}<br/>
                    {`export NODE_OPTIONS="--require @opentelemetry/auto-instrumentations-node/register"`}<br/><br/>
                    {`# ${getContentOtelSdkApi("SEC_9_PT_7")}`}<br/>
                    {`export OTEL_NODE_RESOURCE_DETECTORS="env,host,os,serviceinstance"`}<br/>
                </p>
            </p>
            <br />
            <p className="text-area">
                {getContentOtelSdkApi("SEC_9_PT_8")}
                <span className="code">{`OTEL_NODE_RESOURCE_DETECTORS`}</span>
                {getContentOtelSdkApi("SEC_9_PT_9")}
                <p className="code-area">
                    {`# Alibaba Cloud`}<br/>
                    {`export OTEL_NODE_RESOURCE_DETECTORS="env,host,serviceinstance,alibaba"`}<br/><br/>
                    {`# Amazon Web Services`}<br/>
                    {`export OTEL_NODE_RESOURCE_DETECTORS="env,host,os,serviceinstance,aws"`}<br/><br/>
                    {`# Azure`}<br/>
                    {`export OTEL_NODE_RESOURCE_DETECTORS="env,host,os,serviceinstance,azure"`}<br/><br/>
                    {`# Google Cloud Platform`}<br/>
                    {`export OTEL_NODE_RESOURCE_DETECTORS="env,host,os,serviceinstance,gcp"`}<br/>
                </p>
            </p>
            <br />
            <p className="text-area">
                {getContentOtelSdkApi("SEC_9_PT_10")}
                <p className="code-area">
                    {`$ npx ts-node app.ts`}<br/>
                    {`OpenTelemetry automatic instrumentation started successfully`}<br/>
                    {`OpenTelemetry automatic instrumentation started successfully`}<br/>
                    {`Listening for requests on http://localhost:8080`}<br/>
                </p>
            </p>
            <br />
            <p className="text-area">
                {getContentOtelSdkApi("SEC_9_PT_11")}
                <Link
                    href="http://localhost:8080/rolldice"
                    underline="always"
                    target="_blank"
                >
                    {`http://localhost:8080/rolldice`}
                </Link>
                {getContentOtelSdkApi("SEC_9_PT_12")}
            </p>
            <br />
            <p className="text-area">{getContentOtelSdkApi("SEC_9_PT_13")}</p>
            <br />
            <ol className="text-area">
                <li>
                    {getContentOtelSdkApi("SEC_9_PT_14")}
                    <span className="code">{`https://<your-stack-name>.grafana.net`}</span>
                </li>
                <li>{getContentOtelSdkApi("SEC_9_PT_15")}</li>
            </ol>
            <br />
            <p className="text-area">
                {getContentOtelSdkApi("SEC_9_PT_16")}
                <img
                    src="posts/otelSdk/grafana-metrics.png"
                    className="image-big width-full"
                    alt="metrics on grafana"
                />
            </p>
            <br />
            <br />
            <p className="text-area">
                {getContentOtelSdkApi("SEC_9_PT_17")}
                <img
                    src="posts/otelSdk/grafana-traces.png"
                    className="image-big width-full"
                    alt="traces on grafana"
                />
            </p>
            <br />
            <br />
            <p className="text-area">{getContentOtelSdkApi("SEC_9_PT_18")}</p>


            <h2 id="sdk-instrumentation" className="sub-title-small">{getContentOtelSdkApi("SEC_10_TITLE")}</h2>
            <p className="text-area">{getContentOtelSdkApi("SEC_10_PT_1")}</p>
            <br />
            <p className="text-area">
                {getContentOtelSdkApi("SEC_10_PT_2")}
                <span className="code">{`NODE_OPTIONS`}</span>
                {`:`}
                <p className="code-area">
                    {`unset NODE_OPTIONS`}
                </p>
            </p>
            <br />
            <p className="text-area">
                {getContentOtelSdkApi("SEC_10_PT_3")}
                <p className="code-area">
                    {`npm install --save @opentelemetry/sdk-node`}
                </p>
            </p>
            <br />
            <p className="text-area">
                {getContentOtelSdkApi("SEC_10_PT_4")}
                <span className="code">{`instrumentation.ts:`}</span>
                <p className="code-area">
                    {`import { NodeSDK } from '@opentelemetry/sdk-node';`}<br/><br/>
                    {`const sdk = new NodeSDK();`}<br/>
                    {`sdk.start();`}
                </p>
            </p>
            <br />
            <p className="text-area">
                {getContentOtelSdkApi("SEC_10_PT_5")}
                <span className="code">{`require`}</span>
                {getContentOtelSdkApi("SEC_10_PT_6")}
                <p className="code-area">
                    {`npx ts-node --require ./instrumentation.ts app.ts`}
                </p>
            </p>
            <br />
            <p className="text-area">
                {getContentOtelSdkApi("SEC_10_PT_7")}
                <p className="code-area">
                    {/* {`npm install --save @opentelemetry/sdk-trace-node`}<br/> */}
                    {`npm install --save @opentelemetry/sdk-metrics`}<br/>
                    {`npm install --save @opentelemetry/exporter-metrics-otlp-http`}<br/>
                    {`npm install --save @opentelemetry/exporter-trace-otlp-http`}
                </p>
            </p>
            <br />
            <p className="text-area">
                {getContentOtelSdkApi("SEC_10_PT_8")}
                <p className="code-area">
                    {`import { NodeSDK } from '@opentelemetry/sdk-node';`}<br/>
                    {`import { ConsoleSpanExporter } from '@opentelemetry/sdk-trace-node';`}<br/>
                    {`import {`}<br/>
                    &nbsp;&nbsp;{`  PeriodicExportingMetricReader,`}<br/>
                    &nbsp;&nbsp;{`  ConsoleMetricExporter,`}<br/>
                    {`} from '@opentelemetry/sdk-metrics';`}<br/>
                    {`const { getNodeAutoInstrumentations } = require('@opentelemetry/auto-instrumentations-node');`}<br/><br/>
                    {`const sdk = new NodeSDK({`}<br/>
                    &nbsp;&nbsp;{`traceExporter: new ConsoleSpanExporter(),`}<br/>
                    &nbsp;&nbsp;{`metricReader: new PeriodicExportingMetricReader({`}<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;{`exporter: new ConsoleMetricExporter(),`}<br/>
                    &nbsp;&nbsp;{`}),`}<br/>
                    &nbsp;&nbsp;{`instrumentations: [getNodeAutoInstrumentations()]`}<br/>
                    {`});`}<br/><br/>
                    {`sdk.start();`}
                </p>
            </p>
            <br />
            <p className="text-area">
                {getContentOtelSdkApi("SEC_10_PT_9")}
                <p className="code-area">
                    {`import { diag, DiagConsoleLogger, DiagLogLevel } from '@opentelemetry/api';`}<br/>
                    {`diag.setLogger(new DiagConsoleLogger(), DiagLogLevel.DEBUG);`}<br/>
                </p>
            </p>
            <br />
            <p className="text-area">
                {getContentOtelSdkApi("SEC_10_PT_10")}
                <p className="code-area">
                    {`npx ts-node --require ./instrumentation.ts app.ts`}
                </p>
            </p>
            <br />
            <p className="text-area">
                {getContentOtelSdkApi("SEC_10_PT_11")}
                <img
                    src="posts/otelSdk/grafana-metrics-sdk.png"
                    className="image-big width-full"
                    alt="metrics on grafana"
                />
            </p>

            <h2 id="manual-instrumentation" className="sub-title-small">{getContentOtelSdkApi("SEC_11_TITLE")}</h2>
            <p className="text-area">{getContentOtelSdkApi("SEC_11_PT_1")}</p>
            <br />
            <p className="text-area">
                {getContentOtelSdkApi("SEC_11_PT_2")}
                <p className="code-area">
                    {`npm install --save @opentelemetry/sdk-trace-node`}<br/>
                    {`npm install --save @opentelemetry/sdk-trace-base`}<br/>
                    {`npm install --save @opentelemetry/instrumentation`}<br/>
                    {`# ${getContentOtelSdkApi("SEC_11_PT_3")}`}<br/>
                    {`npm install --save @opentelemetry/host-metrics`}<br/>
                    {`npm install --save @opentelemetry/instrumentation-http`}<br/>
                </p>
            </p>
            <br />
            <p className="text-area">
                {getContentOtelSdkApi("SEC_11_PT_4")}
                <span className="code">{`instrumentation.ts`}</span>
                {getContentOtelSdkApi("SEC_11_PT_5")}
                <p className="code-area">
                    {`const { NodeTracerProvider } = require('@opentelemetry/sdk-trace-node');`}<br/>
                    {`const { OTLPMetricExporter } = require('@opentelemetry/exporter-metrics-otlp-http');`}<br/>
                    {`const { OTLPTraceExporter } = require('@opentelemetry/exporter-trace-otlp-http');`}<br/>
                    {`const { SimpleSpanProcessor } = require('@opentelemetry/sdk-trace-base');`}<br/>
                    {`const { MeterProvider, PeriodicExportingMetricReader } = require('@opentelemetry/sdk-metrics');`}<br/>
                    {`const { HostMetrics } = require('@opentelemetry/host-metrics');`}<br/>
                    {`const { HttpInstrumentation } = require('@opentelemetry/instrumentation-http');`}<br/>
                    {`const { registerInstrumentations } = require('@opentelemetry/instrumentation');`}<br/><br/>
                    {`const metricExporter = new OTLPMetricExporter();`}<br/>
                    {`const meterProvider = new MeterProvider();`}<br/>
                    {`meterProvider.addMetricReader(new PeriodicExportingMetricReader({`}<br/>
                    &nbsp;&nbsp;{`exporter: metricExporter,`}<br/>
                    &nbsp;&nbsp;{`exportIntervalMillis: 1000,`}<br/>
                    {`}));`}<br/><br/>
                    {`const hostMetrics = new HostMetrics({ meterProvider, name: 'example-host-metrics' });`}<br/>
                    {`hostMetrics.start();`}<br/><br/>
                    {`const traceExporter = new OTLPTraceExporter();`}<br/>
                    {`const provider = new NodeTracerProvider();`}<br/>
                    {`provider.addSpanProcessor(new SimpleSpanProcessor(traceExporter));`}<br/>
                    {`provider.register();`}<br/><br/>
                    {`registerInstrumentations({`}<br/>
                    &nbsp;&nbsp;{`instrumentations: [`}<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;{`new HttpInstrumentation(),`}<br/>
                    &nbsp;&nbsp;{`],`}<br/>
                    {`});`}<br/>
                </p>
            </p>
            <br />
            <p className="text-area">
                {getContentOtelSdkApi("SEC_11_PT_6")}
                <img
                    src="posts/otelSdk/grafana-metrics-manual.png"
                    className="image-big width-full"
                    alt="metrics on grafana"
                />
            </p>
            <br />
            <p className="text-area">
                {getContentOtelSdkApi("SEC_11_PT_7")}
                <p className="code-area">
                    {`const meter = meterProvider.getMeter('example-meter');`}<br/><br/>
                    {`# ${getContentOtelSdkApi("SEC_11_PT_8")}`}<br/>
                    {`const counter = meter.createCounter('metric_name');`}<br/><br/>
                    {`# ${getContentOtelSdkApi("SEC_11_PT_9")}`}<br/>
                    {`counter.add(1, { 'key': 'value' });`}<br/>
                </p>
            </p>
            <br />
            <p className="text-area">
                {getContentOtelSdkApi("SEC_11_PT_10")}
                <p className="code-area">
                    {`npx ts-node --require ./instrumentation.ts app.ts`}
                </p>
            </p>
            <br />
            <p className="text-area">{getContentOtelSdkApi("SEC_11_PT_11")}</p>

            <h2 id="summary" className="sub-title">{getContentOtelSdkApi("SEC_12_TITLE")}</h2>
            <p className="text-area">{getContentOtelSdkApi("SEC_12_PT_1")}</p>
            <br />
            <p className="text-area">{getContentOtelSdkApi("SEC_12_PT_2")}</p>
            <br />
            <p className="text-area">{getContentOtelSdkApi("SEC_12_PT_3")}</p>
            <br />
            <p className="text-area">{getContentOtelSdkApi("SEC_12_PT_4")}</p>
            <br />
            <p className="text-area">{getContentOtelSdkApi("SEC_12_PT_5")}</p>


            <h2 id="references" className="sub-title">{getContentOtelSdkApi("SEC_13_TITLE")}</h2>
            <p className="text-area">
                <Link
                    href="https://github.com/maryliag/blog-sdk"
                    target="_blank"
                >
                    {getContentOtelSdkApi("SEC_13_PT_1")}
                </Link>
                <br />
                <br />
                <Link
                    href="https://opentelemetry.io/docs/languages/"
                    target="_blank"
                >
                    {getContentOtelSdkApi("SEC_13_PT_2")}
                </Link>
                <br />
                <br />
                <Link
                    href="https://github.com/open-telemetry/opentelemetry-js-contrib/blob/main/metapackages/auto-instrumentations-node/README.md"
                    target="_blank"
                >
                    {getContentOtelSdkApi("SEC_13_PT_3")}
                </Link>
                <br />
                <br />
                <Link
                    href="https://github.com/open-telemetry/opentelemetry-js"
                    target="_blank"
                >
                    {getContentOtelSdkApi("SEC_13_PT_4")}
                </Link>
                <br />
                <br />
            </p>
          </section>
        </>)
}
