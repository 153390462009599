
import { getText } from "../texts/texts";
import Link from "@mui/material/Link";
import "./tableOfContents.scss"

export interface headerItem {
    label: string;
    id: string;
    subItems?: headerItem[];
}

interface tableOfContentsProps {
    headers: headerItem[];
    postID: string;
}

export function TableOfContents(props: tableOfContentsProps){
    return (
    <div className="table-of-contents-area">
        <h3 className="title-index">{getText("TABLE_CONTENTS")}</h3>
        <ul>
            {props.headers.map(function(header, idx) {
                return (
                    <li key={`header-${idx}`}>
                        <Link
                            href={`?postID=${props.postID}#${header.id}`}
                            underline="always"
                        >
                        {header.label}
                        </Link>
                        {getSubItems(props.postID, header.subItems)}
                    </li>)
            })}
        </ul>
        
    </div>);
}

function getSubItems(postID: string, subItems?: headerItem[]) {
    if (subItems) {
        const items = (subItems?.map(function(subHeader, subIdx) {
            return (
            <li key={`header-${subIdx}`}>
                <Link
                    href={`?postID=${postID}#${subHeader.id}`}
                    underline="always"
                >
                {subHeader.label}
                </Link>
            </li>);
        }))
        return (<ul>{items}</ul>)
    } else {
        return;
    }
}