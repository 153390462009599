import "./obsOnCall.scss";
import "../posts.scss";
import { getText } from "../../texts/texts";
import { Header } from "../../components/header";
import { getContentObsOnCall } from "./contentObsOnCall";
import { useEffect, useState } from "react";
import { isSmallScreen, trackConfetti } from "../../utils";
import { DiagramBlock } from "../../components/diagramBlock";
import { Link } from "@mui/material";
import confetti from "canvas-confetti";
import { TableOfContents, headerItem } from "../../components/tableOfContents";

export function ObsOnCall() {
  const headers: headerItem[] = [
    {label: getContentObsOnCall("ON_CALL"), id: `on-call`},
    {label: getContentObsOnCall("OBS"), id: `observability`},
    {label: getContentObsOnCall("USER"), id: `user`},
    {label: getContentObsOnCall("SUPPORTER"), id: `person-on-call`},
    {label: getContentObsOnCall("ANALYSIS"), id: `analysis`},
    {label: getContentObsOnCall("OBS_ON_CALL"), id: `obs-on-call`},
  ];
  const [scratched, setScratched] = useState("");
  const [imagesSeparated, setImagesSeparated] = useState(true);
  const particles = isSmallScreen() ? 100 : 300;

  useEffect(() => {
    const onScroll = () => {
      // Scratch line.
      const top =
        document.getElementById("definition")?.getBoundingClientRect()?.top ||
        0;
      const threshold = isSmallScreen() ? 170 : 250;
      if (top < threshold) {
        setScratched("scratched");
      } else {
        setScratched("");
      }

      // Bring images together.
      const imageLeft = document.getElementById("fusion-1");
      const imageRight = document.getElementById("fusion-2");
      if (imageLeft && imageRight) {
        const viewHeight =
          window.innerHeight - imageLeft.getBoundingClientRect().height;
        const topThreshold = window.innerHeight * 0.05;
        const imgTop = imageLeft.getBoundingClientRect().top;

        if (imgTop < topThreshold) {
          imageLeft.style.left = `calc(50% - ${
            imageLeft.getBoundingClientRect().width
          }px)`;
          imageRight.style.right = `calc(50% - ${
            imageRight.getBoundingClientRect().width
          }px)`;
        } else {
          let pct =
            100 - ((imgTop - topThreshold) * 100) / (viewHeight - topThreshold);

          if (pct >= 50) {
            pct = 50;
            if (imagesSeparated) {
              setImagesSeparated(false);
              confetti({
                particleCount: particles,
                spread: 70,
                origin: { y: 0.6 },
              });
              trackConfetti("L-obs-on-call");
            }
          } else {
            setImagesSeparated(true);
          }

          if (
            pct * 0.01 * window.innerWidth -
              imageLeft.getBoundingClientRect().width <
            0
          ) {
            imageLeft.style.left = `0px`;
            imageRight.style.right = `0px`;
          } else {
            imageLeft.style.left = `calc(${pct}% - ${
              imageLeft.getBoundingClientRect().width
            }px)`;
            imageRight.style.right = `calc(${pct}% - ${
              imageRight.getBoundingClientRect().width
            }px)`;
          }
        }
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [imagesSeparated, setImagesSeparated, particles]);

  return (
    <>
      <Header text={getText("LOCAL_2_TITLE")} bck_img="title_bkg_09.png" />
      <section className="content-area">
        <TableOfContents headers={headers} postID="L-obs-on-call"/>

        <p className="text-area">{getContentObsOnCall("SEC_1_PT_1")}</p>
        <br />
        <p className="text-area">{getContentObsOnCall("SEC_1_PT_2")}</p>

        <h2 id="on-call" className="sub-title">{getContentObsOnCall("ON_CALL")}</h2>
        <p id="definition" className={`text-area ${scratched}`}>
          {getContentObsOnCall("SEC_2_PT_1")}
        </p>
        <br />
        <p className="text-area">{getContentObsOnCall("SEC_2_PT_2")}</p>
        <br />
        <p className="text-area">{getContentObsOnCall("SEC_2_PT_3")}</p>
        <br />
        <p className="text-area">{getContentObsOnCall("SEC_2_PT_4")}</p>
        <br />
        <p className="text-area">{getContentObsOnCall("SEC_2_PT_5")}</p>
        <br />
        <p className="text-area">{getContentObsOnCall("SEC_2_PT_6")}</p>
        <br />
        <p className="text-area">{getContentObsOnCall("SEC_2_PT_7")}</p>
        <br />
        <p className="text-area">{getContentObsOnCall("SEC_2_PT_8")}</p>

        <img
          src="posts/obsOnCall/panda-angry.gif"
          className="image-big"
          alt="angry panda"
        />

        <h2 id="observability" className="sub-title">{getContentObsOnCall("OBS")}</h2>
        <p className="text-area">{getContentObsOnCall("SEC_3_PT_1")}</p>
        <br />
        <p className="text-area">
          {getContentObsOnCall("SEC_3_PT_2")}{" "}
          <Link
            href="?postID=L-obs-3-pillars"
            underline="always"
            target="_blank"
          >
            {getContentObsOnCall("SEC_3_PT_3")}
          </Link>
          {"."}
        </p>
        <br />
        <p className="text-area">{getContentObsOnCall("SEC_3_PT_4")}</p>

        <h2 id="user" className="sub-title">{getContentObsOnCall("USER")}</h2>
        <p className="text-area">{getContentObsOnCall("SEC_4_PT_1")} </p>
        <br />
        <p className="text-area">{getContentObsOnCall("SEC_4_PT_2")}</p>
        <img
          src="posts/obsOnCall/bugs.png"
          className="image-big"
          alt="angry panda"
        />
        <p className="translate-text">{getContentObsOnCall("IMG_2")}</p>
        <p className="text-area">
          <br />
          {getContentObsOnCall("SEC_4_PT_3")}{" "}
        </p>
        <br />
        <p className="text-area">{getContentObsOnCall("SEC_4_PT_4")} </p>
        <br />
        <p className="text-area">{getContentObsOnCall("SEC_4_PT_5")} </p>
        <br />
        <p className="text-area">{getContentObsOnCall("SEC_4_PT_6")}</p>

        <h2 id="person-on-call" className="sub-title">{getContentObsOnCall("SUPPORTER")}</h2>
        <img
          src="posts/obsOnCall/on-off.gif"
          className="image-big"
          alt="on and off again"
        />
        <p className="translate-text">{getContentObsOnCall("IMG_1")}</p>
        <br />
        <p className="text-area">{getContentObsOnCall("SEC_5_PT_1")}</p>
        <div className="diagram-area diagram-area-small">
          <DiagramBlock
            text={getContentObsOnCall("SEC_5_PT_2")}
            enabled={true}
          />
          <img src="posts/obsOnCall/arrow.png" className="arrow" alt="arrow" />
          <DiagramBlock
            text={getContentObsOnCall("SEC_5_PT_3")}
            enabled={true}
          />
        </div>
        <p className="text-area">
          {getContentObsOnCall("SEC_5_PT_6")}
          <br />
          <br />
        </p>
        <section className="diagram-area">
          <DiagramBlock
            text={getContentObsOnCall("SEC_5_PT_2")}
            enabled={true}
          />
          <img src="posts/obsOnCall/arrow.png" className="arrow" alt="arrow" />
          <DiagramBlock
            text={getContentObsOnCall("SEC_5_PT_3")}
            enabled={true}
          />
          <img src="posts/obsOnCall/arrow.png" className="arrow" alt="arrow" />
          <DiagramBlock
            text={getContentObsOnCall("SEC_5_PT_4")}
            enabled={false}
          />
          <img src="posts/obsOnCall/arrow.png" className="arrow" alt="arrow" />
          <DiagramBlock
            text={getContentObsOnCall("SEC_5_PT_5")}
            enabled={false}
          />
        </section>
        <p className="text-area">
          {getContentObsOnCall("SEC_5_PT_7")}
          <br />
          <br />
          {getContentObsOnCall("SEC_5_PT_8")}
          <br />
          <br />
          {getContentObsOnCall("SEC_5_PT_9")}
        </p>

        <h2 id="analysis" className="sub-title">{getContentObsOnCall("ANALYSIS")}</h2>
        <p className="text-area">{getContentObsOnCall("SEC_6_PT_1")}</p>
        <br />
        <p className="text-area">{getContentObsOnCall("SEC_6_PT_2")}</p>
        <br />
        <p className="text-area">{getContentObsOnCall("SEC_6_PT_3")}</p>
        <br />
        <p className="text-area">{getContentObsOnCall("SEC_6_PT_4")}</p>

        <h2 id="obs-on-call" className="sub-title">{getContentObsOnCall("OBS_ON_CALL")}</h2>
        <div>
          <div className="combine-images-area">
            <img
              id="fusion-1"
              src="posts/obsOnCall/fusion1.png"
              className="image-left"
              alt="fusion part 1"
            />{" "}
            <img
              id="fusion-2"
              src="posts/obsOnCall/fusion2.png"
              className="image-right"
              alt="fusion part 2"
            />
          </div>
        </div>

        <p className="text-area">{getContentObsOnCall("SEC_7_PT_1")}</p>
        <br />
        <p className="text-area">{getContentObsOnCall("SEC_7_PT_2")}</p>
        <br />
        <p className="text-area">{getContentObsOnCall("SEC_7_PT_3")}</p>
      </section>
    </>
  );
}
