import { Header } from "../../components/header";
import Link from "@mui/material/Link";
import { getText } from "../../texts/texts";
import { getContentOtel } from "./contentOtel";
import "./otel.scss";
import { TextLabelDescription } from "../../components/textUtils";
import { TableOfContents, headerItem } from "../../components/tableOfContents";

export function Otel() {
    const headers: headerItem[] = [
        {label: getContentOtel("SEC_2_TITLE"), id: `problem`},
        {label: getContentOtel("SEC_3_TITLE"), id: `everybody-wins`},
        {label: getContentOtel("SEC_4_TITLE"), id: `standards`},
        {label: getContentOtel("SEC_5_TITLE"), id: `what-is-otel`},
        {label: getContentOtel("SEC_6_TITLE"), id: `components`},
        {label: getContentOtel("SEC_7_TITLE"), id: `challenges`},
        {label: getContentOtel("SEC_8_TITLE"), id: `contribute`},
        {label: getContentOtel("SEC_9_TITLE"), id: `references`},
    ];
    return (
        <>
          <Header text={getText("LOCAL_4_TITLE")} bck_img="title_bkg_01.png" />
          <section className="content-area">
            <TableOfContents headers={headers} postID="L-otel-1"/>

            <p className="text-area">{getContentOtel("SEC_1_PT_1")}</p>

            <h2 id="problem" className="sub-title">{getContentOtel("SEC_2_TITLE")}</h2>
            <p className="text-area">
                {getContentOtel("SEC_2_PT_1")}{" "}
                <Link
                    href="?postID=L-obs-3-pillars"
                    underline="always"
                    target="_blank"
                >
                    {getText("LOCAL_1_TITLE")}
                </Link>
                {getContentOtel("SEC_2_PT_2")}
            </p>
            <br />
            <p className="text-area">{getContentOtel("SEC_2_PT_3")}</p>
            <br />
            <p className="text-area">{getContentOtel("SEC_2_PT_4")}</p>
            <br />
            <p className="text-area">{getContentOtel("SEC_2_PT_5")}</p>
            <br />
            <p className="text-area">{getContentOtel("SEC_2_PT_6")}</p>
            <br />
            <p className="text-area">{getContentOtel("SEC_2_PT_7")}</p>
            <br />
            <p className="text-area">{getContentOtel("SEC_2_PT_8")}</p>

            <h2 id="everybody-wins" className="sub-title">{getContentOtel("SEC_3_TITLE")}</h2>
            <p className="text-area">{getContentOtel("SEC_3_PT_1")}</p>
            <br />
            <p className="text-area">{getContentOtel("SEC_3_PT_2")}</p>
            <br />
            <p className="text-area">{getContentOtel("SEC_3_PT_3")}</p>
            <br />
            <p className="text-area">{getContentOtel("SEC_3_PT_4")}</p>

            <h2 id="standards" className="sub-title">{getContentOtel("SEC_4_TITLE")}</h2>
            <p className="text-area">{getContentOtel("SEC_4_PT_1")}</p>
            <br />
            <p className="text-area">{getContentOtel("SEC_4_PT_2")}</p>
            <br />
            <p className="text-area">{getContentOtel("SEC_4_PT_3")}</p>

            <h2 id="what-is-otel" className="sub-title">{getContentOtel("SEC_5_TITLE")}</h2>
            <p className="text-area">{getContentOtel("SEC_5_PT_1")}</p>
            <br />
            <p className="text-area">{getContentOtel("SEC_5_PT_2")}</p>
            <br />
            <p className="text-area">{getContentOtel("SEC_5_PT_3")}</p>
            <br />
            <p className="text-area">{getContentOtel("SEC_5_PT_4")}</p>
            <br />
            <p className="text-area">{getContentOtel("SEC_5_PT_5")}</p>

            <h2 id="components" className="sub-title">{getContentOtel("SEC_6_TITLE")}</h2>
            <p className="text-area">{getContentOtel("SEC_6_PT_1")}</p>
            <br />
            <TextLabelDescription 
                label={getContentOtel("SEC_6_PT_2")} 
                description={getContentOtel("SEC_6_PT_3")}
            />
            <br />
            <TextLabelDescription 
                label={getContentOtel("SEC_6_PT_4")} 
                description={getContentOtel("SEC_6_PT_5")}
            />
            <br />
            <TextLabelDescription 
                label={getContentOtel("SEC_6_PT_6")} 
                description={getContentOtel("SEC_6_PT_7")}
            />
            <br />
            <TextLabelDescription 
                label={getContentOtel("SEC_6_PT_8")} 
                description={getContentOtel("SEC_6_PT_9")}
                link="?postID=L-otel-sdk-api"
            />
            <br />
            <TextLabelDescription 
                label={getContentOtel("SEC_6_PT_10")} 
                description={getContentOtel("SEC_6_PT_11")}
                link="?postID=L-otel-sdk-api"
            />
            <br />
            <TextLabelDescription 
                label={getContentOtel("SEC_6_PT_12")} 
                description={getContentOtel("SEC_6_PT_13")}
            />
            <br />
            <TextLabelDescription 
                label={getContentOtel("SEC_6_PT_14")} 
                description={getContentOtel("SEC_6_PT_15")}
            />
            <br />
            <TextLabelDescription 
                label={getContentOtel("SEC_6_PT_16")} 
                description={getContentOtel("SEC_6_PT_17")}
            />
            <br />
            <p className="text-area">{getContentOtel("SEC_6_PT_18")}</p>


            <h2 id="challenges" className="sub-title">{getContentOtel("SEC_7_TITLE")}</h2>
            <p className="text-area">{getContentOtel("SEC_7_PT_1")}</p>
            <br />
            <p className="text-area">{getContentOtel("SEC_7_PT_2")}</p>

            <h2 id="contribute" className="sub-title">{getContentOtel("SEC_8_TITLE")}</h2>
            <p className="text-area">{getContentOtel("SEC_8_PT_1")}</p>
            <br />
            <p className="text-area">{getContentOtel("SEC_8_PT_2")}</p>

            <h2 id="references" className="sub-title">{getContentOtel("SEC_9_TITLE")}</h2>
            <p className="text-area">
                <Link
                    href="https://opentelemetry.io/"
                    target="_blank"
                >
                    {getContentOtel("SEC_9_PT_1")}
                </Link>
                <br />
                <br />
                <Link
                    href="https://www.cncf.io/"
                    target="_blank"
                >
                    {getContentOtel("SEC_9_PT_2")}
                </Link>
                <br />
                <br />
                <Link
                    href="https://opentracing.io/"
                    target="_blank"
                >
                    {getContentOtel("SEC_9_PT_3")}
                </Link>
                <br />
                <br />
                <Link
                    href="https://opencensus.io/"
                    target="_blank"
                >
                    {getContentOtel("SEC_9_PT_4")}
                </Link>
                <br />
                <br />
                <Link
                    href="https://opentelemetry.io/docs/specs/otel"
                    target="_blank"
                >
                    {getContentOtel("SEC_9_PT_5")}
                </Link>
                <br />
                <br />
                <Link
                    href="https://opentelemetry.io/docs/specs/otlp/"
                    target="_blank"
                >
                    {getContentOtel("SEC_9_PT_6")}
                </Link>
                <br />
                <br />
                <Link
                    href="https://opentelemetry.io/docs/specs/semconv/"
                    target="_blank"
                >
                    {getContentOtel("SEC_9_PT_7")}
                </Link>
                <br />
                <br />
                <Link
                    href="https://opentelemetry.io/docs/languages"
                    target="_blank"
                >
                    {getContentOtel("SEC_9_PT_8")}
                </Link>
                <br />
                <br />
                <Link
                    href="https://opentelemetry.io/ecosystem/registry"
                    target="_blank"
                >
                    {getContentOtel("SEC_9_PT_9")}
                </Link>
                <br />
                <br />
                <Link
                    href="https://opentelemetry.io/docs/collector"
                    target="_blank"
                >
                    {getContentOtel("SEC_9_PT_10")}
                </Link>
                <br />
                <br />
                <Link
                    href="https://opentelemetry.io/docs/kubernetes/operator/"
                    target="_blank"
                >
                    {getContentOtel("SEC_9_PT_11")}
                </Link>
                <br />
                <br />
                <Link
                    href="https://opentelemetry.io/docs/kubernetes/helm/"
                    target="_blank"
                >
                    {getContentOtel("SEC_9_PT_12")}
                </Link>
                <br />
                <br />
                <Link
                    href="https://opentelemetry.io/docs/faas/"
                    target="_blank"
                >
                    {getContentOtel("SEC_9_PT_13")}
                </Link>
                <br />
                <br />
                <Link
                    href="https://opentelemetry.io/ecosystem/vendors/"
                    target="_blank"
                >
                    {getContentOtel("SEC_9_PT_14")}
                </Link>
                <br />
                <br />
                <Link
                    href="https://github.com/open-telemetry/community#special-interest-groups"
                    target="_blank"
                >
                    {getContentOtel("SEC_9_PT_15")}
                </Link>
                <br />
                <br />
            </p>
          </section>
        </>
      );
    }
    