import { getSiteLanguage } from "../../utils";

export function getContentObs3Pillars(
  key: ContentPortugueseKeys | ContentEnglishKeys
): string {
  if (getSiteLanguage() === "PT") {
    return contentPortuguese[key];
  }
  return contentEnglish[key];
}

type ContentPortugueseKeys = keyof typeof contentPortuguese;
type ContentEnglishKeys = keyof typeof contentEnglish;

const contentEnglish = {
  OBS: `Observability`,
  MONITORING: `Monitoring`,
  METRICS: `Metrics`,
  LOGS: `Logs`,
  TRACE: `Traces`,
  OBS_MON: `Monitoring vs Observability`,
  CARDINALITY: `Cardinality`,
  DIMENSIONALITY: `Dimensionality`,
  REFERENCE: "Reference",
  SEC_1_PT_1: `Observability is defined a lot of times as 3 pillars (metrics, logs and traces) 
    and the terms "monitoring" and "observability" are used interchangeably erroneously. In 
    this post we will see the difference between those terms and understand if Observability 
    should really be defined as 3 pillars.`,
  SEC_1_PT_2: `First, let's understand what is Observability, Metrics, Logs and Traces.`,
  SEC_2_PT_1: `The term Observability comes from mechanical engineering, being the measure of 
    how well internal states of a system can be inferred from knowledge of its external outputs. 
    One way of imagining what is Observability is to remember a car's panel, where you don't 
    need to check personally every detail before using it, it is enough to look at the panel 
    (external output) and you will know how is the water, oil and gas reservoirs, etc 
    (internal states).`,
  SEC_2_PT_2: `Software Engineer borrowed this term, with Observability being defined as how 
    well you can understand and explain any state from your system, no matter how bizarre or 
    novel it is.`,
  SEC_2_PT_3: `Events used in Observability are structured and include information about the 
    context in which that event was executed.`,
  SEC_3_PT_1: `Metric is a number (simple or aggregated), is something cheap, disposable and 
    was used as a base for Telemetry. To be able to create a metric, it is required to know 
    the system, understand what makes sense to monitor and then create the metric and 
    correspondent alerts.`,
  SEC_3_PT_2: `Metrics are used on time series databases, statistics analysis, dashboards, 
  and so on. Since a metric is simple and doesn't contain the context in which that number 
  was generated, it is not enough to understand complex systems.`,
  SEC_3_PT_3: `Very often dashboards are created with several metrics and with time you no 
    longer think what those values really mean, you just remember that when the first chart 
    has a spike you need to restart the server, if the second chart is dropping just clean 
    the cache, and so on.`,
  SEC_4_PT_1: `Logs are files generated from information about your application gathered 
    through events being executed, like errors, problems, warnings or general information. 
    Logs can be structured or not, and are easy for a human to read, but are hard to be parsed.`,
  SEC_4_PT_2: `A single line from a log shows part of an event, but when several lines are 
    combined, it is possible to understand the full event, including its context, and in this 
    case, is very useful for observability.`,
  SEC_5_PT_1: `Traces represent the journey of a request or action across all nodes of a 
    distributed system. Is useful so you can understand the full cycle of an action through 
    several systems.`,
  SEC_5_PT_2: `Previously, systems were a lot simpler than the ones from today, and the 
    traditional practices mentioned above were developed with them in mind. With the evolution 
    of these systems and their infrastructure, the tools used on them also need to evolve, 
    and that is where the need for observability came from.`,
  SEC_5_PT_3: `Thinking about this information, we can say that metrics, logs and traces 
    are the pillars of the monitoring.`,
  SEC_6_MON_1: `• Need to know the system to define which values make sense to monitor`,
  SEC_6_MON_2: `• Reactive. At some point, you realize that a specific information would 
    have been useful (for debugging, on-call, analysis, etc), so the new metric/log/trace 
    is created for future usage`,
  SEC_6_MON_3: `• Available information is limited, since the context is not provided`,
  SEC_6_OBS_1: `• Doesn't need to know the system, since all available information will be sent`,
  SEC_6_OBS_2: `• Proactive. Send all events with its context, even if you don't know if 
    they are helpful, because it can be useful in the future (for debugging, on-call, 
    analysis, etc)`,
  SEC_6_OBS_3: `• Provide the context as part of the event`,
  SEC_7_PT_1: `With monitoring there is a limit of questions that can be made, since there 
    is a limitation on the available information.`,
  SEC_7_PT_2: `When we think about observability, it is possible to answer more questions, 
    if you know where to look.`,
  SEC_7_PT_3: `This means that different profiles will be able to adapt better in each area.`,
  SEC_8_MON_1: `Experienced person: When an error has already happened previously, the 
    person with more experience will be able to solve it faster, since they saw it before 
    and won't need the extra information about the context. An inexperienced person will 
    have more challenges to understand the cause of that problem without knowing the context 
    of that event.`,
  SEC_8_OBS_1: `Curious person: Every person should get to the same conclusion and solve 
    the issue with the information provided by observability, no matter the experience level 
    they have on the system. The most curious person that makes more questions and data 
    analysis will be able to solve the problems quicker.`,
  SEC_9_PT_1: `So what is really important to Observability that makes it different from Monitoring?`,
  SEC_9_PT_2: `The cardinality and dimensionality of the structured events used by it.`,
  SEC_10_PT_1: `Every event has its own key that will make it possible to identify it later. 
    The more unique the key is, the bigger the cardinality will be, which makes it even easier 
    to find the specific event you're looking for.`,
  SEC_10_PT_2: `A challenge in this area is how to define those keys, because they need to 
    be unique enough so the details about that event can be found when necessary, but also 
    created in a form that doesn't increase the amount of unique events so much that you cross 
    the limits set for costs and storage of your application.`,
  SEC_10_PT_3: `Let's take a look at some examples of how you can define a key.`,
  SEC_11_PT_1: `Query in a Database`,
  SEC_11_PT_2: `A workload will have a variety of queries, for this example we will use a 
    list with some simple SELECTs and DELETEs in different tables with a few filters. Now we 
    need to decide how we will create the key for these queries, so we can save their respective 
    statistics and be able to find them later.`,
  SEC_11_PT_3: `The first possibility is the most simple, where all queries are simply classified 
    as "query". The challenge with this approach is that when you have a problem, for example, a 
    specific time always have a spike on query latency, you will try to check which queries were 
    being executed at that time, but all of them have the same classification as "query", so there 
    isn't enough information to understand which one is causing the problem.`,
  SEC_11_PT_4: `The next possibility is to separate by type, in this case SELECT and DELETE. Now 
    it is possible to identify a class of queries, eliminating all other types, but there are 
    still too many possible queries.`,
  SEC_11_PT_5: `When we add the table in which the query was executed, we have a clearer image 
    of where the problem lies. From this moment on, we're no longer guessing without any basis 
    what the problematic query is, because now we know it's a SELECT on a specific table.`,
  SEC_11_PT_6: `Now we need to think about what other information we can add to the key, without 
    adding more than necessary. In this example, we add the WHERE because additional clauses 
    (as well as selecting specific columns), will use a different execution plan for the query 
    and that can radically influence the performance of the query.`,
  SEC_11_PT_7: `Here there is a decision to not add the value of the WHERE, as it would greatly 
    increase the cardinality without too many benefits, since there isn't a lot of difference from 
    "x = 1" versus "x = 2" regarding the query performance. This means we can simply replace the 
    values for "_". This is the last step to define a key for the queries.`,
  SEC_12_PT_1: `Endpoints`,
  SEC_12_PT_2: `An application can have several endpoints,  and when a request is executed we 
    can collect statistics about its status, location, execution time, etc. With the previous 
    example in mind, if we save all requests with the key "endpoint" it will be very hard to analyze 
    later on and make improvements or fix problems.`,
  SEC_12_PT_3: `We can separate by routes, for example "/login", "/checkout", "list_products".`,
  SEC_12_PT_4: `If there are specific parameters for each route, those are worth being added as 
    part of the key. For example, the order selected for the sorting when listing products, the 
    date a product was added, the type of product, etc. Since those values are limited (compared 
    with the previous example where the number of possible values on the WHERE clause is unlimited), 
    it makes sense to add them as part of the key, because they can influence your application and 
    give priority to the most popular choices.`,
  SEC_13_PT_1: `Events used in Observability (and also Telemetry) are considered "wide", because 
    they can have hundreds or thousands of key-value pairs.`,
  SEC_13_PT_2: `The richer the events are in information, more questions can be answered. For 
    example: what are all the calls that returned a 502 error in the last hour made by user X?`,
  SEC_13_PT_3: `Going back to the previous examples, the information that could be collected for 
    each query is: which transaction it was part of, the application, the database/schema, status 
    (failure/success), amount of executions, execution latency, memory and CPU usage, rows reads 
    and writes, execution plan, etc.
    For the endpoint example, we can collect information such as the header, session token, user ID, 
    shopping cart ID, status (failure/success), location, etc.`,
  SEC_13_PT_4: `If we need to summarize the two terms discussed above, we can say Cardinality refers 
    to the amount of events sent and Dimensionality refers to the dimension (amount of information) 
    of each event.`,
  SEC_14_PT_1: `Do I need Observability?`,
  SEC_14_PT_2: `Observability is not mandatory for the proper functioning of all systems. If your 
    application is simple, has little use/users, monitoring will often be sufficient and there is 
    nothing wrong with that.`,
  SEC_14_PT_3: `For more complex systems that have a variety of uses, your life will be much easier 
    if observability is part of your system.`,
  SEC_14_PT_4: `Imagine that each error that occurs in your application forms a new puzzle. Monitoring 
    will give you some pieces, and if you already have experience in the area or have seen those 
    pieces previously, they will be enough for you to understand what image is being formed and fix 
    the problem. When the error is more complex, just a few pieces will not be enough, and you need 
    several pieces (information) to have a more complete picture and understand the entire context, 
    and thus be able to solve the problem.`,
  SEC_15_PT_1: `Example`,
  SEC_15_PT_2: `Let's finish with an example of how the same problem would be solved with Monitoring 
    versus Observability.`,
  SEC_15_PT_3: `In this scenario, you make Legos and receive a call from a user, saying that they 
    followed all the instructions given, but that something went wrong and the end result was not 
    the same as described in the guide.`,
  SEC_15_PT_4: `For simplicity, let's imagine that all the pieces are numbered and are fitted in order, 
    that is, 2 fits into 1, 3 fits into 2, and so on.`,
  SEC_16_PT_1: ` with the ID provided by the user, you can check the entire route of that specific 
    box of Legos. You can see that the parts were produced and passed quality control at the factory, 
    then the box was sent by mail and then used by the user.`,
  SEC_16_PT_2: `Here we can confirm that there is no problem with the product itself and it reached 
    the user as expected and the product was not lost along the way.`,
  SEC_16_PT_3: ` analyzing the logs of when the user was fitting the pieces, we can see the pieces 
  being fitted: 1, 2, 3, 4, 6, 7...`,
  SEC_16_PT_4: `But wait, where is piece 5? Here we can see that it was not fitted!`,
  SEC_16_PT_5: ` we know that this box has 1000 pieces, so let's look at the metrics collected. We 
    realize that the total number of pieces used was 999 and there are 0 pieces left in the box.`,
  SEC_16_PT_6: `Here we can confirm that there is indeed a piece missing from the final build, but 
    it is also not in the box.`,
  SEC_16_PT_7: `With this information in hand we can give the user an answer, explaining that they 
    need the piece number 5, and you can even detail the piece so that they can buy a new one 
    separately.`,
  SEC_16_PT_8: `This solves the user's problem, but you don't know exactly what happened, which means 
    you won't be able to prevent another occurrence. The next step will be to imagine what reasons may 
    have led to this problem and add more metrics, logs and/or traces, so when it happens again you 
    may be able to solve the mystery.`,
  SEC_17_PT_1: ` each moving piece is an event. Here we will be able to see if all the pieces were used.`,
  SEC_17_PT_2: ` each event will have information about the context of the movement, such as the time, 
    number of the piece in which the current one was fitted, coordinates, and even things that you 
    don't know if you will need, such as the color of the piece, its humidity level, etc.`,
  SEC_17_PT_3: `With the information from these events we will be able to discover that… the dog 
    ate the piece!`,
};

const contentPortuguese = {
  OBS: `Observabilidade`,
  MONITORING: `Monitoramento`,
  METRICS: `Métricas`,
  LOGS: `Logs`,
  TRACE: `Rastreamento (Traces)`,
  OBS_MON: `Monitoramento vs Observabilidade`,
  CARDINALITY: `Cardinalidade`,
  DIMENSIONALITY: `Dimensionalidade`,
  REFERENCE: "Referência",
  SEC_1_PT_1: `Observabilidade é muitas vezes definida como 3 pilares (métricas, logs 
    e rastreamento) e os termos "monitoramento" e "observabilidade" são utilizados 
    intercambiavelmente de forma errada. Neste post vamos ver a diferença entre esses 
    termos e entender se realmente a Observabilidade deveria ser definida como 3 pilares.`,
  SEC_1_PT_2: `Primeiro, vamos entender o que é Observabilidade, Métricas, Logs e Rastreamento.`,
  SEC_2_PT_1: `O termo Observabilidade vem da engenharia mecânica, sendo definido como o 
    quão bem um estado interno pode ser inferido através do conhecimento de suas saídas. Uma 
    forma de pensar o que é observabilidade é lembrar do painel de um carro, onde você não 
    precisa ir pessoalmente verificar todos os detalhes do seu carro toda vez que for 
    utilizá-lo, basta olhar o painel (saída) e você saberá como estão os reservatórios 
    de água, óleo, gasolina, pressão dos pneus, etc (estados internos).`,
  SEC_2_PT_2: `A Engenharia de Software emprestou esse termo, sendo então definida como 
    o quão bem você consegue entender e explicar qualquer estado do seu sistema, 
    independente de quão improvável ou diferente ele seja.`,
  SEC_2_PT_3: `Os eventos utilizados em observabilidade são estruturados e incluem 
    informação sobre o contexto em que foi executado.`,
  SEC_3_PT_1: `Métrica nada mais é do que um número (simples ou agregado), é algo barato, 
    descartável e foi usado como base inicial para Telemetria. Para criar uma métrica, é 
    preciso conhecer o sistema, entender o que faz sentido monitorar e então criar a nova 
    métrica e alertas.`,
  SEC_3_PT_2: `Métricas são usadas em bancos time series, análises estatísticas, criação 
    de dashboards, etc. Por ser mais simples e não possuir o contexto em que aquele número 
    foi gerado, a métrica passa a não ser suficiente para entender sistemas mais complexos.`,
  SEC_3_PT_3: `Muitas vezes dashboard são criados com várias métricas e com o tempo você 
    nem para mais para pensar o que o valor realmente significa, você apenas lembra que 
    se o primeiro gráfico estiver aumentando tem que reiniciar o servidor, se o segundo 
    estiver caindo precisa limpar caches, e assim por diante.`,
  SEC_4_PT_1: `Logs são arquivos gerados contendo informações sobre a sua aplicação conforme 
    eventos vão sendo executados, como erros, problemas, avisos ou detalhes em geral. Logs 
    podem ser estruturados ou não, e são fáceis para um humano ler, porém difíceis de 
    serem parseados.`,
  SEC_4_PT_2: `Uma única linha de log mostra parte de um evento, mas quando várias linhas 
    são combinadas, é possível entender um evento completo, incluindo seu contexto, e neste 
    caso são muitos úteis para observabilidade.`,
  SEC_5_PT_1: `Rastreamento representa a jornada de um request ou ação, conforme passa 
    por nós de um sistema distribuído. É útil para que se possa entender o ciclo completo 
    da ação através de diversos sistemas.`,
  SEC_5_PT_2: `Os sistemas antigamente eram muito mais simples que os atuais, e as práticas 
    tradicionais mencionadas acima foram desenvolvidas pensando neles. Com a evolução dos 
    sistemas e infraestrutura, as ferramentas utilizadas neles também precisaram evoluir, 
    e daí surgiu a necessidade da observabilidade.`,
  SEC_5_PT_3: `Pensando nestas informações, podemos dizer que métricas, logs e rastreamento 
    são pilares do monitoramento.`,
  SEC_6_MON_1: `• Precisa conhecer o sistema para definir quais valores fazem sentido 
    serem monitorados`,
  SEC_6_MON_2: `• Reativo. Em algum momento percebe-se que tendo certa informação teria 
    sido útil (durante debug, chamados, análises, etc), então a nova métrica/log/trace é 
    criada para futuros usos`,
  SEC_6_MON_3: `• Informação disponível é limitada, já que o contexto não é enviado`,
  SEC_6_OBS_1: `• Não precisa conhecer o sistema, pois envia todas as informações disponíveis`,
  SEC_6_OBS_2: `• Proativo. Envia todos os eventos com seu contexto mesmo sem saber se 
    será necessário, pois pode ser útil no futuro (durante debug, chamados, análises, etc)`,
  SEC_6_OBS_3: `• Passa todo o contexto para o evento`,
  SEC_7_PT_1: `Isso significa que com monitoramento existe um limite de perguntas que 
    podem ser feitas, já que existe uma limitação na informação disponível.`,
  SEC_7_PT_2: `Quando falamos de Observabilidade, é possível responder a mais perguntas, 
    se você souber onde procurar.`,
  SEC_7_PT_3: `Isso significa que diferentes perfis vão conseguir se adaptar melhor 
    para cada área.`,
  SEC_8_MON_1: `Pessoa experiente: Para os erros que já aconteceram anteriormente, a 
    pessoa com mais experiência vai conseguir resolvê-los mais rápido, por já ter visto 
    aqueles valores antes, sem precisar de informações extra sobre o contexto. Já uma pessoa 
    nova no sistema terá mais dificuldade para entender o que pode ter causado aquele 
    problema sem possuir o contexto do evento.`,
  SEC_8_OBS_1: `Pessoa curiosa: Independente do nível de experiência no sistema, todas 
    as pessoas deveriam chegar à mesma conclusão e resolver o problema, com as informações 
    providas pela observabilidade. A pessoa mais curiosa que faz mais perguntas e análise 
    mais dados conseguirá resolver os problemas mais rápido.`,
  SEC_9_PT_1: `O que então é importante para a Observabilidade que a torna diferente 
    do Monitoramento?`,
  SEC_9_PT_2: `A cardinalidade e a dimensionalidade dos eventos estruturados utilizados.`,
  SEC_10_PT_1: `Cada evento possui uma chave, que vai ajudar a identificá-lo posteriormente. 
    Quanto mais chaves únicas, maior a cardinalidade, o que torna mais fácil encontrar o 
    evento específico que se esteja procurando.`,
  SEC_10_PT_2: `Uma dificuldade nesta área é definir como uma chave é criada, de forma 
    que seja única o suficiente para que detalhes sejam encontrados quando necessário, 
    mas que não aumente a quantidade de eventos e consequentemente seus custos e capacidade 
    de armazenamento mais do que sua aplicação foi definida para utilizar.`,
  SEC_10_PT_3: `Vamos olhar alguns exemplos de como definir chaves.`,
  SEC_11_PT_1: `Query em Banco de Dados`,
  SEC_11_PT_2: `O seu workload terá uma variedade de queries, para este exemplo vamos 
    utilizar uma lista de simples SELECTs e DELETEs em tabelas diferentes e com alguns 
    filtros. Agora precisamos definir como criaremos chaves para estas queries, para 
    podermos salvar estatísticas respectivas a cada uma e posteriormente podermos encontrá-las.`,
  SEC_11_PT_3: `A primeira possibilidade é a mais simples possível, onde todas as queries 
    são simplesmente classificadas como "query". A dificuldade é quando você tiver um 
    problema, por exemplo, um certo horário sempre há um aumento na latência das queries, 
    e você tentar olhar quais as queries foram executadas naquele horário, todas estão 
    classificadas apenas como "query", então não há muita informação que possa ajudar a 
    entender o problema.`,
  SEC_11_PT_4: `A próxima possibilidade é separar o tipo, neste caso SELECT e DELETE. 
    Aqui já é possível identificar uma classe de queries, eliminando todas as outras, 
    mas ainda existem muitas queries possíveis.`,
  SEC_11_PT_5: `Quando incluímos a tabela na qual a query foi executada, já temos uma 
    imagem mais clara de onde o problema se encontra. A partir desse momento não estamos mais 
    adivinhando sem nenhuma base qual é a query problemática, aqui já sabemos que é um SELECT 
    em uma tabela específica.`,
  SEC_11_PT_6: `Agora precisamos pensar quais outras informações nós podemos incluir, 
    sem adicionar mais do que o necessário. Neste exemplo, adicionamos o WHERE, porque 
    cláusulas adicionais (assim como selecionar colunas específicas), irão utilizar um 
    plano diferente na execução da query, que podem radicalmente influenciar sua performance.`,
  SEC_11_PT_7: `Aqui há a decisão de não incluir o valor do WHERE, pois aumentaria bastante 
    a cardinalidade sem grandes benefícios, já que não há muita diferença entre  "x = 1" 
    versus "x = 2" com relação ao plano de execução e à performance. Por isso, podemos apenas 
    substituir o valor por "_". Essa é a última etapa para definir a chave para queries.`,
  SEC_12_PT_1: `Endpoints`,
  SEC_12_PT_2: `Uma aplicação pode ter vários endpoints, e quando cada chamada é executada 
    podemos coletar estatísticas sobre sucesso, localização, tempo de execução, etc. 
    Pensando no exemplo anterior, se apenas salvarmos todas as execuções com a chave "endpoint" 
    será muito difícil analisar posteriormente e fazer melhorias ou arrumar problemas.`,
  SEC_12_PT_3: `Podemos separar por rotas, por exemplo, "/login", "/checkout", 
    "/listar_produtos". `,
  SEC_12_PT_4: `Se existem parâmetros específicos para cada rota, estes valem a pena incluir 
    como parte da chave, por exemplo, ao listar produtos se são ordenados por preço, data 
    em que foram adicionados, tipo de produto, etc. Como estes valores são limitados 
    (comparados com o exemplo anterior em que existe um número grande de valores que podem 
    ser utilizados no WHERE), vale a pena incluir como parte da chave, porque podem 
    influenciar sua aplicação e dar prioridades para as opções mais escolhidas.`,
  SEC_13_PT_1: `Eventos utilizados em Observabilidade (e também Telemetria) são considerados 
    "largos" porque podem possuir centenas ou milhares de pares chave-valor.`,
  SEC_13_PT_2: `Quanto mais rico em informações, mais perguntas poderão ser respondidas. 
    Por exemplo: quais todas as chamadas que retornaram um erro 502 na última hora feitas 
    pelo usuário X?`,
  SEC_13_PT_3: `Pensando nos exemplos anteriores, as informações que poderiam ser 
    coletadas para cada query incluem: transação de que fez parte, aplicação, database 
    (schema), status (falha/sucesso), quantidade de execuções, tempo de execução, contenção, 
    uso de memória, uso de disco, rows lidos, rows escritos, plano usado para execução, etc.
    Para o endpoint, podemos colocar informações como header, token da sessão, ID do usuário, 
    ID do carrinho de compras, status (falha/sucesso), localização, etc.`,
  SEC_13_PT_4: `Se tivermos que resumir os dois termos explicados acima, podemos dizer que 
  Cardinalidade é referente a quantidade de eventos enviados e Dimensionalidade é referente 
  a dimensão (quantidade de informações) de cada evento.`,
  SEC_14_PT_1: `Preciso de Observabilidade?`,
  SEC_14_PT_2: `Observabilidade não é obrigatória para o bom funcionamento de todos os 
    sistemas. Se sua aplicação é simples, tem pouco uso/usuários, muitas vezes o monitoramento 
    será suficiente e não há nada de errado nisso.`,
  SEC_14_PT_3: `Para sistemas mais complexos, com variedade de uso, sua vida será muito mais 
    fácil se observabilidade fizer parte do seu sistema.`,
  SEC_14_PT_4: `Imagine que cada erro que ocorra na sua aplicação forme um novo quebra-cabeça. 
    O monitoramento irá te dar algumas peças, e se você já possui experiência na área, já viu 
    aquelas peças antes, elas serão suficientes pra você entender o que a imagem quer dizer, 
    e então corrigir o problema. Quando o erro é mais complexo, só algumas peças não serão 
    suficientes, e você precisa de várias peças (informações) para ter uma imagem mais completa 
    e entender todo o contexto, e assim poder resolver o problema.`,
  SEC_15_PT_1: `Exemplo`,
  SEC_15_PT_2: `Vamos terminar com um exemplo de como o mesmo problema seria resolvido com 
    Monitoramento versus Observabilidade.`,
  SEC_15_PT_3: `Neste cenário você fabrica legos e recebe um chamado de um usuário, dizendo 
    que seguiu todas as instruções dadas, mas que algo deu errado e o resultado final não 
    ficou igual ao descrito no guia.`,
  SEC_15_PT_4: `Para simplicidade vamos imaginar que todas as peças são numeradas e são 
    encaixadas em ordem, ou seja, 2 encaixa na 1, 3 encaixa na 2, e assim por diante.`,
  SEC_16_PT_1: ` com o ID fornecido pelo usuário você checa todo o trajeto daquela caixa 
    específica de legos. Com isso você consegue ver que as peças foram produzidas e passaram 
    pelo controle de qualidade na fábrica, em seguida a caixa foi enviada pelo correio e 
    então utilizada pelo usuário.`,
  SEC_16_PT_2: `Aqui podemos confirmar que não há um problema com o produto em si e que o 
    que era esperado chegou no usuário e não houve perda do produto no caminho.`,
  SEC_16_PT_3: ` analisando os logs de quando o usuário estava encaixando as peças, podemos 
    ver as peças que foram encaixadas: 1, 2, 3, 4, 6, 7..`,
  SEC_16_PT_4: `Cadê a peça 5? Aqui podemos ver que ela não foi encaixada!`,
  SEC_16_PT_5: ` sabemos que esta caixa possui 1000 peças, então vamos olhar para as métricas 
    coletadas e percebemos que o total de peças utilizadas foi 999 e olhando quantas sobraram 
    na caixa vemos 0.`,
  SEC_16_PT_6: `Aqui podemos confirmar que realmente há uma peça faltando na construção final, 
    mas que ela também não está na caixa.`,
  SEC_16_PT_7: `Com essa informação podemos dar uma resposta ao usuário, explicando que ele 
    precisa de uma peça número 5, e você pode inclusive detalhar a peça para que a pessoa possa 
    comprar uma nova avulsa.`,
  SEC_16_PT_8: `Com isso o problema do usuário foi resolvido, mas você não sabe exatamente o 
    que aconteceu, o que significa que você não será capaz de prevenir outra ocorrência. 
    O próximo passo será imaginar quais motivos podem ter levado a esse problema e adicionar 
    mais métricas, logs e/ou traces, assim quando acontecer novamente talvez você consiga 
    desvendar o mistério.`,
  SEC_17_PT_1: ` cada peça movimentada é um evento. Aqui vamos conseguir ver se todas as 
    peças foram utilizadas.`,
  SEC_17_PT_2: ` cada evento terá informações sobre o contexto do movimento, como horário, 
    número da peça em que a atual foi encaixada, coordenadas, e até mesmo coisas que você 
    não sabe se vai precisar, como cor da peça, nível de umidade da mesma, etc.`,
  SEC_17_PT_3: `Com as informações desses eventos vamos conseguir descobrir que… o cachorro comeu a peça!`,
};
