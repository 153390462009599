import { getText } from "../../texts/texts";
import { Header } from "../../components/header";
import Link from "@mui/material/Link";
import { getContentEmAndDev } from "./contentEmAndDev";
import { getSiteLanguage } from "../../utils";
import "./emAndDev.scss";
import { TableOfContents, headerItem } from "../../components/tableOfContents";

export function EMAndDev() {
  const headers: headerItem[] = [
    {label: getContentEmAndDev("CHANGE_TRACK"), id: `change-track`},
    {label: getContentEmAndDev("RESPONSIBILITIES"), id: `responsibilities`},
    {label: getContentEmAndDev("ORGANIZATION"), id: `organization`},
    {label: getContentEmAndDev("BEST"), id: `best-for-you`},
    {label: getContentEmAndDev("RECS"), id: `recommendations`},
];

  const tabsImg =
    getSiteLanguage() === "PT"
      ? "posts/emAndDev/tabs_pt.png"
      : "posts/emAndDev/tabs_en.png";

  const calendarImg =
    getSiteLanguage() === "PT"
      ? "posts/emAndDev/calendar_pt.png"
      : "posts/emAndDev/calendar_en.png";

  return (
    <>
      <Header text={getText("LOCAL_3_TITLE")} bck_img="title_bkg_02.png" />
      <section className="content-area">
        <TableOfContents headers={headers} postID="L-em-and-dev"/>

        <p className="text-area">{getContentEmAndDev("SEC_1_PT_1")}</p>
        <br />
        <p className="text-area">{getContentEmAndDev("SEC_1_PT_2")}</p>
        <br />
        <p className="text-area">{getContentEmAndDev("SEC_1_PT_3")}</p>
        <br />
        <p className="text-area">{getContentEmAndDev("SEC_1_PT_4")}</p>
        <br />
        <p className="text-area">{getContentEmAndDev("SEC_1_PT_5")}</p>

        <h2 id="change-track" className="sub-title">{getContentEmAndDev("CHANGE_TRACK")}</h2>
        <p className="text-area">{getContentEmAndDev("SEC_2_PT_1")}</p>
        <img src="posts/emAndDev/run.gif" className="image-big" alt="running" />
        <br />
        <p className="text-area">{getContentEmAndDev("SEC_2_PT_2")}</p>
        <br />
        <p className="text-area">
          {getContentEmAndDev("SEC_2_PT_3")}
          <b>{getContentEmAndDev("SEC_2_PT_4")}</b>
          {getContentEmAndDev("SEC_2_PT_5")}
        </p>
        <br />
        <p className="text-area">{getContentEmAndDev("SEC_2_PT_6")}</p>
        <br />
        <p className="text-area">{getContentEmAndDev("SEC_2_PT_7")}</p>
        <br />
        <p className="text-area">{getContentEmAndDev("SEC_2_PT_8")}</p>

        <h2 id="responsibilities" className="sub-title">{getContentEmAndDev("RESPONSIBILITIES")}</h2>
        <p className="text-area">{getContentEmAndDev("SEC_3_PT_1")}</p>
        <br />
        <p className="text-area">{getContentEmAndDev("SEC_3_PT_2")}</p>
        <br />
        <p className="text-area">{getContentEmAndDev("SEC_3_PT_3")}</p>
        <br />
        <p className="text-area">{getContentEmAndDev("SEC_3_PT_4")}</p>
        <br />
        <p className="text-area">
          <b>{getContentEmAndDev("SEC_3_PT_5")}</b>
          {getContentEmAndDev("SEC_3_PT_6")}
        </p>
        <br />
        <p className="text-area">
          <b>{getContentEmAndDev("SEC_3_PT_7")}</b>
          {getContentEmAndDev("SEC_3_PT_8")}
        </p>
        <br />
        <p className="text-area">
          <b>{getContentEmAndDev("SEC_3_PT_9")}</b>
          {getContentEmAndDev("SEC_3_PT_10")}
          <Link
            href="https://www.programaria.org/retencao-de-talentos-tech/"
            underline="always"
            target="_blank"
          >
            {getContentEmAndDev("SEC_3_PT_11")}
          </Link>
          {getContentEmAndDev("SEC_3_PT_12")}
        </p>
        <br />
        <p className="text-area">{getContentEmAndDev("SEC_3_PT_13")}</p>
        <br />
        <p className="text-area">{getContentEmAndDev("SEC_3_PT_14")}</p>

        <h2 id="organization" className="sub-title">{getContentEmAndDev("ORGANIZATION")}</h2>
        <p className="text-area">{getContentEmAndDev("SEC_4_PT_1")}</p>
        <br />
        <p className="text-area">{getContentEmAndDev("SEC_4_PT_2")}</p>
        <br />
        <p className="text-area">{getContentEmAndDev("SEC_4_PT_3")}</p>
        <img src={tabsImg} className="image-big" alt="tabs" />
        <br />
        <br />
        <p className="text-area">
          <b>{getContentEmAndDev("SEC_4_PT_4")}</b>
          {getContentEmAndDev("SEC_4_PT_5")}
        </p>
        <br />
        <p className="text-area">
          <b>{getContentEmAndDev("SEC_4_PT_6")}</b>
          {getContentEmAndDev("SEC_4_PT_7")}
          <Link href="?postID=L-obs-on-call" underline="always" target="_blank">
            {getContentEmAndDev("SEC_4_PT_8")}
          </Link>
          {getContentEmAndDev("SEC_4_PT_9")}
        </p>
        <br />
        <p className="text-area">
          <b>{getContentEmAndDev("SEC_4_PT_10")}</b>
          {getContentEmAndDev("SEC_4_PT_11")}
        </p>
        <br />
        <p className="text-area">
          <b>{getContentEmAndDev("SEC_4_PT_12")}</b>
          {getContentEmAndDev("SEC_4_PT_13")}
        </p>
        <br />
        <p className="text-area">
          <b>{getContentEmAndDev("SEC_4_PT_14")}</b>
          {getContentEmAndDev("SEC_4_PT_15")}
        </p>
        <br />
        <p className="text-area">
          <b>{getContentEmAndDev("SEC_4_PT_16")}</b>
          {getContentEmAndDev("SEC_4_PT_17")}
        </p>
        <br />
        <p className="text-area">{getContentEmAndDev("SEC_4_PT_18")}</p>

        <img src={calendarImg} className="image-big" alt="calendar" />
        <br />
        <p className="text-area">
          <p className="no-margin">
            <div className="colored-square red" />{" "}
            {getContentEmAndDev("CALENDAR_1")}
          </p>
          <br />
          <p className="no-margin">
            <div className="colored-square magenta" />{" "}
            {getContentEmAndDev("CALENDAR_2")}
          </p>
          <br />
          <p className="no-margin">
            <div className="colored-square blue" />{" "}
            {getContentEmAndDev("CALENDAR_3")}
          </p>
          <br />
          <p className="no-margin">
            <div className="colored-square green" />{" "}
            {getContentEmAndDev("CALENDAR_4")}
          </p>
          <br />
          <p className="no-margin">
            <div className="colored-square cyan" />{" "}
            {getContentEmAndDev("CALENDAR_5")}
          </p>
          <br />
          <p className="no-margin">
            <div className="colored-square white" />{" "}
            {getContentEmAndDev("CALENDAR_6")}
          </p>
          <br />
        </p>
        <br />

        <p className="text-area">{getContentEmAndDev("SEC_4_PT_19")}</p>
        <br />
        <p className="text-area">{getContentEmAndDev("SEC_4_PT_20")}</p>
        <br />
        <p className="text-area">{getContentEmAndDev("SEC_4_PT_21")}</p>
        <br />
        <p className="text-area">{getContentEmAndDev("SEC_4_PT_22")}</p>
        <br />
        <p className="text-area">{getContentEmAndDev("SEC_4_PT_23")}</p>
        <br />
        <p className="text-area">{getContentEmAndDev("SEC_4_PT_24")}</p>
        <br />

        <h2 id="best-for-you" className="sub-title">{getContentEmAndDev("BEST")}</h2>
        <p className="text-area">{getContentEmAndDev("SEC_5_PT_1")}</p>
        <br />
        <p className="text-area">{getContentEmAndDev("SEC_5_PT_2")}</p>
        <br />
        <p className="text-area">{getContentEmAndDev("SEC_5_PT_3")}</p>
        <br />
        <p className="text-area">{getContentEmAndDev("SEC_5_PT_4")}</p>
        <br />
        <p className="text-area">{getContentEmAndDev("SEC_5_PT_5")}</p>
        <br />

        <h2 id="recommendations" className="sub-title">{getContentEmAndDev("RECS")}</h2>
        <p className="text-area">
          <Link
            href="https://www.oreilly.com/library/view/the-managers-path/9781491973882/"
            target="_blank"
          >
            {getContentEmAndDev("SEC_6_PT_1")}
          </Link>
          <br />
          <br />
          <Link href="https://www.engmanagement.dev/" target="_blank">
            {getContentEmAndDev("SEC_6_PT_2")}
          </Link>
          <br />
          <br />
          <Link
            href="https://charity.wtf/2019/01/04/engineering-management-the-pendulum-or-the-ladder/"
            target="_blank"
          >
            Engineering Management: The pendulum or the ladder
          </Link>
          <br />
          <br />
          <Link
            href="https://charity.wtf/2017/05/11/the-engineer-manager-pendulum/"
            target="_blank"
          >
            The Engineer/Manager Pendulum
          </Link>
          <br />
          <br />
          <Link
            href="https://jvns.ca/blog/things-your-manager-might-not-know/"
            target="_blank"
          >
            Things your manager might not know
          </Link>
          <br />
          <br />
          <Link
            href="https://jvns.ca/blog/2018/09/22/new-zine--help--i-have-a-manager/"
            target="_blank"
          >
            Help! I have a manager
          </Link>
        </p>
      </section>
    </>
  );
}
