import { createTheme } from "@mui/material/styles";

const colorPurpleDark: string = "#4519d2";
const colorPurpleLight: string = "#9076e6";

export const theme = createTheme({
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          position: "fixed",
          zIndex: "10",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: colorPurpleDark,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "transparent",
            color: colorPurpleLight,
          },
          "&.Mui-selected": {
            color: colorPurpleDark,
          },
        },
      },
    },
  },
});
