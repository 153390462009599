import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import "./search.scss";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { getText } from "../texts/texts";
import {
  LanguageKeys,
  trackFilterLanguage,
  trackFilterType,
  updateSearchParams,
} from "../utils";
import { Badge, Box, Button, InputLabel, Modal } from "@mui/material";
import { ExternalType } from "./post";

export interface FilterOptions {
  language: LanguageKeys;
  type: ExternalType;
}

interface SearchProps {
  filterBySearch: (search: string) => void;
  applyFilter: (filterValues: FilterOptions) => void;
  searchValue: string;
}

function getFilterFromSearchParams(): FilterOptions {
  const params = new URLSearchParams(window.location.search);
  let filter: FilterOptions = {
    language: params.get("filterLanguage") || "ALL",
    type: params.get("filterType") || "ALL",
  };

  return filter;
}

function activeFiltersCount(filter: FilterOptions): number {
  let count = 0;
  if (filter.language !== "ALL") {
    count++;
  }
  if (filter.type !== "ALL") {
    count++;
  }
  return count;
}

export function Search(props: SearchProps) {
  const [searchValue, setSearchValue] = React.useState(props.searchValue);
  const [filter, setFilter] = React.useState(getFilterFromSearchParams());
  const [activeFilters, setActiveFilters] = React.useState(
    activeFiltersCount(filter)
  );
  const [open, setOpen] = React.useState(false);

  const handleEnter = (e: { key: string }) => {
    if (e.key === "Enter") {
      props.filterBySearch(searchValue);
    }
  };
  const searchClick = () => {
    props.filterBySearch(searchValue);
  };

  const closeClick = () => {
    setSearchValue("");
    props.filterBySearch("");
  };

  const setLanguage = (event: SelectChangeEvent) => {
    setFilter({
      language: event.target.value as LanguageKeys,
      type: filter.type,
    });
  };

  const setType = (event: SelectChangeEvent) => {
    setFilter({
      language: filter.language,
      type: event.target.value as ExternalType,
    });
  };

  const applyFilters = () => {
    setActiveFilters(activeFiltersCount(filter));
    updateSearchParams([
      { key: "filterLanguage", value: filter.language },
      { key: "filterType", value: filter.type },
    ]);
    trackFilterLanguage(filter.language);
    trackFilterType(filter.type);
    props.applyFilter(filter);
    setOpen(false);
  };

  const resetFilters = () => {
    const f = {
      language: "ALL",
      type: "ALL",
    };
    setFilter(f);
    setActiveFilters(activeFiltersCount(f));
    updateSearchParams([
      { key: "filterLanguage", value: "ALL" },
      { key: "filterType", value: "ALL" },
    ]);
    trackFilterLanguage(filter.language);
    trackFilterType(filter.type);
    props.applyFilter(f);
    setOpen(false);
  };

  const handleClose = () => {
    setFilter(getFilterFromSearchParams());
    setOpen(false);
  };

  return (
    <div className="filter-area">
      <div className="search-input-area">
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          value={searchValue}
          placeholder={getText("SEARCH_DEFAULT")}
          inputProps={{ "aria-label": "search posts" }}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          onKeyDown={handleEnter}
          className="search-input"
        />
        {searchValue.length > 0 && (
          <IconButton
            type="button"
            sx={{ p: "10px" }}
            aria-label="close"
            onClick={closeClick}
            className="icon-right"
          >
            <CloseIcon />
          </IconButton>
        )}
        <IconButton
          type="button"
          sx={{ p: "10px" }}
          aria-label="search"
          onClick={searchClick}
          className="icon-right"
        >
          <SearchIcon />
        </IconButton>
      </div>

      <Badge
        badgeContent={activeFilters}
        color="secondary"
        sx={{ marginRight: "35px", marginBottom: "-5px" }}
      >
        <IconButton
          type="button"
          sx={{ marginRight: "-10px" }}
          aria-label="filter"
          onClick={() => setOpen(true)}
          className="filter-icon"
        >
          <FilterAltIcon fontSize="large" />
        </IconButton>
      </Badge>
      <Modal open={open} onClose={handleClose}>
        <Box className="filter-modal">
          <InputLabel id="language-select-label" className="select-label">
            {getText("SEARCH_LANGUAGE")}
          </InputLabel>
          <Select
            labelId="language-select-label"
            value={filter.language}
            onChange={setLanguage}
            className="language-filter-area"
          >
            <MenuItem value="ALL" className="select-option">
              <em>{getText("SEARCH_LANGUAGE_ALL")}</em>
            </MenuItem>
            <MenuItem value={"EN"} className="select-option">
              {getText("SEARCH_LANGUAGE_EN")}
            </MenuItem>
            <MenuItem value={"PT"} className="select-option">
              {getText("SEARCH_LANGUAGE_PT")}
            </MenuItem>
          </Select>

          <InputLabel id="type-select-label" className="select-label">
            {getText("SEARCH_TYPE")}
          </InputLabel>
          <Select
            labelId="type-select-label"
            value={filter.type}
            onChange={setType}
            className="language-filter-area"
          >
            <MenuItem value="ALL" className="select-option">
              <em>{getText("SEARCH_TYPE_ALL")}</em>
            </MenuItem>
            <MenuItem value="POST" className="select-option">
              {getText("SEARCH_TYPE_POST")}
            </MenuItem>
            <MenuItem value={"VIDEO"} className="select-option">
              {getText("SEARCH_TYPE_VIDEO")}
            </MenuItem>
            <MenuItem value={"PODCAST"} className="select-option">
              {getText("SEARCH_TYPE_PODCAST")}
            </MenuItem>
          </Select>

          <div className="buttons-area">
            <Button
              variant="outlined"
              onClick={resetFilters}
              className="button-secondary"
            >
              {getText("SEARCH_RESET")}
            </Button>
            <Button
              variant="contained"
              onClick={applyFilters}
              className="button-primary"
            >
              {getText("SEARCH_APPLY")}
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
