export const textEnglish = {
  // Tabs
  BLOG: "BLOG",
  ABOUT: "ABOUT ME",

  // Utils
  TABLE_CONTENTS: `Table Of Contents`,

  // About page
  DESCRIPTION_1: `Hi, I'm Marylia Gutierrez, Fullstack Software Engineer, born in São Paulo (Brazil), living in Toronto (Canada).`,
  DESCRIPTION_2: `I enjoy learning and sharing knowledge with others, presenting at conferences or 
    talking on Podcasts, so I created this website to share content I did in other places, and also to post some exclusive content on 
    topics I might find interesting!`,
  DESCRIPTION_3: `Hope you enjoy it!`,
  CONFERENCES: `CONFERENCES`,
  CONFERENCE_1: `DevOps Days Recife (December 2022): Observability is more than 3 pillars`,
  CONFERENCE_2: `DevOps Days Fortaleza (September 2023): Bringing observability to your on-call`,
  CONFERENCE_3: `GopherCon Brazil (September 2023): Data Storage Structure in a Distributed Database`,
  CONFERENCE_4: `Brazilian Symposium on Databases (September 2023): The world of distributed Databases`,
  GRAFANA_1: `Contribute to OpenTelemetry components across a wide range of programming languages.`,
  GRAFANA_2: `Work on OpenTelemetry-based solutions, writing documentation that makes it easy for Grafana Cloud users to 
  instrument their applications with OpenTelemetry and get started with Grafana Cloud.`,
  CRDB_1: `Full-stack development of Observability features for CockroachDB and CockroachDB Cloud, collecting information 
    about Workloads, Cluster Health and Insights and making them available in different forms (e.g. UI Console, SQL Tables, 
    Telemetry).`,
  CRDB_2: `Management of the Cluster Observability team for CockroachDB. 
  Being responsible for technical and people's development.`,
  CRDB_3: `Engineer Manager Sponsor for the DevRel team; Participated on Sales calls to help customers with Technical 
    questions; Interviewed candidates; Mentored people outside of my main team.`,
  IBM_1: `Development and management of internal tool for Observability of Db2 on 
  Cloud and Db2 Warehouse on Cloud products.`,
  IBM_2: `Development of internal tool with metrics of IBM on Cloud products.`,
  IBM_3: `Development of Db2 on Cloud and Db2 Warehouse on Cloud.`,
  IBM_4: `Mentoring in hackathons (including at the World Humanitarian Summit organized by the United Nations) 
  and mentoring for new hires and interns.`,
  IBM_5: `Development of iOS IBM Catalog app and Security responder.`,
  TAQTILE_1: `Development and management of several Android and iOS projects, for example, projects for
  Stanford University, Nestle, Multiplus, Netshoes, Hy-Vee, among others.`,
  TAQTILE_2: `Acting as designer for a few projects and providing mentorship and workshops.`,
  MASTERS_TITLE: `MASTER'S`,
  MASTERS: `Master's - Computer Engineering.`,
  INTERNSHIP_TITLE: `INTERNSHIPS`,
  INTERNSHIP_1_1: `Company focused on Web and Mobile applications projects.`,
  INTERNSHIP_1_2: `Responsibilities: Planning, analysis and development. Scrum master in projects.`,
  INTERNSHIP_2_1: `A provider of technology solutions with emphasis in the health area.`,
  INTERNSHIP_2_2: `Responsibilities: Technology training for large systems, modeling, 
  software development and testing.`,
  INTERNSHIP_3_1: `Company that develops systems for electronic funds transfer and its derivatives.`,
  INTERNSHIP_3_2: `Responsibilities: Planning, analysis, scrum master and development of a 
  gateway system for online payments.`,
  GRADUATION_TITLE: `BACHELOR'S`,
  GRADUATION: `Bachelor's of Science - Computer Engineering.`,

  // Search
  SEARCH_APPLY: `APPLY`,
  SEARCH_RESET: `RESET`,
  SEARCH_DEFAULT: `Search Posts`,
  SEARCH_LANGUAGE: `Language`,
  SEARCH_LANGUAGE_ALL: `All Languages`,
  SEARCH_LANGUAGE_PT: `Portuguese`,
  SEARCH_LANGUAGE_EN: `English`,
  SEARCH_NO_RESULTS: `No Results`,
  SEARCH_TYPE: `Type`,
  SEARCH_TYPE_ALL: `All Types`,
  SEARCH_TYPE_POST: `Text`,
  SEARCH_TYPE_VIDEO: `Video`,
  SEARCH_TYPE_PODCAST: `Podcast`,

  // Posts
  // External
  EXTERNAL_1_TITLE: `SQL query tuning with DB Console`,
  EXTERNAL_1_DESCRIPTION: `How to use the DB Console GUI to identify SQL statements experiencing 
  performance issues — plus resources for resolving any issues you find`,
  // --
  EXTERNAL_2_TITLE: `Good practices for retention of Tech Talents`,
  EXTERNAL_2_DESCRIPTION: `To retain talents, is necessary constants attitudes of team appreciation, 
  constructive feedback and performance recognition. Learn more how to maintain a good relationship 
  with your team and retain tech talents.`,
  // --
  EXTERNAL_3_TITLE: `How to use indexes for better workload performance`,
  EXTERNAL_3_DESCRIPTION: `Indexes greatly improve the performance of read queries that use 
  filters, but they come with a cost to write performance. Learn how to optimize indexes for the performance you need.`,
  // --
  EXTERNAL_4_TITLE: `Observability, Databases, and Management… OH MY!`,
  EXTERNAL_4_DESCRIPTION: `How do you find the time and energy to be an engineering manager while 
  still writing code? Marylia Gutierrez sits down with Adriana and Ana for another all-Latinas-in-tech episode 
  to share her hot tips on doing just that!`,
  // --
  EXTERNAL_5_TITLE: `How to troubleshoot and optimize query performance in CockroachDB`,
  EXTERNAL_5_DESCRIPTION: `Intelligent Insights is a tool that exposes problems detected in workloads 
  and schemas, then offers recommendations to improve the performance of the workload.`,
  // --
  EXTERNAL_6_TITLE: `The world of distributed Databases`,
  EXTERNAL_6_DESCRIPTION: `Distributed databases were created out of necessity, but how does it really work and 
  what we can do with it? In this talk I show how it is necessary, some of its basic concepts and its advantages`,
  EXTERNAL_6_SOURCE: `BRAZILIAN SYMPOSIUM ON DATABASES`,
  // --
  EXTERNAL_7_TITLE: `Data Storage Structure in a Distributed Database`,
  EXTERNAL_7_DESCRIPTION: `In this talk I explain how data storage works in CockroachDB, how the data is redistributed
  into ranges in a way that helps searches and how these ranges are replicated.`,
  EXTERNAL_7_SOURCE: `GOPHERCON BRAZIL 2023`,
  // --
  EXTERNAL_8_TITLE: `Live: Marylia Gutierrez`,
  EXTERNAL_8_DESCRIPTION: `Talking about why observability is important on a database, what are the most interesting
  aspects of it, and the lessons learned in the process.`,
  // --
  EXTERNAL_9_TITLE: `The One Where We Geek Out on Contributing to OpenTelemetry`,
  EXTERNAL_9_DESCRIPTION: `Talking about what it's like to be an OpenTelemetry contributor, how staying technical as 
  a manager in my last last role made the transition to IC in my current role go smoothly and also some tips for 
  people looking to contribute to OTel for the first time.`,
  // --
  EXTERNAL_10_TITLE: `Talking about OpenTelemetry`,
  EXTERNAL_10_DESCRIPTION: `On this podcast episode, we talk about what is OpenTelemetry, how to use it, its components,
  and how to contribute.`,
  // --
  EXTERNAL_11_TITLE: `How to use OpenTelemetry resource attributes and Grafana Cloud Application Observability to
  accelerate root cause analysis`,
  EXTERNAL_11_DESCRIPTION: `In this step-by-step post, learn how to collect OpenTelemetry resource attributes and
  analyze them in Grafana Cloud Application Observability to quickly investigate app performance issues.`,
  // --

  // Local
  LOCAL_1_TITLE: `Observability is more than 3 pillars`,
  LOCAL_1_DESCRIPTION: `Observability is a lot of times defined as 3 pillars (metrics, logs and traces) and the terms
  "monitoring" and "observability" are used interchangeably erroneously. Observability has been evolving, and your
  definition must be updated with it.`,
  // --
  LOCAL_2_TITLE: `Bringing Observability to your On-Call`,
  LOCAL_2_DESCRIPTION: `The partnership between Observabilidade and On-Call can bring improvements to the 
  identification of problems, and consequently a faster resolution of them. We will discuss how this 
  collaboration can be done, without being laborious for the parties involved.
  `,
  // --
  LOCAL_3_TITLE: `Management and Software Development`,
  LOCAL_3_DESCRIPTION: `In this post I share my experience entering the management path and how I organized 
  myself to be an efficient manager who still writes a lot of code.`,
  // --
  LOCAL_4_TITLE: `Intro to OpenTelemetry`,
  LOCAL_4_DESCRIPTION: `Post with an introduction to OpenTelemetry, explaining where it came from, what is used for,
  its challenges and components.`,
  // --
  LOCAL_5_TITLE: `OpenTelemetry - SDKs and APIs`,
  LOCAL_5_DESCRIPTION: `Post explaining and putting into practice OpenTelemetry SKDs and APIs.`,
};

export type TextsEnglishKeys = keyof typeof textEnglish;
