import { LanguageKeys, LanguageOptions, getSiteLanguage } from "../utils";
import { TextsEnglishKeys, textEnglish } from "./english";
import { TextsPortugueseKeys, textPortuguese } from "./portuguese";

const isValidLanguage = (language: any): language is LanguageKeys =>
  LanguageOptions.includes(language);

export function getValidLanguage(language: string | null): LanguageKeys {
  if (language === null || !isValidLanguage(language)) {
    return "ALL";
  }
  return language;
}

export function getText(key: TextsPortugueseKeys | TextsEnglishKeys): string {
  if (getSiteLanguage() === "PT") {
    return textPortuguese[key];
  }
  return textEnglish[key];
}
