import "./header.scss";

interface headerParallaxProps {
  text: string;
  bck_img: string;
}

export function Header(props: headerParallaxProps) {
  return (
    <div className="title-area">
      <img
        src={`header/${props.bck_img}`}
        className="title-img-area"
        alt={`abstract background`}
      />
      <div className="name" id="name">
        <span>{props.text}</span>
      </div>
    </div>
  );
}
