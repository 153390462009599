import "./contact.scss";
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import Link from "@mui/material/Link";
import { trackContactSelected } from "../utils";

export function Contact() {
  return (
    <div className="section-contact">
      <div className="social-area">
        <Link
          href="https://bsky.app/profile/marylia.dev"
          target="_blank"
          onClick={() => trackContactSelected("bluesky")}
          marginTop={"-13px"}
          width={"50px"}
        >
          <svg
            transform="scale(.5)"
            width={"65px"}
            height={"60px"}
          >
            <path fill="#fff" d="M13.873 3.805C21.21 9.332 29.103 20.537 32 26.55v15.882c0-.338-.13.044-.41.867-1.512 4.456-7.418 21.847-20.923 7.944-7.111-7.32-3.819-14.64 9.125-16.85-7.405 1.264-15.73-.825-18.014-9.015C1.12 23.022 0 8.51 0 6.55 0-3.268 8.579-.182 13.873 3.805ZM50.127 3.805C42.79 9.332 34.897 20.537 32 26.55v15.882c0-.338.13.044.41.867 1.512 4.456 7.418 21.847 20.923 7.944 7.111-7.32 3.819-14.64-9.125-16.85 7.405 1.264 15.73-.825 18.014-9.015C62.88 23.022 64 8.51 64 6.55c0-9.818-8.578-6.732-13.873-2.745Z"/>
          </svg>
        </Link>
        <Link
          href="https://www.linkedin.com/in/maryliag"
          target="_blank"
          onClick={() => trackContactSelected("linkedin")}
        >
          <LinkedInIcon sx={{ fontSize: 30, color: "white" }} />
        </Link>
        <Link
          href="https://github.com/maryliag"
          target="_blank"
          onClick={() => trackContactSelected("github")}
        >
          <GitHubIcon sx={{ fontSize: 30, color: "white" }} />
        </Link>
        <Link
          href="https://twitter.com/maryliag_"
          target="_blank"
          onClick={() => trackContactSelected("twitter")}
        >
          <XIcon sx={{ fontSize: 30, color: "white" }} />
        </Link>
      </div>
    </div>
  );
}
