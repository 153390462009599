import { getText } from "../../texts/texts";
import "../posts.scss";
import "./obs3Pillars.scss";
import { getContentObs3Pillars } from "./contentObs3Pillars";
import { Header } from "../../components/header";
import { TableOfContents, headerItem } from "../../components/tableOfContents";

export function Obs3Pillars() {
  const headers: headerItem[] = [
    {label: getContentObs3Pillars("OBS"), id: 'observability'},
    {label: getContentObs3Pillars("METRICS"), id: 'metrics'},
    {label: getContentObs3Pillars("LOGS"), id: 'logs'},
    {label: getContentObs3Pillars("TRACE"), id: 'traces'},
    {label: getContentObs3Pillars("OBS_MON"), id: 'monitoring-vs-observability'},
    {label: getContentObs3Pillars("CARDINALITY"), id: 'cardinality', 
      subItems: [
        {label: getContentObs3Pillars("SEC_11_PT_1"), id: 'query-on-db'},
        {label: getContentObs3Pillars("SEC_12_PT_1"), id: 'endpoints'},
      ],
    },
    {label: getContentObs3Pillars("DIMENSIONALITY"), id: 'dimensionality'},
    {label: getContentObs3Pillars("SEC_14_PT_1"), id: 'need-observability'},
    {label: getContentObs3Pillars("SEC_15_PT_1"), id: 'example',
      subItems: [
        {label: getContentObs3Pillars("MONITORING"), id: 'example-monitoring'},
        {label: getContentObs3Pillars("OBS"), id: 'example-observability'},
      ],
    },
    {label: getContentObs3Pillars("REFERENCE"), id: 'references'},
  ];
  return (
    <div>
      <Header text={getText("LOCAL_1_TITLE")} bck_img="title_bkg_07.png" />
      <section className="content-area">
        <TableOfContents headers={headers} postID="L-obs-3-pillars"/>
        <div className="text-area">
          {getContentObs3Pillars("SEC_1_PT_1")}
          <br />
          <br />
          {getContentObs3Pillars("SEC_1_PT_2")}
        </div>

        <h2 id="observability" className="sub-title">{getContentObs3Pillars("OBS")}</h2>
        <div className="text-area">
          {getContentObs3Pillars("SEC_2_PT_1")}
          <br />
          <br />
          {getContentObs3Pillars("SEC_2_PT_2")}
          <br />
          <br />
          {getContentObs3Pillars("SEC_2_PT_3")}
        </div>

        <h2 id="metrics" className="sub-title">{getContentObs3Pillars("METRICS")}</h2>
        <div className="text-area">
          <img
            src="posts/obs3Pillars/metrics.png"
            className="image-area"
            width={100}
            height={100}
            alt="metrics"
          />
          {getContentObs3Pillars("SEC_3_PT_1")}
          <br />
          <br />
          {getContentObs3Pillars("SEC_3_PT_2")}
          <br />
          <br />
          {getContentObs3Pillars("SEC_3_PT_3")}
        </div>
        <br />

        <h2 id="logs" className="sub-title">{getContentObs3Pillars("LOGS")}</h2>
        <div className="text-area">
          <img
            src="posts/obs3Pillars/logs.png"
            className="image-area"
            width={100}
            height={100}
            alt="logs"
          />
          {getContentObs3Pillars("SEC_4_PT_1")}
          <br />
          <br />
          {getContentObs3Pillars("SEC_4_PT_2")}
        </div>
        <br />

        <h2 id="traces" className="sub-title">{getContentObs3Pillars("TRACE")}</h2>
        <div className="text-area">
          <img
            src="posts/obs3Pillars/trace.png"
            className="image-area"
            width={100}
            height={100}
            alt="traces"
          />
          {getContentObs3Pillars("SEC_5_PT_1")}
        </div>
        <br />
        <br />
        <br />
        <br />
        <div className="text-area">
          {getContentObs3Pillars("SEC_5_PT_2")}
          <br />
          <br />
          {getContentObs3Pillars("SEC_5_PT_3")}
        </div>
        <br />
        <br />

        <h2 id="monitoring-vs-observability" className="sub-title">{getContentObs3Pillars("OBS_MON")}</h2>
        <br />
        <br />
        <div className="two-columns">
          <div className="column column-left">
            <div className="glass-area">
              <span className="title">
                {getContentObs3Pillars("MONITORING")}
              </span>
              <br />
              <div className="line">{getContentObs3Pillars("SEC_6_MON_1")}</div>
              <div className="line">{getContentObs3Pillars("SEC_6_MON_2")}</div>
              <div className="line">{getContentObs3Pillars("SEC_6_MON_3")}</div>
            </div>
          </div>
          <div className="column column-right">
            <div className="glass-area">
              <span className="title">{getContentObs3Pillars("OBS")}</span>
              <br />
              <div className="line">{getContentObs3Pillars("SEC_6_OBS_1")}</div>
              <div className="line">{getContentObs3Pillars("SEC_6_OBS_2")}</div>
              <div className="line">{getContentObs3Pillars("SEC_6_OBS_3")}</div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <div className="text-area">
          {getContentObs3Pillars("SEC_7_PT_1")}
          <br />
          <br />
          {getContentObs3Pillars("SEC_7_PT_2")}
          <br />
          <br />
          {getContentObs3Pillars("SEC_7_PT_3")}
        </div>
        <br />
        <br />
        <br />

        <div className="two-columns">
          <div className="column column-left">
            <div className="glass-area">
              <span className="title">
                {getContentObs3Pillars("MONITORING")}
              </span>
              <br />
              <div className="line">{getContentObs3Pillars("SEC_8_MON_1")}</div>
            </div>
          </div>
          <div className="column column-right">
            <div className="glass-area">
              <span className="title">{getContentObs3Pillars("OBS")}</span>
              <br />
              <div className="line">{getContentObs3Pillars("SEC_8_OBS_1")}</div>
            </div>
          </div>
        </div>

        <br />
        <br />
        <br />
        <div className="text-area">
          {getContentObs3Pillars("SEC_9_PT_1")}
          <br />
          {getContentObs3Pillars("SEC_9_PT_2")}
        </div>
        <br />

        <h2 id="cardinality" className="sub-title">{getContentObs3Pillars("CARDINALITY")}</h2>
        <div className="text-area">
          <img
            src="posts/obs3Pillars/cardinality.png"
            className="image-area"
            width={150}
            height={150}
            alt="traces"
          />
          {getContentObs3Pillars("SEC_10_PT_1")}
          <br />
          <br />
          {getContentObs3Pillars("SEC_10_PT_2")}
          <br />
          <br />
          {getContentObs3Pillars("SEC_10_PT_3")}
          <br />
        </div>
        <div className="clear" />

        <h2 id="query-on-db" className="sub-title small">
          <i>{getContentObs3Pillars("SEC_11_PT_1")}</i>
        </h2>
        <div className="text-area">
          <img
            src="posts/obs3Pillars/card-1.png"
            className="image-area image-large"
            width={150}
            alt="traces"
          />
          <br />
          {getContentObs3Pillars("SEC_11_PT_2")}
        </div>
        <br />
        <div className="clear" />
        <br />
        <br />

        <div className="text-area">
          <img
            src="posts/obs3Pillars/card-2.png"
            className="image-area image-large"
            width={150}
            alt="traces"
          />
          <br />
          {getContentObs3Pillars("SEC_11_PT_3")}
        </div>
        <br />
        <div className="clear" />
        <br />
        <br />

        <div className="text-area">
          <img
            src="posts/obs3Pillars/card-3.png"
            className="image-area image-large"
            width={150}
            alt="traces"
          />
          <br />
          {getContentObs3Pillars("SEC_11_PT_4")}
        </div>
        <br />
        <div className="clear" />
        <br />
        <br />

        <div className="text-area">
          <img
            src="posts/obs3Pillars/card-4.png"
            className="image-area image-large"
            width={150}
            alt="traces"
          />
          <br />
          {getContentObs3Pillars("SEC_11_PT_5")}
        </div>
        <br />
        <div className="clear" />
        <br />
        <br />

        <div className="text-area">
          <img
            src="posts/obs3Pillars/card-5.png"
            className="image-area image-large"
            width={300}
            alt="traces"
          />
          <br />
          {getContentObs3Pillars("SEC_11_PT_6")}
          <br />
          <br />
          {getContentObs3Pillars("SEC_11_PT_7")}
        </div>
        <br />

        <h2 id="endpoints" className="sub-title small">
          <i>{getContentObs3Pillars("SEC_12_PT_1")}</i>
        </h2>
        <div className="text-area">
          {getContentObs3Pillars("SEC_12_PT_2")}
          <br />
          <br />
          {getContentObs3Pillars("SEC_12_PT_3")}
          <br />
          <br />
          {getContentObs3Pillars("SEC_12_PT_4")}
        </div>
        <div className="clear" />
        <br />
        <br />

        <h2 id="dimensionality" className="sub-title">
          {getContentObs3Pillars("DIMENSIONALITY")}
        </h2>
        <div className="text-area">
          <img
            src="posts/obs3Pillars/dimensionality.png"
            className="image-area"
            width={150}
            height={150}
            alt="traces"
          />
          {getContentObs3Pillars("SEC_13_PT_1")}
          <br />
          <br />
          {getContentObs3Pillars("SEC_13_PT_2")}
          <br />
          <br />
          {getContentObs3Pillars("SEC_13_PT_3")}
          <br />
          <br />
          {getContentObs3Pillars("SEC_13_PT_4")}
        </div>
        <br />
        <div className="clear" />

        <h2 id="need-observability" className="sub-title">{getContentObs3Pillars("SEC_14_PT_1")}</h2>
        <br />
        <div className="text-area">
          <img
            src="posts/obs3Pillars/pieces.png"
            className="image-area"
            width={150}
            height={150}
            alt="traces"
          />
          {getContentObs3Pillars("SEC_14_PT_2")}
          <br />
          <br />
          {getContentObs3Pillars("SEC_14_PT_3")}
          <br />
          <br />
          {getContentObs3Pillars("SEC_14_PT_4")}
        </div>

        <br />
        <div className="clear" />
        <h2 id="example" className="sub-title">{getContentObs3Pillars("SEC_15_PT_1")}</h2>
        <div className="text-area">
          {getContentObs3Pillars("SEC_15_PT_2")}
          <br />
          <br />
          {getContentObs3Pillars("SEC_15_PT_3")}
          <br />
          <br />
          {getContentObs3Pillars("SEC_15_PT_4")}
        </div>
        <br />
        <div className="clear" />

        <h2 id="example-monitoring" className="sub-title small">
          <i>{getContentObs3Pillars("MONITORING")}</i>
        </h2>
        <div className="text-area">
          <u>{`${getContentObs3Pillars("TRACE")}:`}</u>
          {getContentObs3Pillars("SEC_16_PT_1")}
          <br />
          {getContentObs3Pillars("SEC_16_PT_2")}
          <br />
          <br />
          <u>{`${getContentObs3Pillars("LOGS")}:`}</u>
          {getContentObs3Pillars("SEC_16_PT_3")}
          <br />
          {getContentObs3Pillars("SEC_16_PT_4")}
          <br />
          <br />
          <u>{`${getContentObs3Pillars("METRICS")}:`}</u>
          {getContentObs3Pillars("SEC_16_PT_5")}
          <br />
          {getContentObs3Pillars("SEC_16_PT_6")}
          <br />
          <br />
          <br />
          {getContentObs3Pillars("SEC_16_PT_7")}
          <br />
          {getContentObs3Pillars("SEC_16_PT_8")}
        </div>
        <br />
        <div className="clear" />

        <h2 id="example-observability" className="sub-title small">
          <i>{getContentObs3Pillars("OBS")}</i>
        </h2>
        <div className="text-area">
          <u>{`${getContentObs3Pillars("CARDINALITY")}:`}</u>
          {getContentObs3Pillars("SEC_17_PT_1")}
          <br />
          <br />
          <u>{`${getContentObs3Pillars("DIMENSIONALITY")}:`}</u>
          {getContentObs3Pillars("SEC_17_PT_2")}
          <br />
          <br />
          <br />
          {getContentObs3Pillars("SEC_17_PT_3")}
        </div>
        <br />
        <img
          src="posts/obs3Pillars/dog.png"
          className="image-middle"
          width={200}
          height={200}
          alt="metrics"
        />
        <br />
        <br />
        <br />
        <br />
        <h2 id="references" className="sub-title">{getContentObs3Pillars("REFERENCE")}</h2>
        <div className="text-area">
          {`Observability Engineering: Achieving Production Excellence`}
          <br />
          <i>{`Charity Majors, Liz Fong-Jones, George Miranda`}</i>
        </div>
      </section>
    </div>
  );
}
