import React, { useEffect, useState } from "react";
import "./aboutMe.scss";
import {
  ProfessionalExperience,
  ProfessionalExperienceLight,
} from "../components/experiences";
import { Contact } from "../components/contact";
import { getText } from "../texts/texts";
import { isSmallScreen } from "../utils";

export function AboutMe() {
  const [smallScreen, setSmallScreen] = useState(isSmallScreen());

  useEffect(() => {
    const onResize = () => {
      setSmallScreen(isSmallScreen());
    };

    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [setSmallScreen]);

  return (
    <div className="base">
      <div className="about-me-area">
        <img
          src={"banner.png"}
          className="banner-area"
          alt={`banner marylia`}
        />
        <div className="description-area">
          {getText("DESCRIPTION_1")}
          <br />
          {getText("DESCRIPTION_2")}
          <br />
          {getText("DESCRIPTION_3")}
        </div>
        {smallScreen && <ProfessionalExperienceLight />}
        {!smallScreen && <ProfessionalExperience />}
      </div>

      <Contact />
    </div>
  );
}
