import { getSiteLanguage } from "../../utils";

export function getContentOtelSdkApi(
  key: ContentPortugueseKeys | ContentEnglishKeys
): string {
  if (getSiteLanguage() === "PT") {
    return contentPortuguese[key];
  }
  return contentEnglish[key];
}

type ContentPortugueseKeys = keyof typeof contentPortuguese;
type ContentEnglishKeys = keyof typeof contentEnglish;

const contentEnglish = {
    SEC_2_TITLE: `Data flow`,
    SEC_3_TITLE: `API`,
    SEC_4_TITLE: `SDK`,
    SEC_5_TITLE: `Setup`,
    SEC_6_TITLE: `Application`,
    SEC_7_TITLE: `Visualization`,
    SEC_8_TITLE: `Instrumentation`,
    SEC_9_TITLE: `Auto instrumentation`,
    SEC_10_TITLE: `Instrumentation libraries`,
    SEC_11_TITLE: `Manual`,
    SEC_12_TITLE: `Summary`,
    SEC_13_TITLE: `References`,
    SEC_1_PT_1: `In a previous post, I made an `,
    SEC_1_PT_2: `introduction to OpenTelemetry`,
    SEC_1_PT_3: `, where I listed its components. In this post I will talk about two of them: APIs (Application 
        Programming Interface) and SDKs (Software Development Kit).`,
    SEC_1_PT_4: `Before talking about these components, we need to understand the context in which they are used, 
        and to do this we will look at the telemetry data.`,
    SEC_2_PT_1: `Imagine that you have an application that is running on a distributed system with several services. 
        You want to collect information about it to know its health. At this point, you can write customized scripts within 
        your own application, collecting this telemetry data and sending it to a database that will allow you to view and 
        analyze it later.`,
    SEC_2_PT_2: `The challenge with this approach is that it is laborious and complex, you will have to decide what is most 
        important, the format in which the data should be collected and sent, and finally carry out the entire implementation.`,
    SEC_2_PT_3: `Now imagine that there was already something that collected this telemetry data for you and sent it somewhere.`,
    SEC_2_PT_4: `That is where OpenTelemetry can help you, as there are already many established and developed packages, 
        plugins, etc that can do this for you.`,
    SEC_2_PT_5: `Note: Another component that could be added to this flow would be the OpenTelemetry Collector, but this is 
        a complex component that will receive its own post in the future.`,
    SEC_2_PT_6: `Let's now take a look at what is already defined by OpenTelemetry.`,
    SEC_3_PT_1: `An Application Programming Interface (API) is the communication bridge between two parts of your system. 
        As the name says, it is an interface, which means that it is not the API's concern how it will be implemented, it just 
        defines what information is necessary for the feature or process to be executed and what the format of the returned 
        result is (if something is in fact returned). It can be used to access functionality or data.`,
    SEC_3_PT_2: `Imagine that your system makes a call to a database to return all orders from a user, but another team is 
        developing the database part and you don't even know which one will be used. To do this, you can define an API such as `,
    SEC_3_PT_3: `getOrders(user)`,
    SEC_3_PT_4: `, and in your application you simply call this function, without worrying about how it will actually fetch 
        the values, just knowing that it will return an array of objects of type order. `,
    SEC_3_PT_5: `With this function, you created a communication interface between your application and another part of the 
        system, in this case, the database. Whoever implements this function will then worry about the database itself, the best 
        way to make the call to optimize performance, etc.`,
    SEC_3_PT_6: `But where does OpenTelemetry fit into this?`,
    SEC_3_PT_7: `OpenTelemetry defines APIs to produce telemetry data! This includes classes, interfaces, variables, types, 
        and functions.`,
    SEC_3_PT_8: `Some examples of what is defined by these APIs (the list is long, but you can find all these definitions at this `,
    SEC_3_PT_9: `MetricsAPI class:`,
    SEC_3_PT_10: ` has definition of all its methods (disable, getMeter, getMeterProvider, setGlobalMeterProvider, getInstance)`,
    SEC_3_PT_11: `TraceAPI class:`,
    SEC_3_PT_12: ` has definition of properties (deleteSpan, getActiveSpan, getSpan, getSpanContext, isSpanContextValid, setSpan, 
        setSpanContext, wrapSpanContext) and methods (disable, getTracer, getTracerProvider, setGlobalTracerProvider, getInstance)`,
    SEC_3_PT_13: `Histogram Interface:`,
    SEC_3_PT_14: ` defined as a hierarchy of the Histogram type, extending the MetricAttributes type and adding the definition 
        for its method (record)`,
    SEC_3_PT_15: `Knowing all the functions, classes, types, etc that OpenTelemetry defines you can then implement them, knowing 
        that you are following the standard and will remain compatible with other parts of the system that expect you to be using 
        OpenTelemetry.`,
    SEC_3_PT_16: `The problem is that just thinking about all of this needing to be implemented for you to be able to use 
        OpenTelemetry is discouraging and you have already given up on the idea. This is where SDKs come into this story.`,
    SEC_4_PT_1: `Software Development Kit (SDK) is a group of tools, which may include debuggers, compilers, libraries, documentation, 
        tutorials and guides. They exist to make life easier for developers, who need all these tools/components and will now be able 
        to find everything in one place.`,
    SEC_4_PT_2: `OpenTelemetry defines APIs, and SDKs are the implementation of these APIs. These SDKs help you generate telemetry 
        data and export it to wherever you want.`,
    SEC_4_PT_3: `The SDKs are implemented in multiple languages, giving you a wide range of choices so you can use what makes the 
        most sense for you and your application. `,
    SEC_4_PT_4: `Currently, there are SDKs in the following languages: C++, C#/.NET, Erlang/Elixir, Go, Java, JavaScript, PHP, Python, 
        Ruby, Rust and Swift. They all have implementations for Traces, Metrics and Logs, but it is worth noting that they don't have 
        the same level of interest and contributions, this means that some are more stable, while others are still marked as experimental 
        or under development. If you are interested in some of these languages that are not as developed, perhaps this will be the 
        incentive for you to start contributing!`,
    SEC_5_PT_1: `We are going to put the previous concepts into practice and collect telemetry data from an application, but to do this 
        we need to set up our environment.`,
    SEC_5_PT_2: `Note: all examples below can be found in this `,
    SEC_5_PT_3: `Github Repo`,
    SEC_6_PT_1: `To start, we need an application, but if you already have one, you can skip this step and go to the next section, 
        otherwise, let's create a very basic application in NodeJS.`,
    SEC_6_PT_2: `This example uses `,
    SEC_6_PT_3: `, but you can use any other framework.`,
    SEC_6_PT_4: `Create a blank file called `,
    SEC_6_PT_5: `Install Express dependencies`,
    SEC_6_PT_6: `Initialize typescript`,
    SEC_6_PT_7: `Create a file called `,
    SEC_6_PT_8: ` and copy the code below on it:`,
    SEC_6_PT_9: `Run your application and open `,
    SEC_6_PT_10: ` to confirm everything is working properly`,
    SEC_7_PT_1: `With the data flow explained previously in mind, the data collected needs to be sent somewhere so that it can be 
        viewed and analyzed. For this example I will use Grafana Cloud and we will need some environment variables.`,
    SEC_7_PT_2: `Sign in to `,
    SEC_7_PT_3: ` (there is a `,
    SEC_7_PT_4: `free account`,
    SEC_7_PT_5: ` too)`,
    SEC_7_PT_6: `Navigate to your `,
    SEC_7_PT_7: ` and if you have several organizations, choose on the dropdown on the top left the one you will use for this test`,
    SEC_7_PT_8: `With your organization selected, you will see a Stack list that belongs to it. Pick the one you will use for this 
        test and click on "Details". If you don't have any Stacks, you can create one by clicking on "Add Stack"`,
    SEC_7_PT_9: `Click "Configure" on the OpenTelemetry panel`,
    SEC_7_PT_10: `In the "Password/API Token" section, click "Generate now" to create the an API token:`,
    SEC_7_PT_11: `Provide a name for you token, such as `,
    SEC_7_PT_12: `Click "Create token"`,
    SEC_7_PT_13: `Click "Copy to Clipboard" to copy all environment variables`,
    SEC_7_PT_14: `Pick a name for your service, such as `,
    SEC_7_PT_15: `Now update the following  environment variables with the values you obtained on the steps above:`,
    SEC_8_PT_1: `Now that we have our environment ready, let's start our instrumentation. Here we will look at the 3 types of 
        instrumentation and how each one works.`,
    SEC_9_PT_1: `This is the easiest type of instrumentation to start, as it doesn't require much configuration and already collects 
        a lot of information. I would recommend this method as your first choice to make sure your data flow is working, environment 
        variables are correct, and data is arriving in your visualization tool as expected.`,
    SEC_9_PT_2: `As your application becomes more complex and requires more customizations, this type of instrumentation will no 
        longer suffice, so in this case (which is the vast majority of applications in production), the recommendation is to use some 
        of the other methods that will be discussed in the following sections.`,
    SEC_9_PT_3: `First we need to install some more dependencies:`,
    SEC_9_PT_4: `Then, lets define a few more environment variables:`,
    SEC_9_PT_5: `Define the exporter type as OTLP`,
    SEC_9_PT_6: `Define the type as auto instrumentation`,
    SEC_9_PT_7: `Define the resource detectors that we want to be collected by the auto instrumentation`,
    SEC_9_PT_8: `In this example, the application is running locally, but if you are running on a cloud environment, you can update the `,
    SEC_9_PT_9: ` variable to include the its detector, for example:`,
    SEC_9_PT_10: `Now run your application:`,
    SEC_9_PT_11: `Once again you can confirm that your application is running by going to `,
    SEC_9_PT_12: ` and refreshing the page a few times, seeing the displayed value change.`,
    SEC_9_PT_13: `Now let's visualize your data in Grafana Cloud:`,
    SEC_9_PT_14: `Navigate to the instance you configured previously, and click on your stack with the format `,
    SEC_9_PT_15: `Open the menu and select "Metrics" and then "New metric exploration"`,
    SEC_9_PT_16: `This page will show you all the metrics being sent to your instance (it may take a few minutes to start showing up).`,
    SEC_9_PT_17: `From here, you can select a specific graph, see its details, break it down by host, instance, etc. You can also 
        click on the "Application" menu where you can access more metrics, traces and logs.`,
    SEC_9_PT_18: `I will not detail further what is possible to do with this data, as this is not the focus of this post. Now that 
        you know that your telemetry data is arriving in your visualization tool, you can decide how it will be analyzed (whether 
        with Grafana Cloud or any other provider you have chosen).`,
    SEC_10_PT_1: `This type of instrumentation gives you a little more freedom of customization, and at the same time it still has 
        many things ready to use that you don't need to create from scratch.`,
    SEC_10_PT_2: `We can take advantage of many of the environment variables defined in auto instrumentation mode, but we will need 
        to remove the value of `,
    SEC_10_PT_3: `We also need to install a new dependency:`,
    SEC_10_PT_4: `Next, let's create an instrumentation file, for example `,
    SEC_10_PT_5: `And to run our application, we need to point to this new file (instead of the `,
    SEC_10_PT_6: ` that we had in auto 
    instrumentation previously):`,
    SEC_10_PT_7: `This file is very simple and currently doesn't collect much, so we can add more components to collect more 
    traces or metrics. First let's install a few more dependencies:`,
    SEC_10_PT_8: `And now let's update the same file to:`,
    SEC_10_PT_9: `Tip: if something is not working as you expected, you can add the following lines to this file, which will 
    print on your terminal logs about your application that can help you understand if something is wrong:`,
    SEC_10_PT_10: `Now run the application again:`,
    SEC_10_PT_11: `More telemetry data is being sent and you can follow the same steps as the previous section to see it in 
    Grafana Cloud.`,
    SEC_11_PT_1: `The last instrumentation mode is manual. With this approach you can make your instrumentation more customized, 
        collecting data that is not handled by any library. This doesn't mean that you can't use existing libraries, on the 
        contrary, if there is already something that collects exactly what you need, why bother recreating it?`,
    SEC_11_PT_2: `Let's look at this example, and for that we will install a few more dependencies:`,
    SEC_11_PT_3: `Example of a package that already collects metrics that we're interested in, without the need to create it from scratch`,
    SEC_11_PT_4: `Our file `,
    SEC_11_PT_5: ` will looks like this:`,
    SEC_11_PT_6: `With these changes, we are sending Traces and Metrics with information about Hosts and HTTP requests. You can 
        follow the same steps as in the previous section to see them in Grafana Cloud.`,
    SEC_11_PT_7: `To create a fully customizable metric, add something like the following:`,
    SEC_11_PT_8: `Example with counter, but it could also be createHistogram, createUpDownCounter or createGauge`,
    SEC_11_PT_9: `On the function in your application that you want to monitor, you would add to the counter`,
    SEC_11_PT_10: `And remember to always restart your application on each change you make:`,
    SEC_11_PT_11: `You can keep adding more metrics that make sense to you.`,
    SEC_12_PT_1: `In this post we saw 2 of the OpenTelemetry components: API and SDKs. SDKs are the implementation of the APIs. 
        SDKs can be used to make your life easier, as a lot of things already exist that you don't need to create from scratch.`,
    SEC_12_PT_2: `We also saw different instrumentation modes, and it is worth highlighting that they are not exclusive, you can 
        mix and match what works for you. Always start with the simplest and then add more, this will help you when finding any 
        errors in your implementation and will allow you to really understand what each line of code is doing!`,
    SEC_12_PT_3: `I know this post was long and perhaps there were so many options that created even more confusion, but that's 
        the point: there is no one solution that fits all cases, but having this freedom of choice and help from existing components, 
        you can choose your best path!`,
    SEC_12_PT_4: `And if there is something that you think is important, but there is still no package or plugin with the desired 
        instrumentation, create issues in the SDKs repositories, as this helps those who are contributing 
        to know the community's wishes and thus give priority for these requests.`,
    SEC_12_PT_5: `Anyway, I hope this post has taught you a little more about these two components! And more posts about the other 
        OpenTelemetry components to come!`,
    SEC_13_PT_1: `Github Repo with the examples above`,
    SEC_13_PT_2: `API and SDK Docs`,
    SEC_13_PT_3: `JS Auto instrumentation Docs`,
    SEC_13_PT_4: `JS SDK Docs`,
}

const contentPortuguese ={
    SEC_2_TITLE: `Fluxo dos dados`,
    SEC_3_TITLE: `API`,
    SEC_4_TITLE: `SDK`,
    SEC_5_TITLE: `Preparação`,
    SEC_6_TITLE: `Aplicação`,
    SEC_7_TITLE: `Visualização`,
    SEC_8_TITLE: `Instrumentação`,
    SEC_9_TITLE: `Auto instrumentação`,
    SEC_10_TITLE: `Bibliotecas de instrumentação`,
    SEC_11_TITLE: `Manual`,
    SEC_12_TITLE: `Resumo`,
    SEC_13_TITLE: `Referências (em inglês)`,
    SEC_1_PT_1: `Em um post anterior, fiz uma `,
    SEC_1_PT_2: `introdução ao OpenTelemetry`,
    SEC_1_PT_3: `, e nele listei as partes que o compõem. Neste post falarei sobre dois destes componentes:
        as APIs (Interface de Programação de Aplicações) e os SDKs (Kit de Desenvolvimento de Software).`,
    SEC_1_PT_4: `Mas antes disso, precisamos entender o contexto em que são utilizados, e para isso vamos 
        ver o fluxo dos dados de telemetria.`,
    SEC_2_PT_1: `Imagine que você possui uma aplicação que está rodando em um sistema distribuído com vários 
        serviços. Você quer coletar informações sobre ela para saber sobre a sua saúde. Neste momento, você 
        pode escrever scripts customizados dentro da sua própria aplicação, coletando esses dados de telemetria 
        e enviando para algum banco de dados que te possibilitará visualizar e analisar estes dados posteriormente.`,
    SEC_2_PT_2: `O desafio desta abordagem é que é trabalhoso e complexo, você terá que definir o que é mais 
        importante, o formato que os dados devem ser coletados e enviados, e por fim fazer toda a implementação.`,
    SEC_2_PT_3: `Agora imagine que já existisse algo que coletasse esses dados de telemetria para você e enviasse 
        para algum lugar.`,
    SEC_2_PT_4: `É neste momento que OpenTelemetry pode te ajudar, pois já existem muitas coisas estabelecidas 
        e desenvolvidas que podem fazer isso para você.`,
    SEC_2_PT_5: `Nota: um componente que pode ser adicionado neste fluxo seria o OpenTelemetry Collector, mas 
        este é um componente complexo que receberá seu próprio post no futuro.`,
    SEC_2_PT_6: `Vamos dar uma olhada agora no que já está definido pelo OpenTelemetry.`,
    SEC_3_PT_1: `Uma Interface de Programação de Aplicações (API) é a ponte de comunicação entre duas partes do 
        seu sistema. Como o nome diz, é uma interface, isso significa que não é preocupação da API como ela será 
        implementada, ela apenas define quais informações são necessárias para que a chamada ou processo seja executado 
        e qual o formato do resultado retornado (se algo for de fato retornado). Ela pode ser utilizada para acessar 
        funcionalidades ou dados.`,
    SEC_3_PT_2: `Imagine que o seu sistema  faça uma chamada em um banco de dados para retornar todos os pedidos de 
        um usuário, mas quem está desenvolvendo a parte do banco de dados é outra equipe e você sequer sabe qual 
        banco de dados será utilizado. Para isso você pode definir uma API como `,
    SEC_3_PT_3: `getPedidos(usuario)`,
    SEC_3_PT_4: `, e na sua aplicação você simplesmente chama essa função, sem se preocupar como realmente buscará 
        os valores, sabendo apenas que irá retornar um array de objetos pedidos.`,
    SEC_3_PT_5: `Com essa função você criou uma interface de comunicação entre a sua aplicação e outra parte do sistema, 
        no caso, o banco de dados. Quem for implementar essa função irá então se preocupar com o banco em si, a melhor 
        forma de fazer a chamada para otimizar performance, etc.`,
    SEC_3_PT_6: `Mas onde entra OpenTelemetry nisso?`,
    SEC_3_PT_7: `O OpenTelemetry tem APIs definidas para produzir dados de telemetria! Isso inclui classes, interfaces, 
        variáveis, tipos e funções.`,
    SEC_3_PT_8: `Alguns exemplos do que pode ser definido por essas APIs (a lista é grande, mas você consegue encontrar 
        todas estas definições neste `,
    SEC_3_PT_9: `Classe MetricsAPI:`,
    SEC_3_PT_10: ` possui definição de todos os seus métodos (disable, getMeter, getMeterProvider, setGlobalMeterProvider, 
        getInstance)`,
    SEC_3_PT_11: `Classe TraceAPI:`,
    SEC_3_PT_12: ` possui definição de propriedades (deleteSpan, getActiveSpan, getSpan, getSpanContext, 
        isSpanContextValid, setSpan, setSpanContext, wrapSpanContext) e métodos (disable, getTracer, getTracerProvider, 
        setGlobalTracerProvider, getInstance)`,
    SEC_3_PT_13: `Interface Histograma:`,
    SEC_3_PT_14: ` definida como uma hierarquia do tipo Histograma, estendendo o tipo MetricAttributes e adicionando a 
        definição para seu método (record)`,
    SEC_3_PT_15: `Sabendo todas as funções, classes, tipos, etc que o OpenTelemetry define você pode então implementá-los, 
        sabendo que está seguindo o padrão e continuará compatível com outras partes do sistema que esperam que você 
        esteja usando OpenTelemetry.`,
    SEC_3_PT_16: `O problema é que só de pensar que tudo isso precisa ser implementado para que você possa usar 
        OpenTelemetry, dá até preguiça de começar e você já desistiu da ideia. É agora que entram os SDKs nesta história.`,
    SEC_4_PT_1: `Kit de Desenvolvimento de Software (SDK) é um grupo de ferramentas, que podem incluir debuggers, 
        compiladores, bibliotecas, documentação, tutoriais e guias. Eles existem para facilitar a vida das pessoas 
        desenvolvedoras, que precisam de todas estas ferramentas/componentes e agora vão poder encontrar tudo em um único local.`,
    SEC_4_PT_2: `OpenTelemetry define APIs, e os SDKs são a implementação destas APIs. Estes SDKs te ajudam a gerar dados 
        de telemetria e exportar para onde você desejar.`,
    SEC_4_PT_3: `Os SDKs são implementados em várias linguagens, dando uma grande diversidade de escolha para que você possa 
        utilizar o que faz mais sentido para você e sua aplicação.`,
    SEC_4_PT_4: `Atualmente, existem SDKs nas linguagens: C++, C#/.NET, Erlang/Elixir, Go, Java, JavaScript, PHP, Python, Ruby, 
        Rust e Swift. Todos possuem implementação para Traces, Métricas e Logs, mas vale ressaltar que não existe o mesmo nível 
        de interesse e contribuições para todas as linguagens, isso significa que algumas estão mais estáveis, enquanto outras 
        ainda estão marcadas como experimental ou em desenvolvimento. Se você tem interesse por algumas destas linguagens que 
        não estão tão desenvolvidas, talvez este seja o incentivo para que você possa começar a contribuir!`,
    SEC_5_PT_1: `Vamos colocar em prática os conceitos anteriores e coletar dados de telemetria de uma aplicação, mas para isso 
        precisamos preparar nosso ambiente.`,
    SEC_5_PT_2: `Nota: todos os exemplos abaixo podem ser encontrados neste `,
    SEC_5_PT_3: `Repositório no Github`,
    SEC_6_PT_1: `Para começar, precisamos ter uma aplicação, mas se você já possui uma, pode pular essa etapa e ir para a 
        próxima seção, caso contrário, vamos criar uma bem básica em NodeJS.`,
    SEC_6_PT_2: `Este exemplo usa `,
    SEC_6_PT_3: `, mas você pode utilizar qualquer outro framework.`,
    SEC_6_PT_4: `Crie um arquivo em branco chamado `,
    SEC_6_PT_5: `Instale as dependências do Express`,
    SEC_6_PT_6: `Inicialize o typescript`,
    SEC_6_PT_7: `Crie um arquivo chamado `,
    SEC_6_PT_8: ` e cole o código abaixo nele`,
    SEC_6_PT_9: `Rode sua aplicação e abra `,
    SEC_6_PT_10: ` para garantir que esteja funcionando corretamente`,
    SEC_7_PT_1: `Lembrando do fluxo de dados explicado anteriormente, os dados coletados precisam ser enviados para algum lugar 
        para que possam ser visualizados e analisados. Para este exemplo usarei Grafana Cloud e para isso precisamos de 
        algumas variáveis de ambiente.`,
    SEC_7_PT_2: `Logue na sua conta `,
    SEC_7_PT_3: ` (existe `,
    SEC_7_PT_4: `conta gratuita`,
    SEC_7_PT_5: ` também)`,
    SEC_7_PT_6: `Vá para o `,
    SEC_7_PT_7: ` e se você possui várias organizações, escolha no dropwdown na parte superior à esquerda a que você 
        utilizará para este teste.`,
    SEC_7_PT_8: `Com a organização selecionada, você verá a lista de Stacks que ela possui, novamente escolha a que você utilizará 
        para este teste e clique em "Details". Caso você não possui uma Stack, clique em "Add Stack" para criar uma.`,
    SEC_7_PT_9: `Clique em "Configure" no painel "OpenTelemetry"`,
    SEC_7_PT_10: `Na área de "Password/API Token", clique em "Generate now" para criar um token da API:`,
    SEC_7_PT_11: `Dê um nome para seu token, como `,
    SEC_7_PT_12: `Clique em "Create token"`,
    SEC_7_PT_13: `Clique em "Copy to Clipboard" para copiar as variáveis de ambiente`,
    SEC_7_PT_14: `Escolha um nome para o seu serviço, como `,
    SEC_7_PT_15: `Agora atualize as variáveis de ambiente a seguir com os valores obtidos acima:`,
    SEC_8_PT_1: `Agora que temos nosso ambiente preparado, vamos começar nossa instrumentação. Aqui vamos olhar os 3 
        tipos de instrumentação e como cada uma funciona.`,
    SEC_9_PT_1: `Esse é o tipo mais fácil de instrumentação para começar, pois ela não requer muitas configurações e já coleta 
        várias informações. Eu recomendaria este método como sua primeira escolha para se certificar que seu fluxo está funcionando, 
        variáveis de ambiente estão corretas, e os dados estão chegando na sua ferramenta de visualização.`,
    SEC_9_PT_2: `Conforme sua aplicação vai ficando mais complexa e precisar de mais customizações, esse tipo de instrumentação 
        não será mais suficiente, aí neste caso (o que é a grande maioria das aplicações em produção), a recomendação é utilizar 
        alguns dos outros métodos que serão discutidos nas seções seguintes.`,
    SEC_9_PT_3: `Primeiro precisamos instalar mais algumas dependências:`,
    SEC_9_PT_4: `Em seguida, vamos definir mais algumas variáveis de ambiente:`,
    SEC_9_PT_5: `Define o tipo de exporter para tipo OTLP`,
    SEC_9_PT_6: `Define que vamos utilizar auto instrumentação`,
    SEC_9_PT_7: `Define os detectores de recursos que queremos que sejam coletados pela auto instrumentação`,
    SEC_9_PT_8: `Esse exemplo está rodando em um ambiente local, mas caso você esteja rodando em um provedor de nuvem, você 
        pode atualizar a variável `,
    SEC_9_PT_9: ` para incluir o detector específico, por exemplo:`,
    SEC_9_PT_10: `Agora é só rodar sua aplicação:`,
    SEC_9_PT_11: `Mais uma vez você pode confirmar que sua aplicação esteja rodando indo para `,
    SEC_9_PT_12: ` e atualizando a página algumas vezes, vendo o valor exibido mudar.`,
    SEC_9_PT_13: `Agora vamos visualizar seus dados na Grafana Cloud:`,
    SEC_9_PT_14: `Navegue até a instância que você configurou anteriormente, e clique na sua stack com formato `,
    SEC_9_PT_15: `Abra o menu e selecione "Metrics" e em seguida "New metric exploration"`,
    SEC_9_PT_16: `Esta página vai te mostrar todas as métricas sendo enviadas para a sua instância (pode levar alguns minutos para começar a aparecer).`,
    SEC_9_PT_17: `Daqui você pode selecionar determinado gráfico, ver seus detalhes, fazer o breakdown por host, instância, 
        etc. Você pode também clicar no menu "Application" para acessar mais algumas métricas, traces e logs.`,
    SEC_9_PT_18: `Não irei detalhar mais o que é possível fazer com esses dados, pois este não é o foco deste post. Agora que 
        você sabe que seus dados de telemetria estão chegando na sua ferramenta de visualização, você pode decidir como será feita 
        a análise dos mesmos (seja com Grafana Cloud ou qualquer outro fornecedor que você tenha escolhido).`,
    SEC_10_PT_1: `Este tipo de instrumentação te dá um pouco mais de liberdade de customização, e ao mesmo tempo ainda possui 
        muitas coisas já prontas que você não precisa criar do zero.`,
    SEC_10_PT_2: `Podemos aproveitar muitas das variáveis de ambiente definidas no modo de auto instrumentação, mas precisaremos 
        remover o valor de `,
    SEC_10_PT_3: `Também precisamos instalar uma nova dependência:`,
    SEC_10_PT_4: `Em seguida, vamos criar um arquivo de instrumentação, por exemplo `,
    SEC_10_PT_5: `E para rodar nossa aplicação, agora precisamos apontar para este novo arquivo (ao invés do `,
    SEC_10_PT_6: ` que tínhamos no auto instrumentation anteriormente): `,
    SEC_10_PT_7: `Este arquivo está bem simples e atualmente não coleta muita coisa, então podemos adicionar mais componentes 
        para que mais traces ou métricas sejam coletadas. Primeiro vamos instalar mais algumas dependências:`,
    SEC_10_PT_8: `E agora podemos atualizar o mesmo arquivo para:`,
    SEC_10_PT_9: `Dica: caso algo não esteja funcionando como você esperava, você pode adicionar neste arquivo as linhas a 
        seguir, que passarão a imprimir no seu terminal logs sobre a sua aplicação que poderá te ajudar a entender se algo está errado:`,
    SEC_10_PT_10: `Agora basta rodar a aplicação novamente:`,
    SEC_10_PT_11: `Mais dados de telemetria estão sendo enviados e você pode seguir os mesmos passos da seção anterior para 
        vê-los na Grafana Cloud.`,
    SEC_11_PT_1: `O último modo de instrumentação é o manual. Nele você pode deixar sua instrumentação mais customizada, coletando 
        dados que você não encontrou nenhuma biblioteca que já faça isso por você. Isso não significa que você não pode utilizar 
        bibliotecas já existentes, pelo contrário, se já existe algo que coleta exatamente o que você precisa, porque se dar ao 
        trabalho de recriar?`,
    SEC_11_PT_2: `Vamos olhar este exemplo, e para isso vamos instalar mais algumas dependências:`,
    SEC_11_PT_3: `Exemplo de biblioteca que já coletam algo que vamos utilizar, sem precisar criar do zero`,
    SEC_11_PT_4: `O nosso arquivo de `,
    SEC_11_PT_5: ` ficará assim:`,
    SEC_11_PT_6: `Com essas mudanças, estamos enviando informações sobre Traces e também Métricas do host e http e você pode 
        seguir os mesmos passos da seção anterior para vê-los na Grafana Cloud.`,
    SEC_11_PT_7: `Para criar uma métrica totalmente customizável, adicione algo como:`,
    SEC_11_PT_8: `Exemplo com counter, mas também poderia ser createHistogram, createUpDownCounter ou createGauge`,
    SEC_11_PT_9: `Na função da sua aplicação que você quer monitorar, adicione o valor no contador`,
    SEC_11_PT_10: `E sempre lembrando de reiniciar sua aplicação a cada mudança:`,
    SEC_11_PT_11: `Com isso você pode ir adicionando mais métricas que façam sentido para você.`,
    SEC_12_PT_1: `Neste post vimos 2 dos componentes do OpenTelemetry: API e SDKs. Os SDKs são a implementação das APIs. 
        Os SDKs podem ser utilizados para facilitar a sua vida, pois muita coisa já existe que você não precisa criar do zero.`,
    SEC_12_PT_2: `Vimos também diferentes modos de instrumentação, e vale ressaltar que eles não são excludentes, você pode 
        misturar e fazer a combinação que funcione pra você. Comece sempre com o mais simples e vá adicionando, isso  ajudará na 
        hora de achar algum erro da sua implementação e permite  entender realmente o que cada linha de código está fazendo!`,
    SEC_12_PT_3: `Sei que este post ficou longo, e apresentar tantas opções talvez tenha criado ainda mais confusão, mas esse é 
        o ponto: não existe uma solução que serve para todos os casos, então ter essa liberdade de escolha e ajuda de componentes 
        já existentes, você pode definir o seu melhor caminho!`,
    SEC_12_PT_4: `E se existe algo que você acha que seja importante, mas que ainda não existe nenhum pacote ou plugin com a 
        desejada instrumentação, crie issues nos repositórios dos SDKs, pois isso ajuda quem está contribuindo a saber os desejos 
        da comunidade e assim dar prioridade para estes pedidos.`,
    SEC_12_PT_5: `Enfim, espero que este post tenha te ensinado um pouco mais sobre esses dois componentes! E mais posts sobre 
        os outros componentes do OpenTelemetry estão por vir!`,
    SEC_13_PT_1: `Repositório no Github com os exemplos acima`,
    SEC_13_PT_2: `API e SDK Docs`,
    SEC_13_PT_3: `JS Auto instrumentation Docs`,
    SEC_13_PT_4: `JS SDK Docs`,
}