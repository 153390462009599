import moment from "moment";
import "moment/locale/pt";
import YouTubeIcon from "@mui/icons-material/YouTube";
import PodcastsIcon from "@mui/icons-material/Podcasts";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import "./post.scss";
import { useCallback } from "react";
import { LanguageKeys, trackPostSelected } from "../utils";

export type PostLocation = "external" | "local";

function getFlagImgName(language?: string) {
  switch (language) {
    case "EN":
      return "flag-cad.png";
    case "PT":
      return "flag-br.png";
    default:
      return "flag-cad.png";
  }
}

export const ExternalTypeOptions = ["POST", "VIDEO", "PODCAST", "ALL"];
export type ExternalType = (typeof ExternalTypeOptions)[number];

function getIcon(type: ExternalType) {
  switch (type) {
    case "POST":
      return <TextSnippetIcon sx={{ fontSize: 25 }} />;
    case "VIDEO":
      return <YouTubeIcon sx={{ fontSize: 25 }} />;
    case "PODCAST":
      return <PodcastsIcon sx={{ fontSize: 25 }} />;
  }
}

export interface PostProps {
  id: string;
  date: moment.Moment;
  description: string;
  img: string;
  language?: LanguageKeys;
  location?: PostLocation;
  source?: string;
  title: string;
  type?: ExternalType;
  url?: string;
  postComponent?: React.ReactElement;
  setPost?: (postID: string) => void;
}

function openLink(url: string, postID: string) {
  trackPostSelected(postID);
  window.open(url);
}

export function ExternalPost(props: PostProps) {
  const language =
    window.localStorage.getItem("language")?.toLowerCase() || "en";

  return (
    <div
      className="post-area"
      onClick={() => openLink(props.url || "", props.id)}
    >
      <img src={props.img} alt="post" className="post-pic" />
      <div className="post-details">
        <div className="first-line">
          <div className="post-title">{props.title}</div>
        </div>
        <div className="post-source">{props.source}</div>
        <div className="flag-area">
          {getIcon(props.type || "POST")}
          <img
            src={getFlagImgName(props.language)}
            width={25}
            height={14.5}
            className="flag"
            alt={`flag ${props.language}`}
          />
        </div>

        <div className="post-description">{props.description}</div>
        <div className="post-date">
          {props.date.locale(language).format("DD MMM, YYYY").toUpperCase()}
        </div>
      </div>
    </div>
  );
}

export function Post(props: PostProps) {
  const openPost = useCallback(() => {
    if (props.setPost) {
      props.setPost(props.id);
    }
  }, [props]);
  const language =
    window.localStorage.getItem("language")?.toLowerCase() || "en";

  return (
    <div className="post-area" onClick={() => openPost()}>
      <img src={props.img} alt="post" className="post-pic" />
      <div className="post-details">
        <div className="first-line margin-bottom">
          <div className="post-title">{props.title}</div>
        </div>
        <div className="flag-area">
          <TextSnippetIcon sx={{ fontSize: 25 }} />
          <img
            src={"flag-br.png"}
            width={25}
            height={14.5}
            alt={`flag br`}
            className="flag"
          />
          <img
            src={"flag-cad.png"}
            width={25}
            height={14.5}
            alt={`flag cad`}
            className="flag"
          />
        </div>
        <div className="post-description">{props.description}</div>
        <div className="post-date">
          {props.date.locale(language).format("DD MMM, YYYY").toUpperCase()}
        </div>
      </div>
    </div>
  );
}
