import moment from "moment";
import { Obs3Pillars } from "./2023-11/obs3Pillars";
import { PostProps } from "../components/post";
import { getText } from "../texts/texts";
import { ObsOnCall } from "./2023-11/obsOnCall";
import { EMAndDev } from "./2023-12/emAndDev";
import { Otel } from "./2024-05/otel";
import { OtelSdkApi } from "./2024-06/otelSdkApi";

export const posts: PostProps[] = [
  {
    id: "L-otel-sdk-api",
    location: "local",
    title: getText("LOCAL_5_TITLE"),
    description: getText("LOCAL_5_DESCRIPTION"),
    type: "POST",
    date: moment("2024-06-24"),
    img: "posts/overview/otel-sdk.png",
    language: "ALL",
    postComponent: <OtelSdkApi />,
  },
  {
    id: "E-grafana-resources",
    location: "external",
    title: getText("EXTERNAL_11_TITLE"),
    description: getText("EXTERNAL_11_DESCRIPTION"),
    source: "GRAFANA LABS BLOG",
    type: "POST",
    language: "EN",
    date: moment("2024-06-17"),
    img: "posts/overview/resources.png",
    url: "https://grafana.com/blog/2024/06/17/how-to-use-opentelemetry-resource-attributes-and-grafana-cloud-application-observability-to-accelerate-root-cause-analysis/",
  },
  {
    id: "E-lambda-3",
    location: "external",
    title: getText("EXTERNAL_10_TITLE"),
    description: getText("EXTERNAL_10_DESCRIPTION"),
    source: "LAMBDA 3 PODCAST",
    type: "PODCAST",
    language: "PT",
    date: moment("2024-06-14"),
    img: "posts/overview/lambda3.png",
    url: "https://www.lambda3.com.br/2024/06/lambda3-podcast-398-opentelemetry/",
  },
  {
    id: "E-geeking-out",
    location: "external",
    title: getText("EXTERNAL_9_TITLE"),
    description: getText("EXTERNAL_9_DESCRIPTION"),
    source: "GEEKING OUT",
    type: "VIDEO",
    language: "EN",
    date: moment("2024-05-14"),
    img: "posts/overview/geek.png",
    url: "https://www.youtube.com/watch?v=SQ-O7m_sMB4",
  },
  {
    id: "L-otel-1",
    location: "local",
    title: getText("LOCAL_4_TITLE"),
    description: getText("LOCAL_4_DESCRIPTION"),
    type: "POST",
    date: moment("2024-05-11"),
    img: "posts/overview/otel.png",
    language: "ALL",
    postComponent: <Otel />,
  },
  {
    id: "L-em-and-dev",
    location: "local",
    title: getText("LOCAL_3_TITLE"),
    description: getText("LOCAL_3_DESCRIPTION"),
    type: "POST",
    date: moment("2023-12-30"),
    img: "posts/overview/em-dev.png",
    language: "ALL",
    postComponent: <EMAndDev />,
  },
  {
    id: "L-obs-on-call",
    location: "local",
    title: getText("LOCAL_2_TITLE"),
    description: getText("LOCAL_2_DESCRIPTION"),
    type: "POST",
    date: moment("2023-11-21"),
    img: "posts/overview/on-call.png",
    language: "ALL",
    postComponent: <ObsOnCall />,
  },
  {
    id: "L-obs-3-pillars",
    location: "local",
    title: getText("LOCAL_1_TITLE"),
    description: getText("LOCAL_1_DESCRIPTION"),
    type: "POST",
    date: moment("2023-11-04"),
    img: "posts/overview/pillars.png",
    language: "ALL",
    postComponent: <Obs3Pillars />,
  },
  {
    id: "E-dose-vivo-o11y",
    location: "external",
    title: getText("EXTERNAL_8_TITLE"),
    description: getText("EXTERNAL_8_DESCRIPTION"),
    source: "DOSE DE TELEMETRIA",
    type: "VIDEO",
    language: "PT",
    date: moment("2023-10-20"),
    img: "posts/overview/podcast-telemetria.png",
    url: "https://www.youtube.com/watch?v=hf1vXCmPt_s",
  },
  {
    id: "E-sbbd-distributed-db",
    location: "external",
    title: getText("EXTERNAL_6_TITLE"),
    description: getText("EXTERNAL_6_DESCRIPTION"),
    source: getText("EXTERNAL_6_SOURCE"),
    type: "VIDEO",
    language: "PT",
    date: moment("2023-09-27"),
    img: "posts/overview/distributed.png",
    url: "https://www.youtube.com/watch?v=eXnYsgqIK_8",
  },
  {
    id: "E-gopher-con-2023",
    location: "external",
    title: getText("EXTERNAL_7_TITLE"),
    description: getText("EXTERNAL_7_DESCRIPTION"),
    source: getText("EXTERNAL_7_SOURCE"),
    type: "VIDEO",
    language: "PT",
    date: moment("2023-09-22"),
    img: "posts/overview/gophercon.png",
    url: "https://youtu.be/51cqfboUtSg?si=jp7-lPerDySOZR9v&t=861",
  },
  {
    id: "E-crdb-troubleshoot-performance",
    location: "external",
    title: getText("EXTERNAL_5_TITLE"),
    description: getText("EXTERNAL_5_DESCRIPTION"),
    source: "COCKROACH LABS BLOG",
    type: "POST",
    language: "EN",
    date: moment("2023-06-21"),
    img: "posts/overview/insights.png",
    url: "https://www.cockroachlabs.com/blog/query-performance-optimization/",
  },
  {
    id: "E-oncallmemaybe-observability",
    location: "external",
    title: getText("EXTERNAL_4_TITLE"),
    description: getText("EXTERNAL_4_DESCRIPTION"),
    source: "ON-CALL ME MAYBE",
    type: "PODCAST",
    language: "EN",
    date: moment("2023-04-25"),
    img: "posts/overview/podcast1.png",
    url: "https://open.spotify.com/episode/607d2uNdEG2k3BlW0CTXfc?si=b50aeddffd614a16",
  },
  {
    id: "E-crdb-indexes",
    location: "external",
    title: getText("EXTERNAL_3_TITLE"),
    description: getText("EXTERNAL_3_DESCRIPTION"),
    source: "COCKROACH LABS BLOG",
    type: "POST",
    language: "EN",
    date: moment("2022-08-23"),
    img: "posts/overview/indexes.png",
    url: "https://www.cockroachlabs.com/blog/indexes-for-better-workload-performance/",
  },
  {
    id: "E-programaria-retention",
    location: "external",
    title: getText("EXTERNAL_2_TITLE"),
    description: getText("EXTERNAL_2_DESCRIPTION"),
    source: "PROGRAMARIA",
    type: "POST",
    language: "PT",
    date: moment("2022-08-21"),
    img: "posts/overview/talk.png",
    url: "https://www.programaria.org/retencao-de-talentos-tech/",
  },
  {
    id: "E-crdb-query-tunning",
    location: "external",
    title: getText("EXTERNAL_1_TITLE"),
    description: getText("EXTERNAL_1_DESCRIPTION"),
    source: "COCKROACH LABS BLOG",
    type: "POST",
    language: "EN",
    date: moment("2022-08-01"),
    img: "posts/overview/tuning.png",
    url: "https://www.cockroachlabs.com/blog/dbconsole-query-tuning/",
  },
];
