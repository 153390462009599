import "./diagramBlock.scss";

interface DiagramBlockProps {
  text: string;
  enabled: boolean;
}

export function DiagramBlock(props: DiagramBlockProps) {
  const outerClass = props.enabled ? "" : "outer-block-disabled";

  return (
    <div className={`outer-block ${outerClass}`}>
      <div className="inner-block">
        <span className="content">{props.text}</span>
      </div>
    </div>
  );
}
