export const textPortuguese = {
  // Tabs
  BLOG: "BLOG",
  ABOUT: "SOBRE MIM",

  // Utils
  TABLE_CONTENTS: `Índice`,

  // About page
  DESCRIPTION_1: `Olá, sou Marylia Gutierrez, Engenheira de Software Fullstack, nascida em São Paulo (Brasil), morando em Toronto (Canadá).`,
  DESCRIPTION_2: `Gosto de aprender e compartilhar conhecimento com outras pessoas, fazendo apresentações em conferências ou falando em 
  Podcasts, por isso criei este site para compartilhar conteúdos que fiz em outros lugares, e também para postar conteúdos exclusivos 
  sobre assuntos que eu ache interessante!`,
  DESCRIPTION_3: `Espero que gostem!`,
  CONFERENCES: `CONFERÊNCIAS`,
  CONFERENCE_1: `DevOps Days Recife (Dezembro 2022): Observabilidade é mais do que 3 pilares`,
  CONFERENCE_2: `DevOps Days Fortaleza (Setembro 2023): Trazendo observabilidade para o seu plantão`,
  CONFERENCE_3: `GopherCon Brasil (Setembro 2023): Estrutura de Armazenamento de Dados em um Banco Distribuído`,
  CONFERENCE_4: `Simpósio Brasileiro de Banco de Dados (Setembro 2023): O Mundo do Banco de Dados distribuído`,
  GRAFANA_1: `Contribuição para OpenTelemetry components em uma ampla variedade de linguagens de programação.`,
  GRAFANA_2: `Desenvolvimento de soluções baseadas em OpenTelemetry, escrevendo documentação que facilite aos usuários do Grafana Cloud
  instrumentar seus aplicativos com OpenTelemetry e começar a usar o Grafana Cloud.`,
  CRDB_1: `Desenvolvimento full-stack de funcionalidades de Observabilidade do CockroachDB e CockroachDB Cloud, coletando 
    informações sobre Workloads, saúde do Cluster e Insights e tornando-as disponíveis em diferentes formas (Console, tabelas SQL, 
    Telemetry, etc).`,
  CRDB_2: `Gerenciamento da equipe de Observabilidade do Cluster para CockroachDB. 
  Responsável tanto pela parte técnica, quanto desenvolvimento de pessoas.`,
  CRDB_3: `Gerente de Engenharia patrocinadora da equipe de DevRel; Participação em ligações para ajudar clientes com perguntas
    técnicas; Entrevista de candidato(a)s; Mentoria de pessoas fora da equipe principal.`,
  IBM_1: `Desenvolvimento e gerenciamento de uma ferramenta interna sobre
  Observabilidade dos produtos Db2 on Cloud e Db2 Warehouse on Cloud.`,
  IBM_2: `Desenvolvimento de uma ferramenta interna com métricas dos produtos IBM
  on Cloud.`,
  IBM_3: `Desenvolvimento dos produtos Db2 on Cloud e Db2 Warehouse on Cloud.`,
  IBM_4: `Mentoria em Hackathons (incluindo World Humanitarian Summit
    organizado pelas Nações Unidas)
    e mentoria para novo(a)s contratado(a)s e estagiário(a)s.`,
  IBM_5: `Desenvolvimento de aplicativo iOS para Catálogo de produtos IBM e
  Atendente de segurança.`,
  TAQTILE_1: `Desenvolvimento e gerenciamento de diversos projetos para Android and
  iOS, por exemplo projetos para Stanford University, Nestle, Multiplus,
  Netshoes, Hy-Vee, entre outros.`,
  TAQTILE_2: `Agindo como designer para alguns projetos e fornecendo mentoria e
  workshops.`,
  MASTERS_TITLE: `MESTRADO`,
  MASTERS: `Mestrado - Engenharia da Computação.`,
  INTERNSHIP_TITLE: `ESTÁGIOS`,
  INTERNSHIP_1_1: `Empresa focada em projetos para aplicações Web e Mobile.`,
  INTERNSHIP_1_2: `Atividades: Planejamento, análises e desenvolvimento. Scrum master de
  projetos.`,
  INTERNSHIP_2_1: `Empresa focada em soluções para a área de saúde.`,
  INTERNSHIP_2_2: `Atividades: Treinamento para sistemas em larga escala, desenvolvimento
  e testes.`,
  INTERNSHIP_3_1: `Empresa focada em sistemas para transferências eletrônicas e seus
  derivados.`,
  INTERNSHIP_3_2: `Atividades: Planejamento, análise, scrum master, desenvolvimento de um
  sistema de gateway para pagamentos online.`,
  GRADUATION_TITLE: `GRADUAÇÃO`,
  GRADUATION: `Bacharelado em Ciência - Engenharia da Computação.`,

  // Search
  SEARCH_APPLY: `APLICAR`,
  SEARCH_RESET: `LIMPAR`,
  SEARCH_DEFAULT: `Buscar Posts`,
  SEARCH_LANGUAGE: `Língua`,
  SEARCH_LANGUAGE_ALL: `Todas as Línguas`,
  SEARCH_LANGUAGE_PT: `Português`,
  SEARCH_LANGUAGE_EN: `Inglês`,
  SEARCH_NO_RESULTS: `Nenhum Resultado`,
  SEARCH_TYPE: `Tipo`,
  SEARCH_TYPE_ALL: `Todos os Tipos`,
  SEARCH_TYPE_POST: `Texto`,
  SEARCH_TYPE_VIDEO: `Vídeo`,
  SEARCH_TYPE_PODCAST: `Podcast`,

  // Posts
  // External
  EXTERNAL_1_TITLE: `Ajuste de SQL queries com DB Console`,
  EXTERNAL_1_DESCRIPTION: `Como utilizar DB Console GUI para identificar SQL statements que 
  apresentam problemas de desempenho - além de recursos para resolver outros problemas encontrados.`,
  // --
  EXTERNAL_2_TITLE: `Boas práticas para retenção de Talentos Tech`,
  EXTERNAL_2_DESCRIPTION: `Para reter talentos, é preciso atitudes constantes de 
  valorização da equipe, feedback construtivo, reconhecimento de performance. Saiba mais 
  sobre como manter uma boa relação com a sua equipe e manter talentos tech.`,
  // --
  EXTERNAL_3_TITLE: `Como usar índices para melhorar a performance do seu workload`,
  EXTERNAL_3_DESCRIPTION: `Os índices melhoram muito o desempenho de consultas que usam filtros, 
  mas apresentam um custo para escrita. Aprenda como otimizar índices para melhorar desempenho.`,
  // --
  EXTERNAL_4_TITLE: `Observabilidade, Banco de Dados e Gerenciamento… OH MY!`,
  EXTERNAL_4_DESCRIPTION: `Como você acha tempo e energia para ser uma gerente de engenharia enquanto
  ainda escreve código? Marylia Gutierrez senta com Adriana e Ana para outro episódio de somente 
  latinas-em-tecnologia para compartilhar dicas sobre como fazer exatamente isso!`,
  // --
  EXTERNAL_5_TITLE: `Como solucionar problemas e otimizar performance de queries no CockroachDB`,
  EXTERNAL_5_DESCRIPTION: `Intelligent Insights é uma ferramenta que expõe problemas detectados 
  em workloads e schemas e oferece recomendações para melhorar seu desempenho.`,
  // --
  EXTERNAL_6_TITLE: `O Mundo do Banco de Dados distribuído`,
  EXTERNAL_6_DESCRIPTION: `Banco de Dados distribuído surgiu de uma necessidade, mas como ele 
  realmente funciona e o que podemos fazer com ele? Nesta palestra mostro como ele é necessário, 
  alguns de seus conceitos básicos de funcionamento e suas vantagens.`,
  EXTERNAL_6_SOURCE: `SIMPÓSIO BRASILEIRO DE BANCO DE DADOS`,
   // --
   EXTERNAL_7_TITLE: `Estrutura de Armazenamento de Dados em um Banco Distribuído`,
   EXTERNAL_7_DESCRIPTION: `Nesta palestra mostro como funciona o armazenamento de dados no CockroachDB,
   como dados são redistribuídos em ranges de forma que ajude no momento de busca e como estes ranges 
   são replicados.`,
   EXTERNAL_7_SOURCE: `GOPHERCON BRASIL 2023`,
  // --
  EXTERNAL_8_TITLE: `Dose ao vivo: Marylia Gutierrez`,
  EXTERNAL_8_DESCRIPTION: `Conversa sobre porquê observabilidade em um banco de dados é importante, 
  quais os aspectos mais interessantes, e quais as lições aprendidas no processo.`,
  // --
  EXTERNAL_9_TITLE: `Falando sobre contribuir para OpenTelemetry`,
  EXTERNAL_9_DESCRIPTION: `Conversa sobre como é ser uma contribuidora de OpenTelemetry, como permanecer 
  técnica na função de gerente no meu emprego anterior fez com que a transição para IC em minha função 
  atual ocorresse de forma natural e também algumas dicas para pessoas que desejam contribuir para 
  OTel pela primeira vez.`,
  // --
  EXTERNAL_10_TITLE: `Falando sobre OpenTelemetry`,
  EXTERNAL_10_DESCRIPTION: `Neste episódio do podcast, falamos sobre o que é OpenTelemetry, como usar,
  seus components e como contribuir.`,
  // --
  EXTERNAL_11_TITLE: `Como usar atributos de recursos do OpenTelemetry e Grafana Cloud Application Observability
  para acelerar análise de causas de problemas`,
  EXTERNAL_11_DESCRIPTION: `Neste passo a passo, aprenda como coletar atributos de recursos do OpenTelemetry e
  analise-os na Grafana Cloud Application Observability para investigar rapidamente problemas de desempenho
  da sua aplicação.`,
  // --

  // Local
  LOCAL_1_TITLE: `Observabilidade é mais do que 3 pilares`,
  LOCAL_1_DESCRIPTION: `Observabilidade é muitas vezes definida como 3 pilares (métricas, logs e 
  rastreamento) e os termos “monitoramento” e “observabilidade” são utilizados intercambiavelmente 
  de forma errada. Observabilidade vem evoluindo, e sua definição também deverá ser atualizada.`,
  // --
  LOCAL_2_TITLE: `Trazendo Observabilidade para o seu plantão`,
  LOCAL_2_DESCRIPTION: `A parceria entre Observabilidade e Plantão pode trazer melhorias para a 
  identificação de problemas, e consequentemente uma resolução mais rápida dos mesmos. Discutiremos 
  como essa colaboração pode ser feita, sem ser trabalhosa para as partes envolvidas.`,
  // --
  LOCAL_3_TITLE: `Gerência e Desenvolvimento de Software`,
  LOCAL_3_DESCRIPTION: `Neste post compartilho minha experiência entrando na trilha de gerência e 
  como me organizei para ser uma gerente eficiente e que ainda desenvolve bastante código.`,
  // --
  LOCAL_4_TITLE: `Introdução a OpenTelemetry`,
  LOCAL_4_DESCRIPTION: `Post com introdução sobre OpenTelemetry, explicando de onde surgiu, para que 
  serve, seus desafios e componentes.`,
  // --
  LOCAL_5_TITLE: `OpenTelemetry - SDKs e APIs`,
  LOCAL_5_DESCRIPTION: `Post explicando e colocando em prática OpenTelemetry SKDs e APIs.`,
};

export type TextsPortugueseKeys = keyof typeof textPortuguese;
