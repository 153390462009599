import "./blog.scss";
import {
  PostProps,
  ExternalPost,
  Post,
  ExternalType,
} from "../components/post";

import { getText } from "../texts/texts";
import { Contact } from "../components/contact";
import { FilterOptions, Search } from "../components/search";
import { useState } from "react";
import { PostPage } from "../components/postPage";
import { LanguageKeys, isSmallScreen, trackPostSelected, updateSearchParams } from "../utils";
import { posts } from "../posts/postsList";
// import { Hair } from "../components/hair";
import Pagination from "@mui/material/Pagination";

interface Page {
  current: number;
  total: number;
}
const POSTS_PER_PAGE = 50;

interface HairColor {
  red: number;
  green: number;
  blue: number;
}

export function Blog() {
  const params = new URLSearchParams(window.location.search);
  const [searchValue, setSearchValue] = useState(
    params.get("searchValue") || ""
  );
  const [filter, setFilter] = useState<FilterOptions>({
    language: params.get("filterLanguage") || "ALL",
    type: params.get("filterType") || "ALL",
  });
  const [postID, setPostID] = useState(params.get("postID") || "");
  const [tracked, setTracked] = useState(false);

  if (params.get("postID") && !tracked) {
    trackPostSelected(params.get("postID") || "")
    setTracked(true);
  }
  
  const [page, setPage] = useState<Page>({
    current: 1,
    total: Math.ceil(posts.length / POSTS_PER_PAGE),
  });
  const [hairColor, setHairColor] = useState<HairColor>({
    red: 0,
    green: 0,
    blue: 0,
  });
  var div = document.getElementById("root");
  if (div) {
    div.addEventListener("mousemove", function (e) {
      setHairColor({
        red: e.offsetX % 255,
        green: e.offsetY % 255,
        blue: Math.abs(e.offsetX - e.offsetY) % 255,
      });
    });
  }

  const handlePostID = (postID: string) => {
    setPostID(postID);
    updateSearchParams([{ key: "postID", value: postID }]);
  };

  if (postID) {
    const post = getPostByID(postID);
    if (post && post.postComponent) {
      return (
        <PostPage post={post.postComponent} onBack={() => handlePostID("")} />
      );
    }
  }

  function filterPosts(posts: PostProps[], searchValue: string): PostProps[] {
    if (
      searchValue === "" &&
      filter.language === "ALL" &&
      filter.type === "ALL"
    ) {
      return posts;
    }

    const results = posts.filter(
      (p) =>
        postHasLanguage(filter.language, p) &&
        postHasType(filter.type, p) &&
        postHasSearchTerm(searchValue, p)
    );
    const total = Math.ceil(results.length / POSTS_PER_PAGE);

    if (total !== page.total) {
      setPage({ current: 1, total: total });
    }

    return results;
  }

  const filterBySearch = (search: string) => {
    setSearchValue(search);
    updateSearchParams([{ key: "searchValue", value: search }]);
  };

  const applyFilter = (filterValues: FilterOptions) => {
    console.log("APPLY: ", filterValues);
    setFilter(filterValues);
  };

  const filteredPosts = filterPosts(posts, searchValue);
  const postsOnPage = isSmallScreen()
    ? filteredPosts
    : getPostsForPage(filteredPosts, page);

  const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => {
    setPage({ current: value, total: page.total });
  };

  return (
    <div className="base-posts">
      <div className="posts-area">
        <Search
          searchValue={searchValue}
          filterBySearch={filterBySearch}
          applyFilter={applyFilter}
        />
        {postsOnPage.length === 0 && (
          <>
            <img
              src={"shrug.png"}
              width={400}
              className="no-results-area"
              alt={`no results`}
            />
            <span className="no-results-text">
              {getText("SEARCH_NO_RESULTS")}
            </span>
          </>
        )}
        {postsOnPage.map((post: PostProps) => {
          if (post.location === "external") {
            return (
              <div key={post.id}>
                <ExternalPost
                  id={post.id}
                  title={post.title}
                  description={post.description}
                  source={post.source}
                  type={post.type}
                  language={post.language}
                  date={post.date}
                  img={post.img}
                  url={post.url}
                />
                <div className="space-mobile" />
              </div>
            );
          }
          return (
            <div key={post.id}>
              <Post
                id={post.id}
                title={post.title}
                description={post.description}
                date={post.date}
                img={post.img}
                postComponent={post.postComponent}
                setPost={handlePostID}
              />
              <div className="space-mobile" />
            </div>
          );
        })}
        {filteredPosts.length > POSTS_PER_PAGE && (
          <Pagination
            count={page.total}
            page={page.current}
            onChange={handlePageChange}
            className="pagination-area"
          />
        )}
      </div>
      {filteredPosts.length > 0 && (
        <>
          {/* <div className="hair-color" id="circle">
            <Hair
              color={`rgb(${hairColor.red}, ${hairColor.green}, ${hairColor.blue})`}
            />
          </div> */}

          <img
            src={"marylia.png"}
            className="caricature"
            alt={`marylia caricature`}
          />
        </>
      )}
      <Contact />
    </div>
  );
}

function getPostsForPage(posts: PostProps[], page: Page): PostProps[] {
  const start = (page.current - 1) * POSTS_PER_PAGE;
  if (page.current !== page.total) {
    return posts.slice(start, start + POSTS_PER_PAGE);
  }
  return posts.slice(start);
}

function postHasLanguage(language: "" | LanguageKeys, post: PostProps) {
  if (language === "" || language === "ALL" || post.language === "ALL") {
    return true;
  }
  return post.language === language;
}

function postHasType(type: "" | ExternalType, post: PostProps) {
  if (type === "" || type === "ALL") {
    return true;
  }
  return post.type === type;
}

function postHasSearchTerm(search: string, post: PostProps) {
  if (search === "") {
    return true;
  }
  const postInfo =
    `${post.title} ${post.description} ${post.source} ${post.url}`.toLocaleLowerCase();
  // If search term is wrapped by quotes, do the exact search term.
  if (search.startsWith('"') && search.endsWith('"')) {
    search = search.substring(1, search.length - 1);
    return postInfo.includes(search.toLocaleLowerCase());
  }

  return search
    .toLowerCase()
    .split(" ")
    .every((val) => postInfo.includes(val));
}

function getPostByID(postID: string): PostProps | null {
  const filtered = posts.filter((p) => p.id === postID);
  if (filtered.length > 0) {
    return filtered[0];
  }
  return null;
}
