import { getSiteLanguage } from "../../utils";

export function getContentObsOnCall(
  key: ContentPortugueseKeys | ContentEnglishKeys
): string {
  if (getSiteLanguage() === "PT") {
    return contentPortuguese[key];
  }
  return contentEnglish[key];
}

type ContentPortugueseKeys = keyof typeof contentPortuguese;
type ContentEnglishKeys = keyof typeof contentEnglish;

const contentEnglish = {
  OBS: `Observability`,
  ON_CALL: `On-Call`,
  USER: `User`,
  SUPPORTER: `Person on call`,
  ANALYSIS: `Observability Analysis`,
  OBS_ON_CALL: `Observability and On-Call`,
  IMG_1: ``,
  IMG_2: ``,
  SEC_1_PT_1: `The collaboration between Observability and On-Call can bring improvements to the 
    identification of problems, and consequently their faster resolution, which makes the end user 
    happy to have their problem resolved and generates a less stressful process for people solving 
    the escalation.`,
  SEC_1_PT_2: `If you've never been on call in your life, first congratulations on being lucky! 
    Second, let's understand how it works and what Observability is.`,
  SEC_2_PT_1: `On-call is a period of tranquility, without major issues, that people look forward to!`,
  SEC_2_PT_2: `Hm… Scratch that! That was the definition for vacation…`,
  SEC_2_PT_3: `On-call is a period that you need to be available to answer and solve possible 
    escalations.`,
  SEC_2_PT_4: `An escalation is when something goes wrong in your application, service or system 
    and someone is responsible for finding out what went wrong and how to fix it (or at least provide 
    an alternative).`,
  SEC_2_PT_5: `The longer the on-call exists, the harder it is. But why does this happen?`,
  SEC_2_PT_6: `Because the easiest problems have already been found and solved, whether during development, 
    testing, or even by the users themselves. Who has never received a bug report as soon as something 
    was released, which was easily reproduced and you still can't believe how none of the tests caught it?`,
  SEC_2_PT_7: `When the easiest problems have already been solved, what remains are the ones you didn't 
    foresee, some extreme case, a scenario you can't reproduce or even something you have no idea how is 
    even possible (the good old: it works on my machine!).`,
  SEC_2_PT_8: `Solving problems like this can be very challenging and stimulating, but at the same time 
    very difficult, tiring and even frustrating.`,
  SEC_3_PT_1: `How well you can understand and explain any state from your system, no matter how bizarre 
    or novel it is, without needing to predict it.`,
  SEC_3_PT_2: `If you want to understand a little more about Observability, take a look at another post 
    here on the site, called `,
  SEC_3_PT_3: `Observability is more than 3 pillars`,
  SEC_3_PT_4: `The next step is to combine Observability and On-Call, to serve both the user and the 
    person on on-call.`,
  SEC_4_PT_1: `The best on-call shift is the one that has no escalations. There are 2 ways that would 
    allow this to happen:`,
  SEC_4_PT_2: `The first way is having a product that does not have any bugs, the documentation is 
    complete and everything works perfectly, so that no one needs your help. Unfortunately, that doesn't 
    happen (I bet some of you even laughed when reading this), so we have to prepare ourselves for when a 
    problem does happen.`,
  SEC_4_PT_3: `The second way is to provide enough observability to the user so that they can solve their 
    problems on their own. Of course, if there is an error in your product, you will have to fix it, here I 
    am referring to the case in which there is a problem that the user can solve without the help from the 
    support team.`,
  SEC_4_PT_4: `For example, imagine that your product is a database, and the application using it has an 
    increase in latency, what type of information can your product provide so that it is possible to 
    understand the problem?`,
  SEC_4_PT_5: `First would be to show an overview, about states of all nodes, version, location, etc. You 
    also need to show data about the workload, such as how long each query is taking to execute, being able 
    to identify the slowest ones, those with contention, etc. Information about what is running at that moment 
    (sometimes a backup is consuming a lot of memory/CPU). Data can be shown about the indexes used (if they 
    are actually being used, if they are efficient, if new ones should be created, etc.).`,
  SEC_4_PT_6: `Ultimately, the more actionable information you provide, the better. Having clear and 
    up-to-date documentation is also very important.`,
  SEC_5_PT_1: `At some point, you will get assigned to a ticket from an escalation, so let's look at how 
    this process might work.`,
  SEC_5_PT_2: `User creates ticket`,
  SEC_5_PT_3: `TSE (Technical Support Engineer) tries to resolve it`,
  SEC_5_PT_4: `Investigation of a new bug`,
  SEC_5_PT_5: `Solution and creation of new version/Alternative`,
  SEC_5_PT_6: `In most cases, when a new ticket is created, someone from support will be able to resolve it 
    using documentation, their own experience and runbooks. Other information can also be used such as logs, 
    traces, metrics and debugging tools specific to your product.`,
  SEC_5_PT_7: `When this is not enough, we enter the process of investigating a new bug. At this point a 
    developer can be involved. The tools used here will be similar to what was used by the support team, but 
    now they will be seen with different eyes. The product code itself can also be analyzed.`,
  SEC_5_PT_8: `When the new problem is identified, an update of documentation, runbooks, etc. must be made. 
    This new information can be used by the support team in future escalations, without the need to involve 
    the developer team again.`,
  SEC_5_PT_9: `At this point we also have the resolution itself, which may include generating a new version 
    of your application, service or system.`,
  SEC_6_PT_1: `At any point in the process described above, the people involved can make a request for more 
    observability. If you use github for example, it could be as simple as adding a new label called 
    "needs-observability".`,
  SEC_6_PT_2: `The Observability team can then look at all tickets marked with this label, and if the 
    information in the ticket is sufficient to understand what the request is, the team can create an issue 
    and eventually develop the feature or improvement.`,
  SEC_6_PT_3: `When the ticket is more complex, the team can analyze the request by calling the people involved 
    (support and engineering), so they can better explain the entire context, what they tried to do and what 
    improvement in Observability would have made that ticket easier. Often in this analysis, the observability 
    team can think of solutions that support hadn't even considered, but would make things better.`,
  SEC_6_PT_4: `The observability team can also analyze all tickets to find patterns and trends, which can also 
    bring ideas for new features and improvements.`,
  SEC_7_PT_1: `Bringing these two areas together is a collaborative effort between the support, engineering 
    and observability teams and is beneficial for everyone involved.`,
  SEC_7_PT_2: `This process is a cycle, as new features will continue to be created and more observability 
    will need to be created to support them.`,
  SEC_7_PT_3: `With this collaboration in place, we will be able to achieve the goal of tickets that are easier 
    to resolve, less stress and satisfied users.`,
};

const contentPortuguese = {
  OBS: `Observabilidade`,
  ON_CALL: `Plantão`,
  USER: `Pessoa Usuária`,
  SUPPORTER: `Pessoa de Plantão`,
  ANALYSIS: `Análise da Observabilidade`,
  OBS_ON_CALL: `Observabilidade e Plantão`,
  IMG_1: `Alô, TI, você já tentou desligar e ligar novamente?`,
  IMG_2: `A forma mais eficiente de se livrar de todos os bugs foi se livrar de todo o software`,
  SEC_1_PT_1: `A colaboração entre Observabilidade e Plantão pode trazer melhorias para a 
    identificação de problemas, e consequentemente sua resolução mais rápida, o que deixa a pessoa 
    usuária final feliz por ter seu problema resolvido e gera um processo menos estressante para as 
    pessoas resolvendo o chamado.`,
  SEC_1_PT_2: `Se você nunca esteve de plantão na vida, primeiro parabéns pela sua sorte! 
    Segundo, vamos entender como ele funciona e o que é Observabilidade.`,
  SEC_2_PT_1: `Plantão é um período de tranquilidade, sem grandes problemas, muito aguardado 
    pelas pessoas!`,
  SEC_2_PT_2: `Hm… Frase errada! Essa era a definição de férias…`,
  SEC_2_PT_3: `Plantão é um período em que você precisa estar disponível para responder e 
    resolver a possíveis chamados.`,
  SEC_2_PT_4: `Um chamado é quando algo dá errado na sua aplicação, serviço ou sistema e alguém 
    é responsável por descobrir o que deu errado e consertar (ou pelo menos sugerir alternativas).`,
  SEC_2_PT_5: `Quanto mais tempo o plantão existe, mais difícil ele é. Mas porque isso acontece?`,
  SEC_2_PT_6: `Porque os problemas mais fáceis já foram encontrados e resolvidos, seja durante o 
    desenvolvimento, testes, ou até mesmo pelas próprias pessoas usuárias. Quem nunca recebeu um 
    chamado de algum bug assim que algo foi lançado, que foi facilmente reproduzido e você não 
    acredita até hoje como nenhum dos testes pegou aquele erro?`,
  SEC_2_PT_7: `Quando os problemas mais fáceis já foram resolvidos, o que sobra são os que você 
    não previu, algum caso extremo, um cenário que você não consegue reproduzir ou até mesmo algo 
    que você não faz ideia de como é possível (o bom e velho: funciona no meu computador!).`,
  SEC_2_PT_8: `Resolver problemas assim pode ser bem desafiador e estimulante, mas ao mesmo tempo 
    bem trabalhoso, cansativo e até mesmo frustrante.`,
  SEC_3_PT_1: `Quão bem você consegue entender e explicar qualquer estado do seu sistema, independente 
    de quão diferente ou improvável, sem precisar ter previsto ele.`,
  SEC_3_PT_2: `Se você quiser entender um pouco mais sobre Observabilidade, dê uma olhada no outro 
    post aqui do site, chamado `,
  SEC_3_PT_3: `Observabilidade é mais do que 3 pilares`,
  SEC_3_PT_4: `O próximo passo é juntar a Observabilidade e o Plantão, para atender tanto a pessoa 
    usuária quanto a pessoa de plantão.`,
  SEC_4_PT_1: `O melhor plantão é aquele que não tem chamados. Para que isso aconteça existem 2 formas:`,
  SEC_4_PT_2: `A primeira forma é o seu produto não possuir nenhum bug, a documentação estar completa 
    e tudo funcionar perfeitamente, de forma que ninguém precise de sua ajuda. Infelizmente, isso não 
    acontece (aposto que alguns de vocês até riram ao ler essa opção), então temos que nos preparar 
    para quando um problema acontecer.`,
  SEC_4_PT_3: `A segunda forma é fornecer observabilidade suficiente para a pessoa usuária de forma 
    que ela consiga resolver seus problemas sozinha. É claro que se há um erro no seu produto, você 
    terá que arrumar, aqui estou me referindo ao caso em que há um problema que a própria pessoa usuária 
    consegue resolver sem ajuda da equipe de suporte.`,
  SEC_4_PT_4: `Por exemplo, imagine que seu produto é um banco de dados, e a aplicação utilizando-o 
    teve um aumento de latência, que tipo de informação o seu produto pode fornecer para que seja possível 
    entender o problema?`,
  SEC_4_PT_5: `Primeiro seria mostrar uma visão geral, sobre estados de todos os nós, versão, localização, 
    etc. Também é preciso mostrar dados sobre seu workload, como quanto tempo cada query está demorando 
    para ser executada, sendo possível identificar as mais lentas, com contenção, etc. Informações sobre 
    o que está sendo executado naquele momento (às vezes algum backup está consumindo muita memória/CPU). 
    Pode-se mostrar dados sobre os índices utilizados (se realmente estão sendo utilizados, se são 
    eficientes, se novos deveriam ser criados, etc).`,
  SEC_4_PT_6: `Enfim, quanto mais informações você fornecer que tenham ações que a pessoa usuária possa 
    fazer a partir delas, melhor. Ter documentação clara e atualizada também é muito importante.`,
  SEC_5_PT_1: `Em algum momento, você receberá um chamado, então vamos olhar como esse processo pode 
    funcionar.`,
  SEC_5_PT_2: `Pessoa usuária abre chamado`,
  SEC_5_PT_3: `TSE (Technical Support Engineer) tenta resolver`,
  SEC_5_PT_4: `Investigação de um novo bug`,
  SEC_5_PT_5: `Solução e criação de nova versão/Alternativa`,
  SEC_5_PT_6: `Na maioria dos casos, quando um novo chamado é criado, alguém do suporte vai conseguir 
    resolver utilizando documentação, sua própria experiência e runbooks. Outras informações também podem 
    ser utilizadas como logs, traces, métricas e ferramentas de debug específicas para o seu produto.`,
  SEC_5_PT_7: `Quando isso não é suficiente, entramos no processo para investigação de um novo bug. Neste 
    momento uma pessoa desenvolvedora pode ser envolvida. As ferramentas utilizadas aqui serão similares 
    ao que foi utilizado pela equipe de suporte, mas agora serão vistas com outros olhos. Também pode ser 
    analisado o código do produto em si.`,
  SEC_5_PT_8: `Quando o novo problema é identificado, deverá ser feita uma atualização de documentação, 
    runbooks, etc. Essa nova informação pode ser utilizada pela equipe de suporte em futuros chamados, 
    sem a necessidade de envolver a equipe de desenvolvedores novamente.`,
  SEC_5_PT_9: `Nesse ponto também temos a resolução em si, que pode incluir geração de uma nova versão da 
    sua aplicação, serviço ou sistema.`,
  SEC_6_PT_1: `Em qualquer momento do processo descrito anteriormente, as pessoas envolvidas podem fazer 
    uma solicitação para ter mais observabilidade. Se você utiliza github por exemplo, pode ser simples 
    como adicionar um novo label chamado "precisa-de-observabilidade".`,
  SEC_6_PT_2: `A equipe de Observabilidade pode então olhar todos os chamados marcados com esse label, e 
    se as informações no ticket são suficientes para entender qual é o pedido, a equipe já pode criar uma 
    issue para que possam desenvolver a feature ou melhoria.`,
  SEC_6_PT_3: `Quando o chamado é mais complexo, a equipe pode fazer a análise do pedido chamando as pessoas 
    envolvidas (suporte e engenharia), assim elas podem explicar melhor todo o contexto, o que tentaram 
    fazer e qual melhoria na Observabilidade teria feito aquele chamado mais fácil. Muitas vezes nessa 
    análise, a equipe consegue pensar em soluções que o suporte não havia sequer considerado, mas tornará 
    as coisas melhores.`,
  SEC_6_PT_4: `A equipe de observabilidade pode também fazer uma análise de todos os chamados para 
    encontrar padrões e tendências, que podem também trazer ideias de novas features e melhorias.`,
  SEC_7_PT_1: `Reunir essas duas áreas é um trabalho em conjunto entre as equipes de suporte, engenharia 
    e observabilidade e é benéfica para todos os envolvidos.`,
  SEC_7_PT_2: `Este processo é um ciclo, pois novas features vão continuar sendo criadas e mais 
    observabilidade precisará ser criada para dar suporte a elas.`,
  SEC_7_PT_3: `Com essa colaboração em vigor, conseguiremos atingir o objetivo de chamados mais fáceis de 
    serem resolvidos, menos estresse e pessoas usuárias satisfeitas.`,
};
