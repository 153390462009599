import React, { useEffect, useState } from "react";
import { getText } from "../texts/texts";
import Link from "@mui/material/Link";
import { trackConferenceSelected } from "../utils";

interface ExperienceProps {
  imgSize: number;
}

export function ExperienceConferences() {
  return (
    <>
      <div className="float-left">
        <p className="info-text info-text-right">
          <Link
            href="https://www.youtube.com/watch?v=eXnYsgqIK_8"
            underline="always"
            target="_blank"
            onClick={() => {trackConferenceSelected("sbbd-2023")}}
          >
            • {getText("CONFERENCE_4")}
          </Link>
        </p><br/>
        <p className="info-text info-text-right">
          <Link
            href="https://youtu.be/51cqfboUtSg?si=jp7-lPerDySOZR9v&t=861"
            underline="always"
            target="_blank"
            onClick={() => {trackConferenceSelected("gophercon-2023")}}
          >
            • {getText("CONFERENCE_3")}
          </Link>
        </p><br/>
        <p className="info-text info-text-right">
          <Link
            href="https://devopsdays.org/events/2023-fortaleza/program/marylia-gutierrez"
            underline="always"
            target="_blank"
            onClick={() => {trackConferenceSelected("devops-fortaleza-2023")}}
          >
            • {getText("CONFERENCE_2")}
          </Link>
        </p><br/>
        <p className="info-text info-text-right">
          <Link
            href="https://devopsdays.org/events/2022-recife/program/marylia-gutierrez"
            underline="always"
            target="_blank"
            onClick={() => {trackConferenceSelected("devops-recife-2022")}}
          >
            • {getText("CONFERENCE_1")}
          </Link>
        </p>
      </div>
    </>
  );
}

export function ExperienceGrafana(props: ExperienceProps) {
  return (
    <>
      <div className="experience-info-area float-left">
        <img
          src="about/logo-grafana.png"
          width={props.imgSize}
          height={props.imgSize}
          alt="Grafana logo"
        />
        <div className="info-text info-text-right">
          {getText("GRAFANA_1")}
        </div>
      </div>
      <div className="experience-info-area float-right">
        <div className="info-text info-text-left">{getText("GRAFANA_2")}</div>
      </div>
    </>
  );
}

export function ExperienceCRDB(props: ExperienceProps) {
  return (
    <>
      <div className="experience-info-area float-left">
        <img
          src="about/logo-crdb.png"
          width={props.imgSize}
          height={props.imgSize}
          alt="Cockroach Labs logo"
        />
        <div className="info-text info-text-right">
          {getText("CRDB_1")}
          <br />
          Go, TypeScript, React.
        </div>
      </div>
      <div className="experience-info-area float-right">
        <div className="info-text info-text-left">{getText("CRDB_2")}</div>
      </div>
      <div className="experience-info-area float-left">
        <div className="info-text info-text-right">{getText("CRDB_3")}</div>
      </div>
    </>
  );
}

export function ExperienceIBM(props: ExperienceProps) {
  return (
    <>
      <div className="experience-info-area float-left">
        <img
          src="about/logo-ibm.png"
          width={props.imgSize}
          height={props.imgSize}
          alt="ibm logo"
        />
        <div className="info-text info-text-right">
          {getText("IBM_1")}
          <br />
          Python, JavaScript, Node.js, Vue.js, DB2, Luigi, Looker, Segment,
          Docker, Ruby
        </div>
      </div>
      <div className="experience-info-area float-right">
        <div className="info-text info-text-left">
          {getText("IBM_2")}
          <br />
          Python, JavaScript, Node.js, Dust, DB2, Looker, Swagger
        </div>
      </div>
      <div className="experience-info-area float-left">
        <div className="info-text info-text-right">
          {getText("IBM_3")}
          <br />
          Java
        </div>
      </div>
      <div className="experience-info-area float-right">
        <div className="info-text info-text-left">{getText("IBM_4")}</div>
      </div>
      <div className="experience-info-area float-left">
        <div className="info-text info-text-right">
          {getText("IBM_5")}
          <br />
          Objective-C
        </div>
      </div>
    </>
  );
}

export function ExperienceTaqtile(props: ExperienceProps) {
  return (
    <>
      <div className="experience-info-area float-left">
        <img
          src="about/logo-taqtile.png"
          width={props.imgSize}
          height={props.imgSize}
          alt="taqtile logo"
        />
        <div className="info-text info-text-right">
          {getText("TAQTILE_1")}
          <br />
          Java, Objective-C.
        </div>
      </div>
      <div className="experience-info-area float-right">
        <div className="info-text info-text-left">{getText("TAQTILE_2")}</div>
      </div>
    </>
  );
}

export function ExperienceMasters(props: ExperienceProps) {
  return (
    <>
      <div className="experience-info-area float-left">
        <img
          src="about/logo-poli.png"
          width={props.imgSize}
          height={props.imgSize}
          alt="poli logo"
        />
        <div className="info-text">{getText("MASTERS")}</div>
      </div>
    </>
  );
}

export function ExperienceInternships(props: ExperienceProps) {
  return (
    <>
      <div className="experience-info-area float-left">
        <img
          src="about/logo-taqtile-indigo.jpeg"
          width={props.imgSize}
          height={props.imgSize}
          alt="indigo logo"
        />
        <div className="info-text">
          {getText("INTERNSHIP_1_1")}
          <br />
          <br />
          {getText("INTERNSHIP_1_2")}
          <br />
          <br />
          PHP, Java, MySQL, JQuery, HTML, CSS, JavaScript e Photoshop.
          <br />
          Objective-C, Java, Ruby on Rails.
        </div>
      </div>
      <div className="experience-info-area float-right">
        <div className="info-text info-text-left">
          {getText("INTERNSHIP_2_1")}
          <br />
          <br />
          {getText("INTERNSHIP_2_2")}
          <br />
          <br />
          Java, Oracle, HTML, CSS,JSP, JavaScript, Weblogic, Tomcat, Selenium.
        </div>
        <img
          src="about/logo-touch.png"
          width={props.imgSize}
          height={props.imgSize}
          alt="touch logo"
        />
      </div>
      <div className="experience-info-area float-left">
        <img
          src="about/logo-software-express.jpeg"
          width={props.imgSize}
          height={props.imgSize}
          alt="software express logo"
        />
        <div className="info-text">
          {getText("INTERNSHIP_3_1")}
          <br />
          <br />
          {getText("INTERNSHIP_3_2")}
          <br />
          <br />
          Java, C.
        </div>
      </div>
    </>
  );
}

export function ExperienceGraduation(props: ExperienceProps) {
  return (
    <>
      <div className="experience-info-area float-left">
        <img
          src="about/logo-poli.png"
          width={props.imgSize}
          height={props.imgSize}
          alt="poli logo"
        />
        <div className="info-text">{getText("GRADUATION")}</div>
      </div>
    </>
  );
}

export function handleExperienceScroll(
  experienceTop: boolean,
  setExperienceTop: React.Dispatch<React.SetStateAction<boolean>>,
  topThreshold: number,
  id: string,
  heightDiff: number
) {
  const value = document.getElementById(`col1-${id}`);
  const details = document
    .getElementById(`${id}-info`)
    ?.getBoundingClientRect();
  const bottom = details?.bottom || 0;
  const top = details?.top || topThreshold;
  if (experienceTop) {
    // Check for new position after it's already fixed.
    if (top > topThreshold) {
      setExperienceTop(false);
      return;
    }

    if (value) {
      const newTop = bottom - heightDiff;
      if (newTop < topThreshold) {
        value.style.top = newTop + "px";
      } else {
        value.style.top = "75px";
      }
    }
  } else {
    // Check for new position while is not fixed.
    const top = document.getElementById(id)?.getBoundingClientRect().top || 0;
    if (top >= topThreshold - 500 && top <= topThreshold + 5) {
      setExperienceTop(true);
    }
  }
}

interface LabelLineProps {
  label: string;
  date: string;
  id: string;
}

function LabelLine(props: LabelLineProps) {
  return (
    <div className={`line-area ${props.id}`} id={props.id}>
      <span className="line-label">{props.label}</span>
      <span className="line-date">{props.date}</span>
      <div className="horizontal-line" />
    </div>
  );
}

// Professional Experience for large screen
export function ProfessionalExperience() {
  const [experience1Top, setExperience1Top] = useState(false);
  const [experience2Top, setExperience2Top] = useState(false);
  const [experience3Top, setExperience3Top] = useState(false);
  const [experience4Top, setExperience4Top] = useState(false);
  const [experience5Top, setExperience5Top] = useState(false);
  const [graduation1Top, setGraduation1Top] = useState(false);
  const [graduation2Top, setGraduation2Top] = useState(false);
  const [conferencesTop, setConferencesTop] = useState(false);
  const topThreshold: number = 75;

  useEffect(() => {
    const onScroll = () => {
      handleExperienceScroll(
        conferencesTop,
        setConferencesTop,
        topThreshold,
        "conferences",
        100
      );
      handleExperienceScroll(
        experience1Top,
        setExperience1Top,
        topThreshold,
        "estagios",
        300
      );
      handleExperienceScroll(
        experience2Top,
        setExperience2Top,
        topThreshold,
        "taqtile",
        100
      );
      handleExperienceScroll(
        experience3Top,
        setExperience3Top,
        topThreshold,
        "ibm",
        100
      );
      handleExperienceScroll(
        experience4Top,
        setExperience4Top,
        topThreshold,
        "crdb",
        100
      );
      handleExperienceScroll(
        experience5Top,
        setExperience5Top,
        topThreshold,
        "grafana",
        100
      );
      handleExperienceScroll(
        graduation1Top,
        setGraduation1Top,
        topThreshold,
        "poli1",
        100
      );
      handleExperienceScroll(
        graduation2Top,
        setGraduation2Top,
        topThreshold,
        "poli2",
        100
      );
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [
    conferencesTop,
    setConferencesTop,
    experience1Top,
    setExperience1Top,
    experience2Top,
    setExperience2Top,
    experience3Top,
    setExperience3Top,
    experience4Top,
    setExperience4Top,
    experience5Top,
    setExperience5Top,
    graduation1Top,
    setGraduation1Top,
    graduation2Top,
    setGraduation2Top,
  ]);

  // Conferences variables.
  let conferencesLabel = "pos-relative";
  let conferencesDetails = "";
  if (conferencesTop) {
    conferencesLabel = "pos-fixed";
    conferencesDetails = "margin-left-50";
  }

  // Experience 1 variables.
  let experience1Label = "pos-relative";
  let experience1Details = "";
  if (experience1Top) {
    experience1Label = "pos-fixed";
    experience1Details = "margin-left-50";
  }

  //Experience 2 variables.
  let experience2Label = "pos-relative";
  let experience2Details = "";
  if (experience2Top) {
    experience2Label = "pos-fixed";
    experience2Details = "margin-left-50";
  }

  // Experience 3 variables.
  let experience3Label = "pos-relative";
  let experience3Details = "";
  if (experience3Top) {
    experience3Label = "pos-fixed";
    experience3Details = "margin-left-50";
  }

  // Experience 4 variables.
  let experience4Label = "pos-relative";
  let experience4Details = "";
  if (experience4Top) {
    experience4Label = "pos-fixed";
    experience4Details = "margin-left-50";
  }

  // Experience 5 variables.
  let experience5Label = "pos-relative";
  let experience5Details = "";
  if (experience5Top) {
    experience5Label = "pos-fixed";
    experience5Details = "margin-left-50";
  }

  // Graduation 1 variables.
  let graduation1Label = "pos-relative";
  let graduation1Details = "";
  if (graduation1Top) {
    graduation1Label = "pos-fixed";
    graduation1Details = "margin-left-50";
  }

  // Graduation 2 variables.
  let graduation2Label = "pos-relative";
  let graduation2Details = "";
  if (graduation2Top) {
    graduation2Label = "pos-fixed";
    graduation2Details = "margin-left-50";
  }

  return (
    <div className="section-experience">
      <section className="experience-conferences">
        <div className={`column-1 ${conferencesLabel}`} id="col1-conferences">
          <LabelLine label={getText("CONFERENCES")} date="" id="conferences" />
          <div className="vertical-line" />
        </div>
        <div className={`column-2 ${conferencesDetails}`} id="conferences-info">
          <ExperienceConferences />
        </div>
      </section>

      <section className="experience-5">
        <div className={`column-1 ${experience5Label}`} id="col1-grafana">
          <LabelLine label="GRAFANA" date="2024-..." id="grafana" />
          <div className="vertical-line" />
        </div>
        <div className={`column-2 ${experience5Details}`} id="grafana-info">
          <ExperienceGrafana imgSize={150} />
        </div>
      </section>

      <section className="experience-4">
        <div className={`column-1 ${experience4Label}`} id="col1-crdb">
          <LabelLine label="COCKROACH LABS" date="2021-2024" id="crdb" />
          <div className="vertical-line" />
        </div>
        <div className={`column-2 ${experience4Details}`} id="crdb-info">
          <ExperienceCRDB imgSize={150} />
        </div>
      </section>

      <section className="experience-3">
        <div className={`column-1 ${experience3Label}`} id="col1-ibm">
          <LabelLine label="IBM" date="2014-2021" id="ibm" />
          <div className="vertical-line" />
        </div>
        <div className={`column-2 ${experience3Details}`} id="ibm-info">
          <ExperienceIBM imgSize={150} />
        </div>
      </section>

      <section className="experience-2">
        <div className={`column-1 ${experience2Label}`} id="col1-taqtile">
          <LabelLine label="TAQTILE" date="2013-2014" id="taqtile" />
          <div className="vertical-line" />
        </div>
        <div className={`column-2 ${experience2Details}`} id="taqtile-info">
          <ExperienceTaqtile imgSize={150} />
        </div>
      </section>

      <section className="graduation">
        <div className={`column-1 ${graduation2Label}`} id="col1-poli2">
          <LabelLine
            label={getText("MASTERS_TITLE")}
            date="2013-2015"
            id="poli2"
          />
          <div className="vertical-line" />
        </div>

        <div className={`column-2 ${graduation2Details}`} id="poli2-info">
          <ExperienceMasters imgSize={150} />
        </div>
      </section>

      <section className="experience-1">
        <div className={`column-1 ${experience1Label}`} id="col1-estagios">
          <LabelLine
            label={getText("INTERNSHIP_TITLE")}
            date="2010-2012"
            id="estagios"
          />
          <div className="vertical-line" />
        </div>

        <div className={`column-2 ${experience1Details}`} id="estagios-info">
          <ExperienceInternships imgSize={150} />
        </div>
      </section>

      <section className="graduation">
        <div className={`column-1 ${graduation1Label}`} id="col1-poli1">
          <LabelLine
            label={getText("GRADUATION_TITLE")}
            date="2008-2012"
            id="poli1"
          />
          <div className="vertical-line" />
        </div>

        <div className={`column-2 ${graduation1Details}`} id="poli1-info">
          <ExperienceGraduation imgSize={150} />
        </div>
      </section>
    </div>
  );
}

interface LabelLightProps {
  label: string;
  date: string;
}

export function LabelLight(props: LabelLightProps) {
  return (
    <>
      <span className="label-light-title">{props.label}</span>
      <span className="label-light-date">{props.date}</span>
      <div className="divider-horizontal" />
    </>
  );
}

// Professional Experience for small screen (mobile)
export function ProfessionalExperienceLight() {
  return (
    <div className="section-experience small-padding">
      <LabelLight label="CONFERENCES" date="" />
      <div style={{ height: "315px" }}>
        <ExperienceConferences />
      </div>

      <LabelLight label="GRAFANA" date="2024-..." />
      <div style={{ height: "315px" }}>
        <ExperienceGrafana imgSize={100} />
      </div>

      <LabelLight label="COCKROACH LABS" date="2021-2024" />
      <div style={{ height: "315px" }}>
        <ExperienceCRDB imgSize={100} />
      </div>

      <LabelLight label="IBM" date="2014-2021" />
      <div style={{ height: "515px" }}>
        <ExperienceIBM imgSize={100} />
      </div>

      <LabelLight label="TAQTILE" date="2013-2014" />
      <div style={{ height: "220px" }}>
        <ExperienceTaqtile imgSize={100} />
      </div>

      <LabelLight label={getText("MASTERS_TITLE")} date="2013-2015" />
      <div style={{ height: "155px" }}>
        <ExperienceMasters imgSize={100} />
      </div>

      <LabelLight label={getText("INTERNSHIP_TITLE")} date="2010-2012" />
      <div style={{ height: "315px" }}>
        <ExperienceInternships imgSize={100} />
      </div>

      <LabelLight label={getText("GRADUATION_TITLE")} date="2008-2012" />
      <div style={{ height: "155px" }}>
        <ExperienceGraduation imgSize={100} />
      </div>
    </div>
  );
}
