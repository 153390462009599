import React from "react";
import { Analytics } from "@vercel/analytics/react";
import { ThemeProvider } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import "./App.scss";
import { theme } from "./core/theme";
import { AboutMe } from "./mainPages/aboutMe";
import { Blog } from "./mainPages/blog";
import { Toolbar } from "@mui/material";
import { getText } from "./texts/texts";
import {
  trackTabSelected,
  trackWebsiteLanguage,
  updateSearchParams,
} from "./utils";

function App() {
  const params = new URLSearchParams(window.location.search);
  const [selectedTab, setSelectedTab] = React.useState(
    params.get("tab") === "about" ? 1 : 0
  );

  const language = params.get("language");
  setLanguage(language);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    const tab = newValue === 0 ? "blog" : "about";
    trackTabSelected(tab);
  };

  function setLanguage(language: string | null) {
    const languageStored = window.localStorage.getItem("language");

    if (language && language !== null && languageStored !== language) {
      window.localStorage.setItem("language", language);
      window.location.reload();
      trackWebsiteLanguage(language);
      updateSearchParams([{ key: "language", value: language }]);
    }
  }

  function updateTabSearchParams(tab: string) {
    updateSearchParams([
      { key: "tab", value: tab },
      { key: "postID", value: "" },
    ]);
  }

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <div>{children}</div>}
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Analytics />
      <AppBar position="static">
        <Toolbar className="menu-toolbar">
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            aria-label="menu"
            className="menu-tabs"
          >
            <Tab
              label={getText("BLOG")}
              id="tab-blog"
              aria-controls="tab-blog"
              className="menu-tab"
              onClick={() => updateTabSearchParams("blog")}
              key={"tab-blog"}
            />
            <Tab
              label={getText("ABOUT")}
              id="tab-me"
              aria-controls="tab-me"
              className="menu-tab"
              onClick={() => updateTabSearchParams("about")}
              key={"tab-about"}
            />
          </Tabs>
          <div className="language-area">
            <img
              src="flag-br.png"
              className="flag-top"
              width={25}
              alt="flag brasil"
              onClick={() => setLanguage("PT")}
            />
            <img
              src="flag-cad.png"
              className="flag-top"
              width={25}
              alt="flag canada"
              onClick={() => setLanguage("EN")}
            />
          </div>
        </Toolbar>
      </AppBar>
      <div className="App"></div>
      <CustomTabPanel value={selectedTab} index={0}>
        <Blog />
      </CustomTabPanel>
      <CustomTabPanel value={selectedTab} index={1}>
        <AboutMe />
      </CustomTabPanel>
    </ThemeProvider>
  );
}

export default App;
