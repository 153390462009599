import { getSiteLanguage } from "../../utils";

export function getContentOtel(
  key: ContentPortugueseKeys | ContentEnglishKeys
): string {
  if (getSiteLanguage() === "PT") {
    return contentPortuguese[key];
  }
  return contentEnglish[key];
}

type ContentPortugueseKeys = keyof typeof contentPortuguese;
type ContentEnglishKeys = keyof typeof contentEnglish;

const contentEnglish = {
    SEC_2_TITLE: `Problem`,
    SEC_3_TITLE: `Everybody wins`,
    SEC_4_TITLE: `Standards`,
    SEC_5_TITLE: `What is OpenTelemetry?`,
    SEC_6_TITLE: `Components`,
    SEC_7_TITLE: `Challenges`,
    SEC_8_TITLE: `Contribute`,
    SEC_9_TITLE: `References`,
    SEC_1_PT_1: `In the world of observability, there is a lot of talk about OpenTelemetry (also known as OTel), 
    and its usage is increasing, so it is often assumed that everyone already knows what it is, where it came 
    from or what it is used for. For this reason, I created this post to help you understand what OpenTelemetry is.`,
    SEC_2_PT_1: `Imagine that your team is responsible for the observability area of a product. You decide which 
    information is important to monitor the health of your application. In this example let's imagine that this 
    information is in the form of traces (to learn more about traces, take a look at `,
    SEC_2_PT_2: `). The next step is to decide which tool to use to visualize and analyze this information.`,
    SEC_2_PT_3: `After a lot of research, you decide to use company X's product. You prepare the traces to be 
    sent in the format that company X requests and everything works as expected. So far so good.`,
    SEC_2_PT_4: `After a while you decide that company X is no longer the best choice for you, as there is a cheaper 
    company Y, with better support, whose product has more features or for any other reason. The problem is that 
    company Y requires you to send these same traces in a completely different format.`,
    SEC_2_PT_5: `Now you have some considerations to make: will the work and costs of rewriting your code to adapt 
    to company Y's products be offset by this switch? What if as soon as you make the switch to company Y, an even 
    better company Z comes along? Will you make this switch again? How many times will this change be necessary? 
    And what are the risks of not making the switch?`,
    SEC_2_PT_6: `This is a problem known as "vendor lock-in", where the costs of switching vendors are so high 
    that the customer ends up stuck with the current vendor.`,
    SEC_2_PT_7: `We can make an analogy with  USB-C versus Lightning cell phone cables. Imagine that you bought 
    an iPhone and to charge it you are forced to use Apple's Lightning Cable. You leave the charger in the socket 
    next to your bed and it is always there when you want to charge it. Now you decide to buy a Google cell phone, 
    but it can't be charged with the same cable. You have to switch to a USB-C charger.`,
    SEC_2_PT_8: `When you use the Lightning Cable you are stuck using only Apple products, but when you use USB-C 
    you can provide power from your outlet to a variety of devices, like the ones from Google, Samsung, Motorola, 
    etc., so the same cable can stay in that socket next to your bed for the rest of your life, no matter how many 
    times you change your cell phone!`,
    SEC_3_PT_1: `Now imagine if all vendors used the same standard to send/receive traces, metrics, logs, etc.`,
    SEC_3_PT_2: `The advantage for you as a client is that you only need to learn it once, without needing to 
    study, learn and develop in a format or even a new language for each vendor. Additionally, switching from 
    one company to another becomes much easier, as your code would only need to change some configuration values 
    and it would start working for the new product.`,
    SEC_3_PT_3: `For companies, adopting an open standard has several advantages. Your customers may already be 
    familiar with the standard, reducing support and implementation costs. An open standard also has a community 
    focused on the same subject that can help your customers. There is also a competitive advantage where your 
    customers may prefer to adopt open standards rather than closed and proprietary solutions.`,
    SEC_3_PT_4: `Creating a standard benefits everyone involved, which is where the idea to develop standards 
    for telemetry data began.`,
    SEC_4_PT_1: `The creation of a standard for traces emerged in parallel in two projects: OpenTracing and 
    OpenCensus. Even though they worked differently and didn't necessarily have the same components, they were 
    both open source and wanted to create a standard for tracing.`,
    SEC_4_PT_2: `Over time, the two solutions started to have conflicting decisions, so it was decided that the 
    best thing to do would be to combine the best part of these two projects.`,
    SEC_4_PT_3: `This is how OpenTelemetry emerged, which combined the two projects and is hosted by the Cloud 
    Native Computing Foundation (CNCF). Since then the project has expanded to much more than creating a 
    convention for traces.`,
    SEC_5_PT_1: `OpenTelemetry is a framework for creating and managing telemetry data, such as traces, metrics, 
    and logs. One of the biggest advantages of OpenTelemetry is that it is vendor, tool, and language agnostic, 
    which means you can use it with a variety of observability products.`,
    SEC_5_PT_2: `It is also an open source project, so anyone can contribute, review code, give feedback, propose 
    conventions, and so on.`,
    SEC_5_PT_3: `As this project started with the merger of two projects that were focused on traces, OpenTelemetry 
    is more stable in this area and continues to be developed and improved in other areas, such as metrics and logs.`,
    SEC_5_PT_4: `OpenTelemetry is a collection of specifications, APIs, SDKs, and tools to instrument, generate, 
    collect, and export telemetry data of a system, which allows you to analyze the health, performance, and behavior 
    of your application.`,
    SEC_5_PT_5: `Most observability vendors support OpenTelemetry, such as Grafana, Datadog, Honeycomb, Lightstep, 
    Dynatrace, Elastic.`,
    SEC_6_PT_1: `OpenTelemetry is made up of several components, which can be quite complex, so here they will only 
    be listed, and in future posts they will be further detailed. The main components are:`,
    SEC_6_PT_2: `Specification:`,
    SEC_6_PT_3: ` defines the components, such as APIs, SDKs, data, etc.`,
    SEC_6_PT_4: `Open Telemetry Protocol (OTLP):`,
    SEC_6_PT_5: ` defines the format of telemetry data.`,
    SEC_6_PT_6: `Semantic conventions:`,
    SEC_6_PT_7: ` defines a standard naming scheme for common telemetry data types.`,
    SEC_6_PT_8: `Application Programming Interface (API):`,
    SEC_6_PT_9: ` defines how to generate telemetry data.`,
    SEC_6_PT_10: `Software Development Kit (SDK):`,
    SEC_6_PT_11: ` specific to different languages and implements the specification, APIs and exports telemetry data.`,
    SEC_6_PT_12: `Library ecosystem:`,
    SEC_6_PT_13: ` implements instrumentation for common libraries and frameworks.`,
    SEC_6_PT_14: `Automatic instrumentation components:`,
    SEC_6_PT_15: ` generation of telemetry data without the need to change code.`,
    SEC_6_PT_16: `Collector:`,
    SEC_6_PT_17: ` a proxy that receives, processes, and exports telemetry data.`,
    SEC_6_PT_18: `In addition to these, there are also other components such as Operator for Kubernetes, Helm Charts, 
    and community assets for FaaS.`,
    SEC_7_PT_1: `Because this project is community led, there are no guarantees that all areas will receive the same 
    level of support. This means that a feature you want may not have been developed yet. We can see this example 
    when we compare logs with traces, or even different language SDKs.`,
    SEC_7_PT_2: `Contributing in your spare time is not easy, which is why we often rely on companies that have teams 
    dedicated to developing for OpenTelemetry. For example, I work for Grafana Labs and my focus is to contribute to 
    the SDKs, so I can help both the company I work for and the community as a whole.`,
    SEC_8_PT_1: `This is a community project, and it's open to everyone. This means that if there's something you 
    want, you can get involved at the level that makes the most sense to you: join special interest groups (SIGs), 
    help review Pull Requests, create issues for bugs or features, give your opinion or use case for implementations, 
    ask or answer questions on Slack, or even contribute to the development itself.`,
    SEC_8_PT_2: `The OpenTelemetry community is very welcoming and would love your involvement!`,
    SEC_9_PT_1: `OpenTelemetry`,
    SEC_9_PT_2: `CNCF`,
    SEC_9_PT_3: `OpenTracing`,
    SEC_9_PT_4: `OpenCensus`,
    SEC_9_PT_5: `OpenTelemetry Specification`,
    SEC_9_PT_6: `OTLP Specification`,
    SEC_9_PT_7: `OpenTelemetry Semantic Conventions`,
    SEC_9_PT_8: `Language APIs & SDKs`,
    SEC_9_PT_9: `Registry`,
    SEC_9_PT_10: `Collector`,
    SEC_9_PT_11: `OpenTelemetry Operator for Kubernetes`,
    SEC_9_PT_12: `OpenTelemetry Helm Charts`,
    SEC_9_PT_13: `Functions as a Service`,
    SEC_9_PT_14: `Vendors`,
    SEC_9_PT_15: `Special Interest Groups`,
};

const contentPortuguese = {
    SEC_2_TITLE: `Problema`,
    SEC_3_TITLE: `Todo mundo sai ganhando`,
    SEC_4_TITLE: `Padrões`,
    SEC_5_TITLE: `O que é OpenTelemetry?`,
    SEC_6_TITLE: `Componentes`,
    SEC_7_TITLE: `Desafios`,
    SEC_8_TITLE: `Contribuir`,
    SEC_9_TITLE: `Referências (em inglês)`,
    SEC_1_PT_1: `No mundo da observabilidade muito se fala sobre Open Telemetry (também conhecido como OTel), 
    e seu uso é cada vez mais comum, por isso muitas vezes se assume que todos já sabem o que é, de onde 
    surgiu ou para que serve. Por isso, criei este post para te ajudar a entender o que é esse tal de Open Telemetry.`,
    SEC_2_PT_1: `Imagine que sua equipe é responsável pela área de observabilidade de um produto. Vocês decidem 
    quais informações são importantes para monitorar a saúde da sua aplicação. Neste exemplo vamos imaginar que 
    essas informações estão em forma de traces (se você quiser aprender mais sobre traces, dê uma olhada em `,
    SEC_2_PT_2: `). O próximo passo é decidir qual ferramenta utilizar para visualizar e analisar estas informações.`,
    SEC_2_PT_3: `Depois de muita pesquisa, vocês decidem usar o produto da empresa X. Vocês preparam os traces 
    para serem enviados no formato que a empresa X pede e tudo funciona conforme esperado. Até aqui não há problema.`,
    SEC_2_PT_4: `Depois de um certo tempo você decide que a empresa X não é mais a melhor escolha pra você, pois existe 
    uma empresa Y mais barata, com melhor suporte, cujo produto tem mais recursos ou qualquer outro motivo. O problema 
    é que a empresa Y requer que você mande estes mesmos traces em um formato totalmente diferente.`,
    SEC_2_PT_5: `Agora você tem algumas considerações a fazer: o trabalho e custos de refazer todo seu código para se 
    adequar aos produtos da empresa Y serão compensados por essa troca? E se assim que você fizer a troca para a empresa Y, 
    vier uma empresa Z ainda melhor? Você fará essa troca de novo? Quantas vezes essa troca será necessária? E quais os 
    riscos de não fazer a troca?`,
    SEC_2_PT_6: `Este é um problema conhecido como "vendor lock-in", onde os custos para trocar de fornecedor são tão 
    grandes que o cliente acaba preso ao fornecedor atual.`,
    SEC_2_PT_7: `Podemos fazer uma analogia aqui com os cabos de celulares USB-C versus Lightning. Imagine que você 
    comprou um iPhone e para carregá-lo você é obrigado a usar o cabo Lightning da Apple. Você deixa o carregador na 
    tomada perto da sua cama e ele fica sempre lá pra quando você quiser carregar. Então você decide comprar um 
    celular da Google, mas ele não consegue carregar com o mesmo cabo. Você então troca para um carregador USB-C e seu 
    celular passa a carregar conforme esperado.`,
    SEC_2_PT_8: `Quando você usa o cabo Lightning você está preso a usar apenas produtos da Apple, mas quando você 
    usa USB-C você pode passar a energia da sua tomada para uma variedade de aparelhos, seja Google, Samsung, Motorola, etc, 
    então o mesmo cabo pode ficar naquela tomada do lado da sua cama o resto da vida, não importa quantas vezes você 
    troque de celular!`,
    SEC_3_PT_1: `Imagine agora que todas essas empresas usam o mesmo padrão para enviar/receber os traces, métricas, 
    logs, etc. `,
    SEC_3_PT_2: `A vantagem para você como cliente é que só precisa aprender uma vez, sem precisar estudar, 
    aprender e programar um formato ou até mesmo uma linguagem nova para cada produto. Fora isso, a troca de uma 
    empresa para a outra se torna bem mais fácil, já que seu código precisaria apenas mudar alguns valores do 
    destino e já passaria a funcionar para o produto novo.`,
    SEC_3_PT_3: `Para empresas, adotar um padrão aberto apresenta diversas vantagens. Os seus clientes podem já 
    estar familiarizados com o padrão, reduzindo os custos com suporte e implementação. Um padrão aberto também 
    conta com uma comunidade focada no mesmo assunto que pode ajudar os seus clientes. Existe também uma vantagem 
    competitiva onde os seus clientes podem preferir adotar padrões abertos ao invés de soluções fechadas e 
    proprietárias.`,
    SEC_3_PT_4: `A criação de um padrão beneficia todos os envolvidos, e daí que começou a ideia de que um padrão 
    para lidar com dados de telemetria fosse desenvolvido.`,
    SEC_4_PT_1: `A criação de um padrão para traces começou a surgir em paralelo em dois projetos: OpenTracing 
    e OpenCensus. Eles funcionam de forma diferente e não necessariamente possuíam os mesmos componentes, mas o 
    que tinham em comum é que ambos eram código aberto e visavam a criação de um padrão para tracing.`,
    SEC_4_PT_2: `Com o tempo, as duas soluções passaram a ter decisões conflitantes, então foi decidido que o 
    melhor a se fazer seria a junção da melhor parte destes dois projetos.`,
    SEC_4_PT_3: `Assim surgiu o OpenTelemetry, que combinou os dois projetos e é hospedado pela Cloud Native 
    Computing Foundation (CNCF). Desde então o projeto expandiu para muito mais do que criação de um padrão 
    para traces.`,
    SEC_5_PT_1: `OpenTelemetry é um framework para criar e gerenciar dados de telemetria, como traces, métricas 
    e logs. Uma das maiores vantagens do OpenTelemetry é que ele é independente de fornecedor, ferramentas e 
    linguagem, o que significa que você pode utilizar com uma variedade de produtos de observabilidade.`,
    SEC_5_PT_2: `Este também é um projeto de código aberto, então qualquer pessoa pode contribuir, revisar código, 
    dar opinião, propor convenções, e assim por diante.`,
    SEC_5_PT_3: `Como este projeto começou com a junção de dois projetos que estavam focados em traces, 
    OpenTelemetry também acaba sendo mais estável na área de traces e continua sendo desenvolvido e aprimorado 
    em outras áreas, como métricas e logs.`,
    SEC_5_PT_4: `OpenTelemetry é uma coleção de especificações, APIs, SDKs e ferramentas para instrumentar, 
    gerar, coletar e exportar dados de telemetria de um sistema, o que possibilita analisar a saúde, performance 
    e comportamento da sua aplicação.`,
    SEC_5_PT_5: `A maioria dos fornecedores de observabilidade são compatíveis com OpenTelemetry, como Grafana, 
    Datadog, Honeycomb, Lightstep, Dynatrace, Elastic.`,
    SEC_6_PT_1: `OpenTelemetry é composto de várias partes, que podem ser bem complexas, então aqui eles serão 
    apenas listados, e em posts futuros eles serão mais detalhados. Os principais componentes são:`,
    SEC_6_PT_2: `Especificação:`,
    SEC_6_PT_3: ` define os componentes, como APIs, SDKs, dados, etc.`,
    SEC_6_PT_4: `Protocolo de Open Telemetry (OTLP):`,
    SEC_6_PT_5: ` define o formato dos dados de telemetria.`,
    SEC_6_PT_6: `Convenções semânticas:`,
    SEC_6_PT_7: ` define um esquema de nomenclatura padrão para tipos de dados de telemetria comuns.`,
    SEC_6_PT_8: `Interface de Programação de Aplicações (API):`,
    SEC_6_PT_9: ` define como gerar dados de telemetria.`,
    SEC_6_PT_10: `Kit de Desenvolvimento de Software (SDK):`,
    SEC_6_PT_11: ` específicos para diferentes linguagens e implementa a especificação, APIs e exporta os dados de telemetria.`,
    SEC_6_PT_12: `Ecossistema de bibliotecas:`,
    SEC_6_PT_13: ` implementa a instrumentação para bibliotecas e frameworks comuns.`,
    SEC_6_PT_14: `Componentes de instrumentação automática:`,
    SEC_6_PT_15: ` geração de dados de telemetria sem a necessidade de mudar código.`,
    SEC_6_PT_16: `Coletor:`,
    SEC_6_PT_17: ` um proxy que recebe, processa e exporta dados de telemetria.`,
    SEC_6_PT_18: `Além desses, também existem outros componentes como Operador para Kubernetes, Helm Charts e 
    recursos para FaaS.`,
    SEC_7_PT_1: `Por este projeto ser da comunidade, não há garantias que todas as áreas irão receber o mesmo 
    nível de suporte. Isso significa que um recurso que você queira pode não ter sido desenvolvido ainda. Podemos 
    ver esse problema quando comparamos a maturidade de implementação de logs com traces, por exemplo, ou mesmo os 
    SDKs de diferentes linguagens.`,
    SEC_7_PT_2: `Contribuir em horas vagas não é algo fácil, por isso muitas vezes contamos com empresas que possuam 
    equipes dedicadas a desenvolver para OpenTelemetry. Por exemplo, eu trabalho para a Grafana Labs e meu foco é 
    contribuir para os SDKs, com isso posso ajudar tanto a empresa que trabalho como a comunidade como um todo.`,
    SEC_8_PT_1: `Este é um projeto da comunidade, e é aberto a todos, isso significa que se existe algo que você 
    queira, você pode se envolver no nível que fizer mais sentido para você: junte-se aos grupos de interesse 
    especial (SIGs), ajude com revisões de Pull Requests, crie issues para bugs ou features, dê sua opinião ou caso de 
    uso para as implementações, faça ou responda perguntas no Slack, ou até mesmo contribua com o desenvolvimento em si. `,
    SEC_8_PT_2: `Esta é uma comunidade bem receptiva que iria adorar seu envolvimento!`,
    SEC_9_PT_1: `OpenTelemetry`,
    SEC_9_PT_2: `CNCF`,
    SEC_9_PT_3: `OpenTracing`,
    SEC_9_PT_4: `OpenCensus`,
    SEC_9_PT_5: `OpenTelemetry Especificação`,
    SEC_9_PT_6: `OTLP Especificação`,
    SEC_9_PT_7: `OpenTelemetry Convenções Semânticas`,
    SEC_9_PT_8: `APIs & SDKs`,
    SEC_9_PT_9: `Registro`,
    SEC_9_PT_10: `Coletor`,
    SEC_9_PT_11: `OpenTelemetry Operador para Kubernetes`,
    SEC_9_PT_12: `OpenTelemetry Helm Charts`,
    SEC_9_PT_13: `Funções como Serviço`,
    SEC_9_PT_14: `Fornecedores`,
    SEC_9_PT_15: `Grupos de interesse especial`,
};